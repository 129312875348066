import { Circle, LayerGroup, Popup, TileLayer } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { HighMapContainer } from './map-card.styles';
const MapCard = () => {
  const position: LatLngExpression = [51.67865228941651, 33.91651530243458];
  return (
    <HighMapContainer center={position} zoom={14} scrollWheelZoom={false}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <LayerGroup>
        <Circle
          center={position}
          pathOptions={{ color: 'var(--color-primary)' }}
          radius={30}
        >
          <Popup>Ми тут!</Popup>
        </Circle>
      </LayerGroup>
    </HighMapContainer>
  );
};
export default MapCard;
