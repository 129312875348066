import { Route, Routes } from 'react-router-dom';
import Page404 from '../components/page404/page404.component';
import PsychologyProblems from '../pages/science/research-psychology-problems/psychology-problems.component';
import NewUkrainianSchool from '../pages/science/research-new-ukrainian-school/new-ukrainian-school.component';
import InnovationInVnz from '../pages/science/research-innovation-in-vnz/innovation-in-vnz.component';
import VashulenkoM from '../pages/science/schools-vashulenko-m/vashulenko-m.component';
import BiriukL from '../pages/science/schools-biriuk-l/biriuk-l.component';
import KyzminskyA from '../pages/science/schools-kyzminsky-a/kyzminsky-a.component';
import Society from '../pages/science/society/society.component';

const Science = () => (
  <Routes>
    <Route index element={<Page404 />} />
    <Route
      path="research/psychology-problems"
      element={<PsychologyProblems />}
    />
    <Route
      path="research/new-ukrainian-school"
      element={<NewUkrainianSchool />}
    />
    <Route path="research/innovation-in-vnz" element={<InnovationInVnz />} />
    <Route path="schools/vashulenko-m" element={<VashulenkoM />} />
    <Route path="schools/biriuk-l" element={<BiriukL />} />
    <Route path="schools/kyzminsky-a" element={<KyzminskyA />} />
    <Route path="society" element={<Society />} />
    <Route path="/*" element={<Page404 />} />
  </Routes>
);
export default Science;
