import { Action, combineReducers, ThunkAction } from '@reduxjs/toolkit';
import userReducer from './user/user.slice';
import navigationReducer from './navigation/navigation.slice';
import announcementsReducer from './announcements/announcements.slice';

const rootReducer = combineReducers({
  user: userReducer,
  navigation: navigationReducer,
  announcements: announcementsReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export default rootReducer;
