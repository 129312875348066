import MapCard from '../../../components/map-card/map-card.component';

const Contacts = () => (
  <>
    <h2>КОНТАКТИ:</h2>
    <h3>
      ННІ Педагогіки і психології Глухівського національного педагогічного
      університету імені Олександра Довженка
    </h3>
    <address style={{ fontSize: '1.2rem' }}>
      Адреса:{' '}
      <span>вул. Київська, 24, м.Глухів, Сумська обл., 41400, корпус №1</span>
      <br />
      Електронна пошта:{' '}
      <a href="mailto:dnnipp@gnpu.edu.ua">dnnipp@gnpu.edu.ua</a>
      <hr />
      <b>
        Директор ННІ Педагогіки і психології, кандидат педагогічних наук, доцент
        <br />
        Шерудило Андрій Васильович
      </b>
      ,<br />
      <a href="tel:+380662110850">066-211-08-50</a>
      <br />
      <a href="mailto:sherudilo@gnpu.edu.ua">sherudilo@gnpu.edu.ua</a>
      <hr />
      <b>
        Провідний фахівець
        <br />
        Шелудько Валентина Ігорівна
      </b>
      <br />
      <a href="tel:+380667905643">0667905643</a>
      <br />
      <a href="mailto:pochdek@gmail.com">pochdek@gmail.com</a>
      <hr />
      <b>
        Провідний фахівець <br />
        Тригубенко Лариса Миколаївна
      </b>
      <br />
      <a href="tel:+380664465743">0664465743</a>
      <br />
      <a href="mailto:dekfpo@gmail.com">dekfpo@gmail.com</a>
    </address>
    <hr />
    <h3>Розташування на інтерактивній карті</h3>
    <MapCard />
  </>
);

export default Contacts;
