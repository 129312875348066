import ExternalLink from '../../../components/UI/external-link/external-link.component';

const Staffing = () => (
  <>
    <h2>Кадрове забезпечення</h2>
    <h3>НАУКОВО-ПЕДАГОГІЧНІ ПРАЦІВНИКИ, ЯКІ&nbsp;ЗАБЕЗПЕЧУЮТЬ ОСВІТНІЙ ПРОЦЕС</h3>

    <ul style={{ marginLeft: '5%' }}>
      <li>
        <ExternalLink
          href="http://gnpu.edu.ua/index.php/ua/pro-universytet/naukovo-pedahohichni-pratsivnyky/243-biruk-lydmyla-yakivna"
          text="Бірюк Людмила Яківна"
        />
      </li>
      <li>
        <ExternalLink
          href="http://gnpu.edu.ua/index.php/ua/pro-universytet/naukovo-pedahohichni-pratsivnyky/290-vashulenko-mykhola-samiylovych"
          text="Вашуленко Микола Самійлович"
        />
      </li>
      <li>
        <ExternalLink
          href="http://gnpu.edu.ua/index.php/ua/pro-universytet/naukovo-pedahohichni-pratsivnyky/252-gogol-nataliya-valeriivna"
          text="Гоголь Наталія Валеріївна"
        />
      </li>
      <li>
        <ExternalLink
          href="http://gnpu.edu.ua/index.php/ua/pro-universytet/naukovo-pedahohichni-pratsivnyky/241-kuznetsova-galyna-petryvna"
          text="Кузнецова Галина Петрівна"
        />
      </li>
      <li>
        <ExternalLink
          href="http://gnpu.edu.ua/index.php/ua/pro-universytet/naukovo-pedahohichni-pratsivnyky/240-kurok-vira-panasivna"
          text="Курок Віра Панасівна"
        />
      </li>
      <li>
        <ExternalLink
          href="http://gnpu.edu.ua/index.php/ua/pro-universytet/naukovo-pedahohichni-pratsivnyky/239-lucenko-greg-vasyliovich"
          text="Луценко Григорій Васильович"
        />
      </li>
      <li>
        <ExternalLink
          href="http://gnpu.edu.ua/index.php/ua/pro-universytet/naukovo-pedahohichni-pratsivnyky/253-pinchuk-iryna-olexandrivna"
          text="Пінчук Ірина Олександрівна"
        />
      </li>
      <li>
        <ExternalLink
          href="http://gnpu.edu.ua/index.php/ua/pro-universytet/naukovo-pedahohichni-pratsivnyky/311-pishun-sergii-gryghorovich"
          text="Пішун Сергій Григорович"
        />
      </li>
      <li>
        <ExternalLink
          href="http://gnpu.edu.ua/index.php/ua/pro-universytet/naukovo-pedahohichni-pratsivnyky/255-slinchenko-latysa-volodymirivna"
          text="Слінченко Лариса Володимирівна"
        />
      </li>
      <li>
        <ExternalLink
          href="http://gnpu.edu.ua/index.php/ua/pro-universytet/naukovo-pedahohichni-pratsivnyky/248-tkachenko-nataliya-mykolaivna"
          text="Ткаченко Наталія Миколаївна"
        />
      </li>
      <li>
        <ExternalLink
          href="http://gnpu.edu.ua/index.php/ua/pro-universytet/naukovo-pedahohichni-pratsivnyky/237-tolmachov-volodimir-sergijovich"
          text="Толмачов Володимир Сергійович"
        />
      </li>
    </ul>
  </>
);

export default Staffing;
