import HiddenPDF from '../../../components/hidden-pdf/hidden-pdf.component';

const InnovationInVnz = () => (
  <>
    <h2>
      Науково-дослідна лабораторія з управління інноваційно-педагогічною :
      науково-дослідницькою діяльністю у вищих навчальних закладах
    </h2>
    <HiddenPDF
      btnText="Положення про лабораторію"
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Finnovation%2F%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F%20%D0%A3%D0%9D%D0%97.pdf?alt=media&token=63016a1f-3476-4680-a691-a59ed4492967"
    />
    <HiddenPDF
      btnText="План роботи лабораторії 2023 рік"
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Finnovation%2F%D0%9F%D0%BB%D0%B0%D0%BD.pdf?alt=media&token=1b56fece-232e-44f4-b00a-818d4ba3219a"
    />
    <HiddenPDF
      btnText="Звіт лабораторії 2022 рік"
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Finnovation%2F%D0%97%D0%B2%D1%96%D1%82%20%D0%BB%D0%B0%D0%B1%D0%BE%D1%80%D0%B0%D1%82%D0%BE%D1%80%D1%96%D1%97%202022%20%D1%80..pdf?alt=media&token=123410e5-7df2-45e7-970a-bf17a4dd974f"
    />
    <HiddenPDF
      btnText="Звіт лабораторії 2021 рік"
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Finnovation%2F%D0%97%D0%B2%D1%96%D1%82.pdf?alt=media&token=b064d558-4d8a-41ac-bc74-baa44791aa35"
    />
  </>
);

export default InnovationInVnz;
