const Rules = () => (
  <>
    <h2>Правила прийому</h2>
    <ul>
      <li>
        <h4>
          Ознайомитися з Правилами прийому на головному сайті університету{' '}
          <a
            href="http://gnpu.edu.ua/index.php/ua/blog/271-pravila-prijomu-do-universitetu"
            target="_blank"
            rel="noreferrer noopener"
          >
            Перейти <i className={'fa fa-external-link'} />
          </a>
        </h4>
      </li>
    </ul>
  </>
);

export default Rules;
