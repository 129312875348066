import ExternalLink from '../../../components/UI/external-link/external-link.component';

const Timetable = () => (
  <>
    <h2>Електронний розклад</h2>
    <p>
      <ExternalLink
        href="http://193.169.124.188/cgi-bin/timetable.cgi"
        text="Перейти на сторінку електронного розкладу"
      />
    </p>
  </>
);

export default Timetable;
