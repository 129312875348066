import styled from 'styled-components';
import { sharedCSSForBlocksWithBG } from '../../education/programs/programs.styles';

export const OrderedListWithBG = styled.ol`
  ${sharedCSSForBlocksWithBG};
  padding-left: 2em;
`;
export const ItemListWithBG = styled.li`
  text-align: start;
  font-size: 1.2rem;
  margin-bottom: inherit;
`;
export const ParagraphWithBG = styled.p`
  ${sharedCSSForBlocksWithBG};
  text-align: justify;
`;
