import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserData } from '../../utils/firebase/firebase.utils';

export type UserState = {
  readonly currentUser: UserData | null;
  readonly isLoggedIn: boolean;
};

const initialState: UserState = {
  currentUser: null,
  isLoggedIn: false
};
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<UserData>) => {
      state.currentUser = action.payload;
      state.isLoggedIn = true;
    },
    logOut: (state) => {
      state.currentUser = null;
      state.isLoggedIn = false;
    }
  }
});
export const { setCurrentUser, logOut } = userSlice.actions;

export default userSlice.reducer;
