import { FC } from 'react';

type ExternalLinkProps = {
  href: string;
  text?: string;
};
const ExternalLink: FC<ExternalLinkProps> = ({ href, text = 'Ознайомитися' }) => (
  <a href={href} target="_blank" rel="noreferrer noopener">
    {text} <i className="fa fa-external-link" />
  </a>
);
export default ExternalLink;
