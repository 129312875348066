import HiddenPDF from '../../../components/hidden-pdf/hidden-pdf.component';

const PsychologyProblems = () => (
  <>
    <h2>
      Навчально-наукова лабораторія з проблем вікової та педагогічної психології
    </h2>
    <HiddenPDF
      btnText="Положення про лабораторію"
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Fpsychology-problems%2F%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F%20%D0%BF%D1%80%D0%BE%20%D0%BB%D0%B0%D0%B1%D0%BE%D1%80%D0%B0%D1%82%D0%BE%D1%80%D1%96%D1%8E%20%D0%B2%D1%96%D0%BA%D0%BE%D0%B2%D0%B0_%D0%BF%D1%81%D0%B8%D1%85%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%8F.docx.pdf?alt=media&token=f8c385db-63a3-406f-a43d-f3d577ef59c1"
    />

    <HiddenPDF
      btnText="Склад лабораторії"
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Fpsychology-problems%2F%D1%81%D0%BA%D0%BB%D0%B0%D0%B4%20%D0%BB%D0%B0%D0%B1%D0%BE%D1%80%D0%B0%D1%82%D0%BE%D1%80%D1%96%D1%97.pdf?alt=media&token=683350a6-b761-43de-91e0-7c17bb553249"
    />
    <HiddenPDF
      btnText="Програма діяльності лабораторії"
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Fpsychology-problems%2F%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%B0%20%D0%B4%D1%96%D1%8F%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%96.pdf?alt=media&token=5ff8bcca-9488-4298-93df-7b7a8434db89"
    />
    <HiddenPDF
      btnText="План роботи лабораторії 2021-2022 рр."
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Fpsychology-problems%2F%D0%9F%D0%BB%D0%B0%D0%BD%20%D1%80%D0%BE%D0%B1%D0%BE%D1%82%D0%B8%202021-2022.pdf?alt=media&token=75d72703-a41a-4411-aa41-feca8e40d2e3"
    />
    <HiddenPDF
      btnText="Звіт лабораторії 2021 рік"
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Fpsychology-problems%2F%D0%97%D0%B2%D1%96%D1%82%20%D0%BB%D0%B0%D0%B1%D0%BE%D1%80%D0%B0%D1%82%D0%BE%D1%80%D1%96%D1%97%20%D0%B7%20%D0%BF%D1%80%D0%BE%D0%B1%D0%BB%D0%B5%D0%BC%20%D0%BF%D0%B5%D0%B4%D0%B0%D0%B3%D0%BE%D0%B3%D1%96%D1%87%D0%BD%D0%BE%D1%97%20%D1%82%D0%B0%20%D0%B2%D1%96%D0%BA%D0%BE%D0%B2%D0%BE%D1%97%20%D0%BF%D1%81%D0%B8%D1%85%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%97%20(1).pdf?alt=media&token=19f608b5-2daa-4649-b0db-6d710647925b"
    />
  </>
);

export default PsychologyProblems;
