import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NavigationState } from './navigation.slice';

export const selectNavigationReducer = (state: RootState): NavigationState =>
  state.navigation;

export const selectIsMenuOpen = createSelector(
  [selectNavigationReducer],
  (navigation) => navigation.isMenuOpen
);

export const selectIsAuthHidden = createSelector(
  [selectNavigationReducer],
  (navigation) => navigation.isAuthHidden
);
