import { Route, Routes } from 'react-router-dom';

import Page404 from '../components/page404/page404.component';
import Timetable from '../pages/student/timetable/timetable.component';
import GuestLectures from '../pages/student/guest-lectures/guest-lectures.component';
import PsychologicalService from '../pages/student/psychological-service/psychological-service.component';
import TrustMailbox from '../pages/student/trust-mailbox/trust-mailbox.component';
import Rehabilitation from '../pages/student/important-rehabilitation/rehabilitation.component';
import EmergencyPlan from '../pages/student/important-emergency-plan/emergency-plan.component';
import Announcement from '../components/announcement/announcement.component';

const Student = () => (
  <Routes>
    <Route index element={<Page404 />} />
    <Route path="timetable" element={<Timetable />} />
    <Route path="guest-lectures" element={<GuestLectures />} />
    <Route
      path="guest-lectures/:id"
      element={<Announcement annoType={'guest-lecture'} />}
    />
    <Route path="psychological-service" element={<PsychologicalService />} />
    <Route path="trust-mailbox" element={<TrustMailbox />} />
    <Route path="important/rehabilitation" element={<Rehabilitation />} />
    <Route path="important/emergency-plan" element={<EmergencyPlan />} />

    <Route path="/*" element={<Page404 />} />
  </Routes>
);
export default Student;
