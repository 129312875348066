import EmptyPage from '../empty-page/empty-page.component';

const Page404 = () => {
  return (
    <EmptyPage
      title={'Помилка 404 (на цьому сайті немає сторінки за цією адресою)'}
      warning
    />
  );
};
export default Page404;
