import HeaderContacts from '../header-address/header-address.component';
import { LogoContainer, Gaudeamus, MainLogo } from './global-header.styles';

const GlobalHeader = () => {
  return (
    <header>
      <HeaderContacts />
      <LogoContainer to="/">
        <MainLogo />
        <div>
          <h1>Навчально-науковий інститут педагогіки і психології</h1>
          <h3>
            ГЛУХІВСЬКОГО НАЦІОНАЛЬНОГО ПЕДАГОГІЧНОГО УНІВЕРСИТЕТУ ІМЕНІ ОЛЕКСАНДРА
            ДОВЖЕНКА
          </h3>
        </div>
      </LogoContainer>
      <Gaudeamus>
        <p>
          Vivat Academia! Vivant professores! Vivat membrum quodlibet! Vivant membra
          quaelibet! Semper sint in flore!
        </p>
      </Gaudeamus>
    </header>
  );
};
export default GlobalHeader;
