import { useNavigate } from 'react-router-dom';

import { GoBackBottom } from '../../components/postgraduate-profile/postgraduate-profile.styles';

import { AboutContainer } from './about-faculty.styles';

const AboutFaculty = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  return (
    <>
      <h2>ІНСТИТУТ ПЕДАГОГІКИ І ПСИХОЛОГІЇ</h2>
      <AboutContainer>
        <p>
          <b>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/about%2Fabout-page.webp?alt=media&token=a1afa861-f5a0-44be-9ec5-417bc93b1114"
              alt="Корпус № 1"
            />
          </b>
        </p>
        <p>
          <b>Місія ННІ Педагогіки і психології</b>, як структурного підрозділу
          Глухівського національного педагогічного університету імені Олександра Довженка
          полягає у створені гнучкої інноваційної освітньої системи для підготовки
          фахівців із суспільних наук, майбутніх учителів початкової школи, особистісного
          розвитку, наукових досліджень та консалтингу (надання інтелектуальних послуг
          особам, установам, спрямованих на отримання якісної освіти, підвищення
          ефективності діяльності вчителів та викладачів відповідного профілю), адекватних
          вимогам інформаційного суспільства, потребам міжнародного і національного ринків
          праці, інтегрованої в світовий освітній простір та орієнтованої на демократичні
          цінності.&nbsp;
        </p>
        <p>
          Функціонування інституту ґрунтується на таких <b>принципах</b>:
        </p>
        <ul>
          <li>
            <em>людиноцентризм;</em>
          </li>
          <li>
            <em>толерантність;</em>
          </li>
          <li>
            <em>миролюбство;</em>
          </li>
          <li>
            <em>солідарність;</em>
          </li>
          <li>
            <em>визначення та конкретизації цілей як результату діяльності;</em>
          </li>
          <li>
            <em>
              забезпечення системності й чіткості структури управління за основі
              зворотного зв'язку за результатами діяльності;&nbsp;
            </em>
          </li>
          <li>
            <em>злагодженості функціонування структурної системи навчального закладу;</em>
          </li>
          <li>
            <em>автономії, самоврядування, партнерства, відкритості до змін;</em>
          </li>
          <li>
            <em>цілеорієнтованої мотивації на професійне кадрове зростання;</em>
          </li>
          <li>
            <em>концептуальної, інформаційно-комунікаційної перманентності;</em>
          </li>
          <li>
            <em>соціальної спрямованості освітньої діяльності.</em>
          </li>
        </ul>

        <p>
          <b>Стратегічна мета діяльності ННІ Педагогіки і психології:</b> підготовка
          професійно-мобільних та гнучких конкурентоспроможних фахівців різних
          освітньо-кваліфікаційних рівнів у відповідності до державних стандартів,
          науково-дослідна робота, культурно-освітня, методична, видавнича робота. Шляхи
          досягнення мети: опора на потужний колектив професіоналів, ефективний
          менеджмент, висока якість послуг, орієнтація на замовників, швидке запровадження
          інновацій, здатність до змін.&nbsp;
        </p>
        <p>
          Основною метою програми розвитку інституту є об’єднання зусиль колективу для
          збереження традицій набутого, визначення пріоритетів та цілей діяльності
          колективу, координація діяльності факультету з іншими структурним підрозділами
          університету та керівництвом. Головні напрями діяльності відповідають Закону
          України “Про вищу освіту”, Статуту Глухівського національного педагогічного
          університету імені Олександра Довженка.
        </p>
        <p>
          Для досягнення поставленої мети необхідне вирішення наступних{' '}
          <b>завдань:&nbsp;</b>
        </p>
        <p>
          1) вдосконалення та розвиток навчального процесу відповідно до потреб
          ринку;&nbsp;
        </p>
        <p>
          2) оптимізація освітньої та наукової діяльності, що передбачає відкриття нових
          спеціальностей і освітніх програм;
        </p>
        <p>
          3) персоніфікована робота зі вступниками з використанням усіх форм
          комунікаційної взаємодії;
        </p>
        <p>
          4) вдосконалення менеджменту інституту на основі сучасних комп'ютерних
          технологій, які забезпечують ефективне вирішення завдань планування, обліку,
          аналізу і регулювання функціональної діяльності, що є основою рейтингового
          оцінювання викладача, кафедри, інституту в цілому;
        </p>
        <p>
          5) постійний розвиток і вдосконалення баз виробничих і навчальних практик і
          системи професійної підготовки;&nbsp;
        </p>
        <p>
          6) розвиток зв'язків із закладами вищої освіти країни та зарубіжжя, розробка та
          реалізація механізмів обміну здобувачів освіти;
        </p>
        <p>
          7) активізація діяльності провідних вчених інституту в удосконаленні процесів
          вітчизняної та світової освіти та науки.
        </p>
        <p>
          Основними <b>напрямками</b> роботи інституту є:
        </p>
        <p>
          - структурна розбудова ННІ Педагогіки і психології відповідно до потреб
          Глухівського національного педагогічного університету імені Олександра Довженка
          у відповідності до вимог сучасного законодавства;
        </p>
        <p>
          - підготовка фахівців за освітніми ступенями „бакалавр”, „магістр”, „доктор
          філософії” на рівні вітчизняних та світових стандартів;
        </p>
        <p>
          - участь ННІ Педагогіки і психології у науковій діяльності Глухівського
          національного педагогічного університету імені Олександра Довженка;
        </p>
        <p>- розвиток особистості здобувача освіти;</p>
        <p>
          - участь ННІ Педагогіки і психології у міжнародній діяльності Глухівського
          національного педагогічного університету імені Олександра Довженка;
        </p>
        <p>
          - формування контингенту і структури підготовки фахівців відповідно до потреб
          ринку праці через удосконалення профорієнтаційної роботи, забезпечення
          відкритості й прозорості роботи відбіркової, конкурсної та атестаційної комісій
          приймальної комісії.
        </p>
        <p>
          <b>ННІ Педагогіки і психології об’єднує три випускові кафедри:</b>
        </p>
        <p>1) Теорії і методики початкової освіти;&nbsp;</p>
        <p>2) Педагогіки і психології початкової освіти;&nbsp;</p>
        <p>3) Педагогіки, психології, соціальної роботи та менеджменту.&nbsp;</p>
        <p>
          <b>Функціонують три навально-наукові/науково-дослідні лабораторії:</b>
        </p>
        <p>
          1) Навчально-наукова лабораторія «Сучасна підготовка майбутніх учителів
          початкових класів до реалізації концепції НУШ» (керівник Пінчук&nbsp;І.О.);
        </p>
        <p>
          2) Навчально-наукова лабораторія з проблем вікової та педагогічної психології
          (керівник Журавель А.В.);
        </p>
        <p>
          3) Науково-дослідна лабораторія <b>&nbsp;</b>«З управління
          інноваційно-педагогічною і науково-дослідницькою діяльністю у вищих навчальних
          закладах» (керівник Луценко Г.В.).
        </p>
        <p>
          Крім того, в інституті функціонує навчальний кабінет «Нова українська школа» та
          наукові школи професора Вашуленка&nbsp;М.С., професора Бірюк Л.Я., професора
          Кузмінського А.І.
        </p>
        <p>
          Підготовка фахівців в ННІ Педагогіки і психології здійснюється за освітніми
          ступенями «Бакалавр», «Магістр», «Доктор філософії». Зміст підготовки фахівців
          визначається освітніми програмами спеціальностей, у яких зазначено перелік та
          обсяг нормативних і вибіркових навчальних дисциплін, послідовність їх вивчення,
          відповідні обсяги й форми проведення занять, засоби проведення поточного й
          підсумкового контролю, проміжної атестації, а також державної атестації.
        </p>
      </AboutContainer>
      <GoBackBottom onClick={goBack} style={{ maxWidth: 'unset' }}>
        <i className={'fa fa-arrow-left'} /> Повернутися на попередню сторінку
      </GoBackBottom>
    </>
  );
};
export default AboutFaculty;
