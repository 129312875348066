import ExternalLink from '../../../components/UI/external-link/external-link.component';

const EntranceExams = () => (
  <>
    <h2>Програма вступних іспитів</h2>
    <ul>
      <li>
        <h4>
          Програма вступних випробувань.
          <ExternalLink href="http://gnpu.edu.ua/index.php/ua/blog/269-programi-vstupnikh-viprobuvan" />
        </h4>
      </li>
    </ul>
  </>
);

export default EntranceExams;
