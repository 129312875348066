import PersonnelMap from "../../../components/personnel-map/personnel-map.component";

import academicCouncilList from "../../../data/academic-council.json";

const AcademicCouncil = () => {
  return (
    <>
      <h2>Вчена рада</h2>
      <PersonnelMap
        personnelList={academicCouncilList}
        additionalText={
          "(затверджено наказом ректора Глухівського НПУ ім.О.Довженка «Про затвердження складу вчених рад факультетів, інститутів» № 297 від 13.09.2022)"
        }
        title={"Склад ученої ради ННІ Педагогіки і психології на 2022-2023 н.р"}
      />
    </>
  );
};
export default AcademicCouncil;
