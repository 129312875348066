import styled from 'styled-components';
export const DirectorySection = styled.section`
  padding: 5px 2%;
  margin-bottom: 10px;

  @media screen and (min-width: 768px) {
    padding: 5px 10%;
    margin-bottom: 10px;
  }

  @media screen and (min-width: 1230px) {
    padding: 10px 20%;
    margin-bottom: 20px;
  }
`;

type TeacherProps = {
  hasMoreInfo: boolean;
};
export const Teacher = styled.div<TeacherProps>`
  margin: 0 auto 30px;
  max-width: 600px;
  background: var(--gradient-secondary);
  padding: 10px 5px 20px;
  border-radius: 1rem;
  box-shadow: -15px 15px 15px -15px black, inset 0 0 14px -7px black;

  h3 {
    font-size: 1.4rem;

    &:first-child {
      text-shadow: 0 0 3px #fff;
      ${({ hasMoreInfo }) => hasMoreInfo && 'cursor: pointer'};
    }
  }

  h4 {
    font-size: 1.2rem;
  }

  p {
    text-align: center !important;
    padding: 0 10px;
  }
  & > a {
    display: block;
    margin-top: 10px;
  }

  address {
    font-size: 1.2rem;
    font-style: normal;
    a {
      color: black;
      text-shadow: none;
      font-style: italic;
    }
  }

  img {
    width: 80%;
    border-radius: 0.5rem;
    max-width: 400px;
    ${({ hasMoreInfo }) => hasMoreInfo && 'cursor: pointer'};
  }
`;
