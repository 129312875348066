const TrustMailbox = () => (
  <>
    <h2>Скринька довіри</h2>
    <p>
      З метою забезпечення належного контролю за організацією освітнього процесу
      в інституті та оперативного реагування на можливі факти порушень і
      зловживань, а також для надання інформаційної та методичної допомоги
      студентам, викладачам, іншим категоріям громадян створено Скриньку довіри.
    </p>
    <p>
      <b>Скринька довіри –</b>{" "}
      <a
        href="mailto:dovira.nnipp@gnpu.edu.ua"
        style={{ display: "inline-block" }}
      >
        <i className={"fa fa-envelope"} />
        &nbsp;dovira.nnipp@gnpu.edu.ua
      </a>
    </p>
    <ul>
      <h3>Повідомлення може бути подано: </h3>
      <li>
        <p>
          <b>без зазначення авторства (анонімно)</b>
        </p>
        Анонімне повідомлення про порушення вимог Закону підлягає перевірці у
        термін не більше п’ятнадцяти днів від дня його отримання. Якщо у
        вказаний термін перевірити інформацію, що міститься в повідомленні,
        неможливо, термін розгляду повідомлення продовжується до тридцяти днів
        від дня його отримання.
      </li>{" "}
      <p>
        <b>із зазначенням авторства</b>
      </p>
      <li>
        Повідомлення підлягає перевірці у термін не більше тридцяти днів з дня
        його отримання, за результатами розгляду викривачу надається письмова
        відповідь.
      </li>
    </ul>
  </>
);

export default TrustMailbox;
