import AnnouncementShort from '../announcement-short/announcement-short.component';

import Spinner from '../UI/spinner/spinner.component';

import {
  selectAnnouncementsIsLoading,
  selectNews
} from '../../store/announcements/announcements.selector';
import { useAppSelector } from '../../store/store';

const MapAnnouncementsShort = ({ annoType = '' }) => {
  const news = useAppSelector(selectNews);
  const isLoading = useAppSelector(selectAnnouncementsIsLoading);
  if (isLoading) return <Spinner />;
  const announcements = news.filter(({ type }) => type.includes(annoType));
  return (
    <>
      {announcements.map((announcement, index) => (
        <AnnouncementShort key={index} announcement={announcement} />
      ))}
      ;
    </>
  );
};
export default MapAnnouncementsShort;
