import { FC } from 'react';

import { BackdropBtn } from './backdrop.styles';

type BackdropComponentProps = {
  toggleMenuHandler: () => void;
};
const BackdropComponent: FC<BackdropComponentProps> = ({ toggleMenuHandler }) => (
  <BackdropBtn onClick={toggleMenuHandler} />
);
export default BackdropComponent;
