import styled from "styled-components";

export const HistoryContainer = styled.section`
  margin: 0 auto 30px;
  background: var(--gradient-secondary);
  padding: 10px 10px 20px;
  border-radius: 1rem;
  box-shadow: -15px 15px 15px -15px black, inset 0 0 14px -7px black;
`;
export const DirectorsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (min-width: 768px) {
    justify-content: space-around;
  }
`;
export const HistoryDirectorContainer = styled.div`
  width: 100px;
  padding: 0;
  @media screen and (min-width: 768px) {
    width: 160px;
    padding: 5px;
  }
  img {
    width: 100px;
    height: 133px;
    @media screen and (min-width: 768px) {
      width: 150px;
      height: 200px;
    }
  }
  h5 {
    margin: 5px;
  }
`;
