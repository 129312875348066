import PDFViewer from '../../../components/pdf-viewer/pdf-viewer.component';
import { ListWithBG } from '../programs/programs.styles';

const NonCitizenStudents = () => (
  <>
    <h2>Підготовка іноземних громадян та осіб без громадянства</h2>
    <div>
      <h3>Освітні програми</h3>
      <ListWithBG>
        <li>
          Психологія. Бакалавр (англійською).{' '}
          <a
            href="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/education%2Fnon-citizen%2F%D0%9F%D1%81%D0%B8%D1%85%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%8F_%D0%B1%D0%B0%D0%BA%D0%B0%D0%BB%D0%B0%D0%B2%D1%80%20(%D0%B0%D0%BD%D0%B3%D0%BB%D1%96%D0%B9%D1%81%D1%8C%D0%BA%D0%BE%D1%8E).pdf?alt=media&token=3273fbe8-e7d0-45e4-af34-bd48581e689f"
            target="_blank"
            rel="noreferrer noopener"
          >
            Ознайомитися <i className="fa fa-external-link" />
          </a>
        </li>
        <li>
          Психологія. Магістр (англійською).
          <a
            href="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/education%2Fnon-citizen%2F%D0%9F%D1%81%D0%B8%D1%85%D0%BE%D0%BB%D0%BE%D0%B3%D1%96%D1%8F_%D0%BC%D0%B0%D0%B3%D1%96%D1%81%D1%82%D1%80%20(%D0%B0%D0%BD%D0%B3%D0%BB%D1%96%D0%B9%D1%81%D1%8C%D0%BA%D0%BE%D1%8E).pdf?alt=media&token=70f53bb8-7709-4578-8a88-748459f0552f"
            target="_blank"
            rel="noreferrer noopener"
          >
            Ознайомитися <i className="fa fa-external-link" />
          </a>
        </li>
      </ListWithBG>
    </div>
    <div>
      <h3>Графік освітнього процесу іноземних громадян</h3>
      <PDFViewer pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/education%2Fnon-citizen%2F%D0%93%D1%80%D0%B0%D1%84%D1%96%D0%BA%20%D0%9E%D0%9F_%D1%96%D0%BD%D0%BE%D0%B7%D0%B5%D0%BC%D1%86%D1%96.pdf?alt=media&token=6d73302c-4db9-4733-a1fc-a4e3ec2d09af" />
      <a
        href="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/education%2Fnon-citizen%2F%D0%93%D1%80%D0%B0%D1%84%D1%96%D0%BA%20%D0%9E%D0%9F_%D1%96%D0%BD%D0%BE%D0%B7%D0%B5%D0%BC%D1%86%D1%96.pdf?alt=media&token=6d73302c-4db9-4733-a1fc-a4e3ec2d09af"
        target="_blank"
        rel="noreferrer noopener"
      >
        Завантажити <i className="fa fa-external-link" />
      </a>
      <PDFViewer pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/education%2Fnon-citizen%2F%D0%93%D1%80%D0%B0%D1%84%D1%96%D0%BA%20%D0%9E%D0%9F_%D1%96%D0%BD%D0%BE%D0%B7%D0%B5%D0%BC%D1%86%D1%96%20(1).pdf?alt=media&token=817a41a6-42cc-4f7f-a5c8-b9e293f9a298" />
      <a
        href="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/education%2Fnon-citizen%2F%D0%93%D1%80%D0%B0%D1%84%D1%96%D0%BA%20%D0%9E%D0%9F_%D1%96%D0%BD%D0%BE%D0%B7%D0%B5%D0%BC%D1%86%D1%96%20(1).pdf?alt=media&token=817a41a6-42cc-4f7f-a5c8-b9e293f9a298"
        target="_blank"
        rel="noreferrer noopener"
      >
        Завантажити <i className="fa fa-external-link" />
      </a>
    </div>
  </>
);
export default NonCitizenStudents;
