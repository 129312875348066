import styled, { keyframes } from 'styled-components';

const toCloseIcon = keyframes`
  0% {
    stroke-dashoffset: 0;
  }
  40% {
    stroke-dashoffset: 79.9;
  }
  60% {
    stroke-dashoffset: 79.9;
    rotate: calc(var(--rotation));
  }
  100% {
    stroke-dashoffset: 0;
    rotate: var(--rotation);
  }
`;

const toOpenIcon = keyframes`
  0% {
    stroke-dashoffset: 0;
    rotate: var(--rotation);
  }
  40% {
    stroke-dashoffset: 79.9;
    rotate: var(--rotation);
  }
  60% {
    stroke-dashoffset: 79.9;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const toRotateForward = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

const toRotateBackward = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
`;

const Line = styled.line`
  stroke-width: 12;
  stroke-linecap: round;
  stroke-dasharray: 80;
  stroke-dashoffset: 0;
  stroke: var(--color-tertiary);
  filter: drop-shadow(0px 0px 2px var(--color-primary))
    drop-shadow(0px 1px 1px var(--color-secondary))
    drop-shadow(0px -1px 1px var(--color-secondary));
`;

export const LineTop = styled(Line)`
  --rotation: -45deg;
  transform-origin: 65px 45px;
`;
export const LineBottom = styled(Line)`
  --rotation: 45deg;
  transform-origin: 60px 55px;
`;
export const LineAdditional = styled(Line)``;

export const Hamburger = styled.svg`
  stroke: black;
  max-width: 3rem;
  transition: rotate 800ms 100ms;
`;
type ButtonProps = {
  isOpen: boolean;
};
export const Button = styled.button<ButtonProps>`
  background: transparent;
  border: none;
  outline: none;
  position: fixed;
  top: 100px;
  left: ${({ isOpen }) => (isOpen ? '320px' : '10px')};
  font-size: 2.3em;
  cursor: pointer;
  transition: opacity, left 0.6s cubic-bezier(0.22, 0.61, 0.36, 1);
  z-index: 1002;
  padding: ${({ isOpen }) => (isOpen ? '0' : '10px')};

  @media screen and (min-width: 768px) {
    top: 140px;
    padding: 20px;
  }

  @media screen and (min-width: 1232px) {
    left: ${({ isOpen }) => (isOpen ? ' 420px' : '10px')};
  }

  ${Hamburger} {
    animation: ${({ isOpen }) => (isOpen ? toRotateBackward : toRotateForward)} 5s linear
      infinite;
    rotate: ${({ isOpen }) => (isOpen ? '2turn' : undefined)};

    ${LineTop}, ${LineBottom} {
      animation: ${({ isOpen }) => (isOpen ? toCloseIcon : toOpenIcon)} 0.6s forwards;
    }
    ${LineAdditional} {
      opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
    }

    &:hover {
      opacity: 0.7;
    }
  }
`;
