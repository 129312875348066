import HiddenPDF from '../../../components/hidden-pdf/hidden-pdf.component';

const NewUkrainianSchool = () => (
  <>
    <h2>
      Навчально-наукова лабораторія "Сучасна підготовка майбутніх учителів
      початкових класів до реалізації Концепції Нова Українська школа"
    </h2>
    <HiddenPDF
      btnText="Положення про лабораторію"
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Fnew-ukr-school%2F%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F_%D0%BF%D1%80%D0%BE_%D0%9DH%D0%9B.docx.pdf?alt=media&token=3c21a1ec-c450-405d-88fd-d4dc0f4f1a1d"
    />

    <HiddenPDF
      btnText="Склад лабораторії"
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Fnew-ukr-school%2F%D0%A1%D0%BA%D0%BB%D0%B0%D0%B4%20%D0%BB%D0%B0%D0%B1%D0%BE%D1%80%D0%B0%D1%82%D0%BE%D1%80%D1%96%D1%97.pdf?alt=media&token=9f54c5e5-f43f-4f27-9524-7b1823ae27c5"
    />

    <HiddenPDF
      btnText="Перспективний план лабораторії 2021-2025рр."
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Fnew-ukr-school%2F%D0%9F%D0%B5%D1%80%D1%81%D0%BF%D0%B5%D0%BA%D1%82%D0%B8%D0%B2%D0%BD%D0%B8%D0%B9%20%D0%BF%D0%BB%D0%B0%D0%BD%202021-2025.pdf?alt=media&token=f69db969-2ceb-4479-8ea5-6aead383b0b4"
    />

    <HiddenPDF
      btnText="План роботи лабораторії 2023 рік"
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Fnew-ukr-school%2F%D0%9F%D0%BB%D0%B0%D0%BD%20%D1%80%D0%BE%D0%B1%D0%BE%D1%82%D0%B8%20%D0%9DH%D0%9B%202023.pdf?alt=media&token=9ee56eaa-60ed-4061-9f6c-a62f4a9e28b4"
    />

    <HiddenPDF
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Fnew-ukr-school%2F%D0%97%D0%B2%D1%96%D1%82%20%D0%BB%D0%B0%D0%B1%D0%BE%D1%80%D0%B0%D1%82%D0%BE%D1%80%D1%96%D1%97%202022%20%D0%9A%D0%A2%D0%9C%D0%9F%D0%9E.pdf?alt=media&token=8f25f5bd-568e-484e-8c1f-002a05ce8026"
      btnText="Звіт лабораторії 2022 рік"
    />
    <HiddenPDF
      btnText="Звіт лабораторії 2021 рік"
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Fnew-ukr-school%2F%D0%97%D0%B2%D1%96%D1%82%20%D0%BB%D0%B0%D0%B1%D0%BE%D1%80%D0%B0%D1%82%D0%BE%D1%80%D1%96%D1%97%202021.pdf?alt=media&token=4525c2b5-3642-4420-8499-9c73338b936b"
    />
  </>
);

export default NewUkrainianSchool;
