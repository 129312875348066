import ReactPlayer from "react-player";

const MaterialBase = () => (
  <>
    <h2>Матеріально-технічна база</h2>
    <ReactPlayer
      width="100%"
      height="auto"
      controls
      light={
        <img
          src="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/about%2Fmtb%2Fmaterial-base-thumbnail.webp?alt=media&token=f028c721-b17a-4b75-b178-d8c501b595b8"
          alt="Thumbnail"
        />
      }
      url="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/about%2Fmtb%2Fmaterial-base.mp4?alt=media&token=0ffed1ba-dc51-4644-a636-127b1953d2b6"
    />
  </>
);

export default MaterialBase;
