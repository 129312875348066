import { FC, useState } from 'react';
import { PDFOpener } from './hidden-pdf.styles';
import PDFViewer from '../pdf-viewer/pdf-viewer.component';

type HiddenPDFProps = {
  btnText: string;
  pdfUrl: string;
};
const HiddenPDF: FC<HiddenPDFProps> = ({ btnText, pdfUrl }) => {
  const [openList, setOpenList] = useState(true);
  return (
    <>
      <PDFOpener onClick={() => setOpenList(!openList)}>
        {btnText}{' '}
        <i
          className="fa fa-chevron-down"
          style={{
            transform: openList ? 'rotate(-90deg)' : undefined
          }}
        />
      </PDFOpener>
      <div style={openList ? { display: 'none' } : undefined}>
        <PDFViewer pdfUrl={pdfUrl} />
      </div>
    </>
  );
};
export default HiddenPDF;
