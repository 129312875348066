import styled from 'styled-components';

export const PostgraduatesCard = styled.div`
  margin: 0 auto 30px;
  max-width: 900px;
  background: var(--gradient-secondary);
  padding: 10px 10px 20px;
  border-radius: 1rem;
  box-shadow: -15px 15px 15px -15px black, inset 0 0 14px -7px black;
  &::after {
    content: '';
    display: block;
    clear: both;
  }
  @media screen and (min-width: 768px) {
    padding: 20px 20px 30px;
  }
`;
export const PostgraduatesCardName = styled.h3`
  text-shadow: 0px 0px 1px white;
  cursor: pointer;
`;
export const PostgraduatesCardImage = styled.img`
  width: 80%;
  border-radius: 0.5rem;
  max-width: 400px;
  cursor: pointer;

  @media screen and (min-width: 1232px) {
    float: left;
    margin-top: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
  }
`;
