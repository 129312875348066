import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';

import Spinner from '../UI/spinner/spinner.component';
import Page404 from '../page404/page404.component';
import AnnounceDate from '../announce-date/announce-date.component';

import {
  selectAnnouncementsIsLoading,
  selectNews
} from '../../store/announcements/announcements.selector';
import { replaceUrlsAndEmails } from '../../utils/replaceUrlsAndEmails/replaceUrlsAndEmails';
import { useAppSelector } from '../../store/store';

import { AdditionalInformation, AnnouncementContainer } from './announcement.styles';
import {
  GoBackBottom,
  GoBackTop
} from '../postgraduate-profile/postgraduate-profile.styles';

type AnnouncementRouteParams = {
  id: string;
};
type AnnouncementProps = {
  annoType?: string;
};
const Announcement: FC<AnnouncementProps> = ({ annoType = '' }) => {
  const navigate = useNavigate();
  const { id } = useParams<keyof AnnouncementRouteParams>() as AnnouncementRouteParams;
  const news = useAppSelector(selectNews);
  const isLoading = useAppSelector(selectAnnouncementsIsLoading);
  if (isLoading) return <Spinner />;

  const announcement = news
    .filter(({ type }) => type.includes(annoType))
    .find(({ linkTo }) => linkTo === id);

  const goBack = () => navigate(-1);

  if (!announcement) {
    return <Page404 />;
  }

  const { shortCut, imagesList, title, text, date, onlyImage } = announcement;
  return (
    <>
      <GoBackTop onClick={goBack} style={{ maxWidth: 'unset' }}>
        <i className={'fa fa-arrow-left'} /> Повернутися на попередню сторінку
      </GoBackTop>
      <AnnouncementContainer>
        {title && <h2>{title}</h2>}
        {shortCut && <p>{shortCut}</p>}
        {imagesList[0]?.original && (
          <ImageGallery items={imagesList} showPlayButton={false} />
        )}
        {text[0] &&
          text.map((paragraph, index) => (
            <p
              key={index}
              dangerouslySetInnerHTML={{
                __html: replaceUrlsAndEmails(paragraph)
              }}
            />
          ))}
        {/*{component && <div dangerouslySetInnerHTML={{ __html: component }} />}*/}
        {onlyImage && <img src={onlyImage} alt={title} />}
        <hr />
        <AdditionalInformation>
          {date && <AnnounceDate date={date} />}
        </AdditionalInformation>
      </AnnouncementContainer>
      <GoBackBottom onClick={goBack} style={{ maxWidth: 'unset' }}>
        <i className={'fa fa-arrow-left'} /> Повернутися на попередню сторінку
      </GoBackBottom>
    </>
  );
};
export default Announcement;
