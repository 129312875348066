import { Route, Routes } from 'react-router-dom';
import Page404 from '../components/page404/page404.component';
import Starostat from '../pages/student-life/starostat/starostat.component';
import Extracurricular from '../pages/student-life/extracurricular/extracurricular.component';
import LeisureTime from '../pages/student-life/leisure-time/leisure-time.component';
import HonorBoard from '../pages/student-life/honor-board/honor-board.component';
import PhotoGallery from '../pages/student-life/photo-gallery/photo-gallery.component';
import Announcement from '../components/announcement/announcement.component';

const StudentLife = () => {
  return (
    <Routes>
      <Route index element={<Page404 />} />
      <Route path="starostat" element={<Starostat />} />
      <Route path="extracurricular" element={<Extracurricular />} />
      <Route
        path="extracurricular/:id"
        element={<Announcement annoType={'extracurricular'} />}
      />
      <Route path="leisure-time" element={<LeisureTime />} />
      <Route path="honor-board" element={<HonorBoard />} />
      <Route path="photo-gallery" element={<PhotoGallery />} />
      <Route path="/*" element={<Page404 />} />
    </Routes>
  );
};

export default StudentLife;
