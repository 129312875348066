import { createGlobalStyle } from 'styled-components';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { Theme } from './components/theme/theme';

type GlobalStylesProps = {
  theme: Theme;
};
export const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
  //font-family: 'Kurale', serif;
  //font-family: 'Oswald', sans-serif;
  //font-family: 'Playfair Display', serif;
  //font-family: 'Yeseva One', cursive;

  :root {
    --color-primary: ${({ theme }) => theme.colors.primary};
    --color-secondary: ${({ theme }) => theme.colors.secondary};
    --color-tertiary: ${({ theme }) => theme.colors.tertiary};
    --color-primary-darker: ${({ theme }) => theme.colors.primaryDarker};
    --color-secondary-darker: ${({ theme }) => theme.colors.secondaryDarker};
    --color-tertiary-darker: ${({ theme }) => theme.colors.tertiaryDarker};
    --color-tertiary-opacity: ${({ theme }) => theme.colors.tertiaryOpacity};
    --bg-color-main: ${({ theme }) => theme.colors.bgMain};
    --text-shadow-main: ${({ theme }) => theme.colors.textShadowMain};
    --text-shadow-heading: var(--color-primary) -1px 1px 1px;
    --text-shadow-link: var(--color-secondary) 1px 0 3px;
    --gradient-primary: linear-gradient(180deg, var(--color-primary) 0%, var(--color-secondary) 100%);
    --gradient-primary-reversed: linear-gradient(0deg, var(--color-primary) 0%, var(--color-secondary) 100%);
    --gradient-secondary: linear-gradient(20deg, var(--color-tertiary-opacity) 0%, var(--color-secondary) 50%, var(--color-tertiary) 100%);
  }

  html {
    scroll-behavior: smooth;

    @media screen and (min-width: 1600px) {
      font-size: 1.2rem;
    }
    @media screen and (max-width: 800px) {
      font-size: 0.9rem;
    }
    @media screen and (max-width: 650px) {
      font-size: 0.8rem;
    }
  }

  body {
    margin: 0;
    background-color: #a0c18280;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: 'Playfair Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #303030;

    #root {
      max-width: 2000px;
      background-color: white;
      box-shadow: 0 0 15px -5px black;
      margin: 0 auto;
    }

    img {
      max-width: 100%;
    }
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Kurale', serif;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  a {
    text-decoration: none;
    color: #303030;
  }

  * {
    box-sizing: border-box;
  }

  .main {
    display: flex;
    height: 100vh;
  }

  .fa-link, .fa-external-link {
    font-size: 0.8em;
  }

  /* Works on Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: var(--color-primary) var(--color-secondary);
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: var(--color-secondary);
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--color-primary-darker);
    border-radius: 20px;
    border: 3px solid var(--color-secondary);
  }

  // SVG ICON STYLES
  .image-gallery-icon {

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: var(--color-primary);
      }
    }

    &:focus {
      // a11y support
      outline: 2px solid var(--color-primary);
    }
  }

  .image-gallery-bullets .image-gallery-bullet {
    &:focus {
      background: var(--color-primary);
      border: 1px solid var(--color-primary);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: var(--color-primary);
        border: 1px solid var(--color-primary);
      }

      &.active:hover {
        background: var(--color-primary);
      }
    }
  }


  .image-gallery-thumbnail {
    width: auto;
    max-width: 100px;

    &.active,
    &:focus {
      border: 4px solid var(--color-primary);

      @media (max-width: 768px) {
        border: 3px solid var(--color-primary);
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        border: 4px solid var(--color-primary);

        @media (max-width: 768px) {
          border: 3px solid var(--color-primary);
        }
      }
    }

  }
`;
