import { CenteredCell, Table } from './selective.styles';
import ExternalLink from '../../../components/UI/external-link/external-link.component';

const TableSpecialBachelor = () => (
  <Table>
    <thead>
      <tr>
        <th>Освітньо-професійна програма (повна назва)</th>
        <th>Вибірковий освітній компонент (повна назва)</th>
        <th>Обсяг кредитів</th>
        <th>Силабус</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td rowSpan={30}>
          ОП «Початкова освіта та інформатика»
          <br />
          ОП «Початкова освіта та мова і література (англійська)»
          <br />
          ОП «Початкова освіта та музичне мистецтво»
        </td>
        <td>Теоретико-методичні основи наукових досліджень</td>
        <CenteredCell>3</CenteredCell>
        <CenteredCell rowSpan={30}>
          <ExternalLink
            href="https://drive.google.com/drive/folders/1eitI7SLF055Qr4DUPM9sn5mGQbIAv8d0?usp=share_link"
            text=""
          />
        </CenteredCell>
      </tr>
      <tr>
        <td>Основи медіа грамотності вчителя початкової школи</td>
        <CenteredCell>3</CenteredCell>
      </tr>
      <tr>
        <td>Формування навичок самостійності у дітей молодшого шкільного віку</td>
        <CenteredCell>3</CenteredCell>
      </tr>
      <tr>
        <td>Інтегроване вивчення предметів у початковій школі</td>
        <CenteredCell>3</CenteredCell>
      </tr>
      <tr>
        <td>
          Основи культури усного і писемного мовлення (Основи культури і техніки мовлення,
          каліграфія)
        </td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Культура фахового мовлення з методикою навчання письма</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>
          Практикум з розвитку усного і писемного українського мовлення з основами
          каліграфії
        </td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Основи психодіагностики</td>
        <CenteredCell>3</CenteredCell>
      </tr>
      <tr>
        <td>Психологія сім’ї</td>
        <CenteredCell>3</CenteredCell>
      </tr>
      <tr>
        <td>Психологія особистості</td>
        <CenteredCell>3</CenteredCell>
      </tr>
      <tr>
        <td>Актуальні проблеми психології та педагогіки початкової освіти</td>
        <CenteredCell>3</CenteredCell>
      </tr>
      <tr>
        <td>Основи копірайтингу у початковій школі</td>
        <CenteredCell>3</CenteredCell>
      </tr>
      <tr>
        <td>Практикум із розвитку зв’язного мовлення</td>
        <CenteredCell>3</CenteredCell>
      </tr>
      <tr>
        <td>Педагогіка (Основи педагогічної майстерності)</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Основи інклюзивної педагогіки</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Інклюзивне навчання в початковій школі</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>
          Формування інклюзивної компетентності майбутніх учителів початкових класів
        </td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Українська мова як державна у початкових класах</td>
        <CenteredCell>3</CenteredCell>
      </tr>
      <tr>
        <td>
          Тренінг-курс міжособистісного спілкування у дітей молодшого шкільного віку
        </td>
        <CenteredCell>3</CenteredCell>
      </tr>
      <tr>
        <td>Інформаційні технології у професійній діяльності вчителя</td>
        <CenteredCell>3</CenteredCell>
      </tr>
      <tr>
        <td>Логіка на уроках математики у початкових класах</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Актуальні проблеми початкової освіти</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Практикум розв’язування задач з математики в початковій школі</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Компетентнісний підхід до мовно-літературної освіти молодших школярів</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Компетентнісний підхід до математичної освіти молодших школярів</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Компетентнісний підхід до інформатичної освіти молодших школярів</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Педагогічні технології в початковій школі</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Сучасні технології в освітній діяльності</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Використання цифрових технологій в освітньому процесі початкової школи;</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Основи риторики в початковій школі</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td rowSpan={3}>ОП «Початкова освіта та інформатика»</td>
        <td>Інформатика (Інформаційно-комунікаційні технології в початковій освіті</td>
        <CenteredCell>9</CenteredCell>
        <CenteredCell rowSpan={3}>
          <ExternalLink
            href="https://drive.google.com/drive/folders/1v5L8FnwGkmSdJNVSyVuPzAmNrn6KkL5w?usp=share_link"
            text=""
          />
        </CenteredCell>
      </tr>
      <tr>
        <td>Інформатика (основи Web UI розробки )</td>
        <CenteredCell>9</CenteredCell>
      </tr>
      <tr>
        <td>Інформатика (цифрова безпека та комунікація в онлайн)</td>
        <CenteredCell>9</CenteredCell>
      </tr>
      <tr>
        <td rowSpan={3}>ОП «Початкова освіта та мова і література (англійська)»</td>
        <td>Іноземна мова (Культура професійного англійського мовлення)</td>
        <CenteredCell>9</CenteredCell>
        <CenteredCell rowSpan={3}>
          <ExternalLink
            href="https://drive.google.com/drive/folders/1NRj13pzXzxvqMiN7w30U2x-0PZcb7enB?usp=share_link"
            text=""
          />
        </CenteredCell>
      </tr>
      <tr>
        <td>Іноземна мова (Ділова англійська мова)</td>
        <CenteredCell>9</CenteredCell>
      </tr>
      <tr>
        <td>Іноземна мова (Іншомовна комунікація в навчальному дискурсі)</td>
        <CenteredCell>9</CenteredCell>
      </tr>
      <tr>
        <td rowSpan={3}>ОП «Початкова освіта та музичне мистецтво»</td>
        <td>Музичне мистецтво (Диригування)</td>
        <CenteredCell>9</CenteredCell>
        <CenteredCell rowSpan={3}>
          <ExternalLink
            href="https://drive.google.com/drive/folders/18_p9NjU5zlKWAyuDWF8_RqggqtPXglBI?usp=share_link"
            text=""
          />{' '}
        </CenteredCell>
      </tr>
      <tr>
        <td>Основи техніки хорового диригування</td>
        <CenteredCell>9</CenteredCell>
      </tr>
      <tr>
        <td>
          Теоретичні й практичні основи управлінської комунікації хорового диригента
        </td>
        <CenteredCell>9</CenteredCell>
      </tr>
      <tr>
        <td rowSpan={12}>Середня освіта (Музичне мистецтво)</td>
        <td>Основи акомпонементу</td>
        <CenteredCell>5</CenteredCell>
        <CenteredCell rowSpan={12}>
          <ExternalLink
            href="https://drive.google.com/drive/folders/15z4hIGtKUkf2M02zHqoHeJGg7JMD3h1O?usp=share_link"
            text=""
          />
        </CenteredCell>
      </tr>
      <tr>
        <td>Ансамблеві форми музичного виконавства</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Практикум шкільного репертуару</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Основи використання дистанційних технологій в загальній мистецькій освіті</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Цифрові технології за професійним спрямуванням</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Сучасне українське хорове мистецтво</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Українська народна музична творчість</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Гурткова робота з мистецтва</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Історія мистецтва</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Основи інклюзивної музичної педагогіки</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Теорія та історія музичної педагогіки</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Художньо-прикладне мистецтво</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td rowSpan={20}>ОПП Психологія</td>
        <td>Дошкільна психологія</td>
        <CenteredCell>4</CenteredCell>
        <CenteredCell rowSpan={20}>
          <ExternalLink
            href="https://drive.google.com/drive/folders/1tacQp6Dr5i7N9vuHOlVOjk0aibRW1Ki5?usp=share_link"
            text=""
          />
        </CenteredCell>
      </tr>
      <tr>
        <td>Сексологія та психологія статі</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Тренінг спілкування та самопізнання</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Психологія підліткового віку</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Використання методів математичної статистики у психологічних дослідженнях</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Теорії особистості</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Психологія спорту</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Психогігієна</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Психологія засобів масової інформації</td>
        <CenteredCell>3</CenteredCell>
      </tr>
      <tr>
        <td>Психологія молодшого шкільного віку</td>
        <CenteredCell>3</CenteredCell>
      </tr>
      <tr>
        <td>Самоорганізація особистості</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Психологія девіантної поведінки</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Психодіагностика в системі освіти</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Життєві кризи особистості</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Основи дефектології та логопедії</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Психологія інформаційної безпеки особистості</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Основи транзактного аналізу</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Психологія Інтернету</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Психологія здоров’я</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Ґендерна психологія</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td rowSpan={27}>ОПП «Соціальна робота та психологія»</td>
        <td>Основи інклюзивної освіти</td>
        <CenteredCell>4</CenteredCell>
        <CenteredCell rowSpan={27}>
          <ExternalLink
            href="https://drive.google.com/drive/folders/1qtnlDNlCwkQ7DYZNfZ9ed7Z6t6O3atUu?usp=share_link"
            text=""
          />
        </CenteredCell>
      </tr>
      <tr>
        <td>Соціально-реабілітаційна робота з дітьми молодшого шкільного віку з ДЦП</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Соціалізація особистості</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Людина в сучасному соціумі</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Соціально-педагогічна профілактика</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Демографія та соціальна статистика в соціальній роботі</td>
        <CenteredCell>6</CenteredCell>
      </tr>
      <tr>
        <td>Практикум соціального працівника</td>
        <CenteredCell>6</CenteredCell>
      </tr>
      <tr>
        <td>Основи дефектології</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Теорія і технології соціально-педагогічної роботи з сім’ями</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Соціальна робота з дитячими та молодіжними організаціями</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Клінічна психологія</td>
        <CenteredCell>8</CenteredCell>
      </tr>
      <tr>
        <td>Диференційна психологія</td>
        <CenteredCell>8</CenteredCell>
      </tr>
      <tr>
        <td>Конфлікти в закладах загальної середньої освіти</td>
        <CenteredCell>8</CenteredCell>
      </tr>
      <tr>
        <td>Соціальна геронтологія</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Соціальна робота з людьми похилого віку</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>
          Рекламно-інформаційні технології та інноваційні методи в соціальній роботі
        </td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Технології роботи соціального гувернера</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Технології соціальної роботи з дітьми групи ризику</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Тренінг усвідомленого батьківства</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Коучинг у професійній діяльності соціального працівника</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Арт-терапія</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Основи психологічного консультування та психокорекції з практикумами</td>
        <CenteredCell>8</CenteredCell>
      </tr>
      <tr>
        <td>Психологія девіантної поведінки</td>
        <CenteredCell>8</CenteredCell>
      </tr>
      <tr>
        <td>Психодіагностика в системі освіти</td>
        <CenteredCell>8</CenteredCell>
      </tr>
      <tr>
        <td>Психологічна служба в системі освіти</td>
        <CenteredCell>8</CenteredCell>
      </tr>
      <tr>
        <td>Психологія творчості і обдарованості</td>
        <CenteredCell>8</CenteredCell>
      </tr>
      <tr>
        <td>Психологія інформаційної безпеки особистості</td>
        <CenteredCell>8</CenteredCell>
      </tr>
    </tbody>
  </Table>
);
export default TableSpecialBachelor;
