import PostgraduateCard, {
  Postgraduate
} from '../../../components/postgraduate-card/postgraduate-card.component';

import postgraduatesList from '../../../data/postgraduates.json';

import {
  PostgraduatesCard,
  PostgraduatesCardImage,
  PostgraduatesCardName
} from '../../../components/postgraduate-card/postgraduate-card.styles';

const Postgraduates = () => {
  const tishchenko = postgraduatesList.find(
    (postgraduate, index) => postgraduate.linkTo === 'tischenko-liudmila'
  );
  return (
    <>
      <h2>Аспіранти та випускники</h2>
      <h2>Аспіранти</h2>
      {postgraduatesList.map((postgraduate, index) => {
        if (postgraduate.linkTo === 'tischenko-liudmila') return null;
        return (
          <PostgraduateCard postgraduate={postgraduate as Postgraduate} key={index} />
        );
      })}
      <h2>Випускники</h2>

      <PostgraduatesCard>
        <PostgraduatesCardName>Сєрих Тетяна Миколаївна</PostgraduatesCardName>
        <PostgraduatesCardImage
          src="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Falumni-photos%2Fserih-tetiana.jpg?alt=media&token=7b57d976-1e43-4d26-a894-ce15bedffdba"
          alt="Сєрих Тетяна Миколаївна"
        />
        <p>
          Доктор філософії, методист Комунального закладу Сумський Палац дітей та юнацтва,
          керівник гуртка гуманітарного напрямку.
        </p>
        <p>
          2020 року закінчила аспірантуру Глухівського національного педагогічного
          університету імені Олександра Довженка та успішно захистила дисертацію на
          здобуття наукового ступеня доктора філософії з галузі знань 01 Освіта/Педагогіка
          зі спеціальності 013 Початкова освіта.
        </p>
        <p>
          <b>Тема дисертації: </b>«Формування полікультурної комунікативної компетентності
          майбутніх учителів початкової школи».
        </p>
      </PostgraduatesCard>
      <PostgraduatesCard>
        <PostgraduatesCardName>Плугіна Альона Петрівна</PostgraduatesCardName>
        <PostgraduatesCardImage
          src="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Falumni-photos%2Fplugina-aliona.jpg?alt=media&token=c9936b65-0b63-4a7a-bfe9-23faa31522aa"
          alt="Плугіна Альона Петрівна"
        />
        <p>
          Доктор філософії, старший викладач кафедри педагогіки та психології початкової
          освіти Глухівського національного педагогічного університету імені Олександра
          Довженка.
        </p>
        <p>
          2020 року закінчила аспірантуру Глухівського національного педагогічного
          університету імені Олександра Довженка та успішно захистила дисертацію на
          здобуття наукового ступеня доктора філософії із галузі знань 01
          Освіта/Педагогіка зі спеціальності 013 Початкова освіта.
        </p>
        <p>
          <b>Тема дисертації: </b>«Підготовка майбутніх учителів початкової школи до
          формування в учнів медіакомпетентності».
        </p>
      </PostgraduatesCard>
      <PostgraduateCard postgraduate={tishchenko as Postgraduate} />
    </>
  );
};
export default Postgraduates;
