const BiriukL = () => (
  <>
    <h2>
      Наукова школа доктора педагогічних наук, професора Бірюк Людмили Яківни
    </h2>

    <img
      src="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Fschools%2Fbiriuk-l.webp?alt=media&token=1ed45ae8-89d6-4ceb-81cb-b509f3f9c612"
      alt="Рисунок1"
    />

    <h4>
      «ТЕОРЕТИКО-МЕТОДИЧНІ ОСНОВИ ФОРМУВАННЯ ПРОФЕСІЙНОЇ КОМПЕТЕНТНОСТІ
      МАЙБУТНІХ УЧИТЕЛІВ ПОЧАТКОВОЇ ОСВІТИ»
    </h4>

    <p>
      <strong>
        <em>Рік заснування: </em>
      </strong>
      <span>2021.</span>
    </p>
    <p>
      <span>
        Наукову школу затверджено рішенням вченої ради від 01.12.2021 р.
        протокол №5
      </span>
    </p>
    <p>
      <span>
        <strong>
          <em>Дані про керівника наукової школи:</em>
        </strong>
      </span>
    </p>
    <p>
      <span>
        <strong>Бірюк Людмила Яківна </strong>народилася 1 грудня 1951 року в
        селі Засулля Недригайлівського району Сумської області. У 1974 році
        закінчила Глухівський державний педагогічний інститут за спеціальністю
        «Педагогіка і методика початкового навчання».
      </span>
    </p>
    <p>
      <span>
        Дисертацію кандидата педагогічних наук захистила 18 вересня 1996 р. на
        засіданні спеціалізованої вченої ради К 01.32.01 Інституту педагогіки
        АПН України, м. Київ за темою «Обогащение словарного запаса младших
        школьников в процессе изучения русских народных сказок».
      </span>
    </p>
    <p>
      <span>
        Вчене звання доцента кафедри іноземних мов присвоєно рішенням
        Атестаційної колегії Міністерства освіти і науки України від 20 червня
        2002 року.
      </span>
    </p>
    <p>
      <span>
        Доктор педагогічних наук зі спеціальності «Теорія і методика професійної
        освіти» з 2012 року. Дисертацію «Теорія і технології формування
        комунікативної компетентності з російської мови майбутнього вчителя
        початкових класів у процесі професійної підготовки» захистила 26 вересня
        2012 р. у спеціалізованій вченій раді Д 73.053.02 Черкаського
        національного університету імені Богдана Хмельницького.
      </span>
    </p>
    <p>
      <span>Професор із 2015 р.</span>
    </p>
    <p>
      <span>
        У 2018 році отримала почесне звання Президента України «Заслужений
        працівник освіти України».
      </span>
    </p>
    <p>
      <span>
        Із 2013 року по теперішній час – завідувач кафедри педагогіки і
        психології початкової освіти Глухівського національного педагогічного
        університету імені Олександра Довженка. При кафедрі функціонує
        аспірантура зі спеціальності 013 Початкова освіта.
      </span>
    </p>
    <p>
      <span>
        <strong>
          <em> </em>
        </strong>
      </span>
    </p>
    <p>
      <span>
        <strong>
          <em>
            Зміст та результати наукових досліджень у визначеному напрямі:
          </em>
        </strong>
      </span>
    </p>
    <ul>
      <li>
        <span>
          формування професійної компетентності майбутніх учителів початкової
          школи в процесі навчання у закладах вищої освіти;
        </span>
      </li>
      <li>
        <span>
          проведення теоретичних та експериментальних досліджень актуальних
          проблем професійної підготовки майбутніх учителів початкових класів у
          руслі компетентнісного, комунікативного та креативного підходів;
        </span>
      </li>
      <li>
        <span>
          проведення науково-методичних конференцій, семінарів, нарад у
          співробітництві з іншими науковими установами, вищими освітніми
          закладами та міжнародними партнерами.
        </span>
      </li>
    </ul>
    <p>
      <span>
        <em>
          <strong>
            Наукові видання (монографії, наукові збірники, наукові статті, в
            тому числі у{' '}
          </strong>
        </em>
        <em>
          <strong>Scopus</strong>
        </em>
        <em>
          <strong>, </strong>
        </em>
        <em>
          <strong>Web</strong>
        </em>{' '}
        <em>
          <strong>of</strong>
        </em>{' '}
        <em>
          <strong>science</strong>
        </em>
        <em>
          <strong>)</strong>
        </em>
      </span>
    </p>
    <p>
      <span>За тематикою наукової школи було видано:</span>
    </p>
    <p>
      <span>
        <strong>7 монографій</strong> (Бірюк Л.Я.{' '}
        <em>
          Комунікативна компетентність майбутнього вчителя початкових класів:
          теорія і технології формування (на матеріалі методики викладання
          російської мови)
        </em>{' '}
        (2009); Бірюк Л.Я.{' '}
        <em>
          Розвиток педагогічної майстерності викла­да­ча в умовах непе­рервної
          освіти{' '}
        </em>
        / Ав­торським є п. «Професійне становлення вчителя початкової школи
        Україні» (2008); Бірюк Л.Я.{' '}
        <em>
          Комплексний підхід до фахової підготовки сучасного вчителя початкових
          класів
        </em>{' '}
        / авторським є п. 2.5. Кредитно-модульна і модульно-рейтингова
        технології оволодіння комунікативною компетентністю з російської мови
        майбутніми вчителями початкових класів (2012); Бірюк Л.Я., Пішун С.Г.
        Теоретичні засади формування професійно-комунікативних якостей майбутніх
        вчителів початкових класів. Мішедченко В.В. Социальные аспекты
        формирования творческой личности студента в процессе
        музыкально-образовательной деятельности. Решетняк В.Ф. Розвиток
        інтелектуального потенціалу особистості в сучасній системі
        загальноосвітньої підготовки // <em>Transformations</em> <em>in</em>{' '}
        <em>Contemporary</em> <em>Society</em>
        <em>: </em>
        <em>Humanitarian</em> <em>Aspects</em>
        <em>. </em>
        <em>Opole</em> (2017); Бірюк Л.Я., Пішун С.Г. Теоретичні основи
        формування комунікативної компетентності майбутніх учителів початкової
        школи засобами тренінгової діяльності. Мішедченко В.В. Застосування
        ігрових художньо-педагогічних технологій навчання на уроках музики в
        початковій школі <em>Modern</em> <em>Technologies</em> <em>in</em>{' '}
        <em>Education</em>
        <em>: </em>
        <em>Opole</em> (2018); Бірюк Л.Я., Пішун С.Г. Системний підхід як
        методологічне підґрунтя формування комунікативної компетентності
        майбутніх учителів початкової школи.
        <em>Series</em> <em>of</em> <em>monographs</em> <em>Faculty</em>{' '}
        <em>of</em> <em>Architecture</em>
        <em>, </em>
        <em>Civil</em> <em>Engineering</em> <em>and</em> <em>Applied</em>{' '}
        <em>Arts</em>
        <em>. </em>
        <em>Wydawnictwo</em> <em>Wy</em>
        <em>?</em>
        <em>szej</em> <em>Szko</em>
        <em>?</em>
        <em>y</em> <em>Technicznej</em> <em>w</em> <em>Katowicach</em> (2019).
        Мішедченко В.В. Формирование искусствоведческой компетентности будущих
        учителей начальных классов и музыки в процессе методической подготовки{' '}
        <em>Problems</em> <em>of</em> <em>modern</em> <em>didactics</em>{' '}
        <em>in</em> <em>higher</em> <em>and</em> <em>secondary</em>{' '}
        <em>schools</em>
        <em>: </em>
        <em>Saarbr</em>
        <em>?</em>
        <em>cken</em> (2017);
      </span>
    </p>
    <p>
      <span>
        Результати наукового доробку висвітлюються також у{' '}
        <strong>наукових статтях</strong>, яких понад <strong>50</strong>{' '}
        (загальним обсягом понад 500 сторінок).
      </span>
    </p>
    <p>
      <span>
        <em>
          <strong>Навчальні видання (підручники та посібники)</strong>
        </em>
        <em>:</em>
      </span>
    </p>
    <p>
      <span>
        <strong>9 - підручників та посібників. </strong>
      </span>
    </p>
    <p>
      <span>
        Бірюк Л.Я. Теорія і технологія формування комунікативної компетентності
        з російської мови майбутнього вчителя у процесі професійної підготовки:
        програма спецкурсу (2006) (Гриф МОНУ); Бірюк Л.Я. Лінгводидактичний
        словник-довідник (2006) (Гриф МОНУ); Бірюк Л.Я., Альмерот О.В.
        Артпедагогічний словник: навчальний посібник для студентів факультету
        початкової освіти (2017); Бірюк Л.Я., Шейко Г.Д. Психолого-педагогічні
        аспекти спілкування викладача і студентів (2018); Бірюк Л.Я., Носова
        І.Є. Особливості професійного педагогічного спілкування у закладах вищої
        освіти (2018); Бірюк Л.Я., Сєрих Т.М. Словник-довідник з полікультурної
        комунікації: навчальний посібник (2019); Мішедченко В.В. Музичний
        калейдоскоп: музичні ігри, загадки, вірші, казки та пісні (2015); Пішун
        С.Г., Гречаник Н.І. Культурологічний аспект професійної підготовки
        майбутніх учителів (2018); Шейко Г.Д. Психологія сім’ї. Розділ «Типові
        історичні наукові уявлення про психологію сім’ї» (2015).
      </span>
    </p>
    <p>
      <span>
        <em>
          <strong> </strong>
        </em>
      </span>
    </p>
    <p>
      <span>
        <em>
          <strong>
            Публічне визнання досягнень представників школи (премії, перемоги у
            конкурсах, гранти, медалі, дипломи, інші нагороди, публікації у
            рейтингових журналах, членство у комітетах наукових конференцій
            міжнародного рівня та редакційних колегіях наукових журналів і
            збірників тощо)
          </strong>
        </em>
      </span>
    </p>
    <p>
      <span>
        Науково-педагогічні працівники кафедри педагогіки і психології
        початкової освіти нагороджені:
      </span>
    </p>
    <p>
      <span>
        Доктор педагогічних наук, професор{' '}
        <strong>
          <em>Бірюк Л.Я.:</em>
        </strong>
      </span>
    </p>
    <p>
      <span>
        - нагрудним знаком «Відмінник освіти України» (2001); Грамотою Сумської
        обласної ради (2013); Грамотою Глухівської міської ради (2013); Грамотою
        Глухівського державного педагогічного університету (2010); Грамотою
        Глухівського національного педагогічного університету імені Олександра
        Довженка (2012); Почесною відзнакою «Професіонал року 2015»; Почесним
        званням «Заслужений працівник освіти України», 2018 р.(указ президента
        України № 300).
      </span>
    </p>
    <p>
      <span>
        Доктор педагогічних наук, доцент{' '}
        <strong>
          <em>Кловак Г.Т.: </em>
        </strong>
        нагрудним знаком «Відмінник освіти України (1998).
      </span>
    </p>
    <p>
      <span>
        Кандидат педагогічних наук, доцент{' '}
        <strong>
          <em>Гречаник Н.І.: </em>
        </strong>
        Грамотою Сумської обласної держадміністрації управління освіти і науки
        (2004); Грамотою Сумської обласної держадміністрації управління освіти і
        науки, (2013); Почесною грамотою НАПН України (2016).
      </span>
    </p>
    <p>
      <span>
        Кандидат педагогічних наук, доцент{' '}
        <strong>
          <em>Мішедченко В.В.: </em>
        </strong>
        Грамотою Міністерства освіти і науки України (2008).
      </span>
    </p>
    <p>
      <span>
        Кандидат педагогічних наук, доцент{' '}
        <strong>
          <em>Пішун С.Г.: </em>
        </strong>
        Грамотою Управління освіти і науки Сумської облдержадміністрації (2005);
        Грамотою Департаменту освіти Сумської облдержадміністрації (2011).
      </span>
    </p>
    <p>
      <span>
        Кандидат педагогічних наук, доцент{' '}
        <strong>
          <em>Решетняк В.Ф.:</em>
        </strong>{' '}
        нагрудним знаком «Відмінник освіти України» (2001); Почесною грамотою
        голови Сумської облдержадміністрації (2004);
      </span>
    </p>
    <ul>
      <li>
        <span>
          Грамотою Глухівської міської ради (2007); Подякою Президента України
          (2009).
        </span>
      </li>
    </ul>
    <p>
      <span>
        Асистент{' '}
        <strong>
          <em>Шейко Г.Д.: </em>
        </strong>
        Грамотою Глухівської міської ради (2010); Подякою Глухівського
        національного педагогічного університету імені Олександра Довженка
        (2019).
      </span>
    </p>
    <p>
      <span>
        Доктор педагогічних наук, професор Бірюк Л.Я. є членом редколегії
        «Вісника» Глухівського національного педагогічного університету імені
        Олександра Довженка : зб. наук. праць (Серія : педагогічні науки) та
        збірника наукових праць «Актуальні проблеми лінгводидактики»
        Глухівського НПУ ім. О. Довженка.
      </span>
    </p>
    <p>
      <span>
        <strong>
          <em> </em>
        </strong>
      </span>
    </p>
    <p>
      <span>
        <strong>
          <em>
            Залучення до наукової діяльності за напрямом школи студентів,
            аспірантів, докторантів
          </em>
        </strong>
      </span>
    </p>
    <p>
      <span>
        Бірюк Л.Я. підготувала одного кандидата педагогічних наук: «Формування
        комунікативної компетентності майбутніх учителів початкових класів
        засобами артпедагогіки» (Альмерот О.В., 2017 р.);
      </span>
    </p>
    <p>
      <span>
        Здійснює керівництво докторськими (Загородня Л.П., Пінчук І.О) та
        кандидатськими дисертаціями (Плугіна А.П., Сєрих Т.М., Голубенко С.М.,
        Тищенко Л.І.) зі спеціальності 013 Початкова освіта.
      </span>
    </p>
    <p>
      <span>
        <em>
          <strong>
            Участь представників школи у діяльності спеціалізованих вчених рад з
            захисту кандидатських, докторських дисертацій
          </strong>
        </em>
      </span>
    </p>
    <p>
      <span>
        Доктор педагогічних наук, професор Бірюк Л.Я. упродовж багатьох років
        була членом спеціалізованих вчених рад із захисту дисертацій за
        спеціальністю 13.00.04 при Полтавському національному педагогічному
        університеті імені В.Г. Короленка та Глухівському національному
        педагогічному університеті імені Олександра Довженка (заступник голови
        спеціалізованої вченої ради), здійснює рецензування, наукову експертизу
        дисертаційних досліджень. Людмила Яківна - голова Науково-експертної
        ради Глухівського НПУ ім. О. Довженка.
      </span>
    </p>
    <p> </p>
  </>
);

export default BiriukL;
