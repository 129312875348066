import MapAnnouncementsShort from "../../../components/map-announcements-short/map-announcements-short.component";

const GuestLectures = () => {
  return (
    <>
      <h2>Гостьові лекції</h2>
      <MapAnnouncementsShort annoType="guest-lecture" />
    </>
  );
};
export default GuestLectures;
