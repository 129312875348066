import React from 'react';
import ImageGallery from 'react-image-gallery';

const EntrantsNews = () => (
  <>
    <h2>Новини вступної кампанії</h2>
    <ImageGallery
      items={[
        {
          original:
            'https://lh3.googleusercontent.com/XhmyxV1RpgiWjXuYfDjgpD2lTZTgOfNDkGOTNgQzG0sc7byP6dvuI4b_vtyByegKEI2XueKCnn-xKsTEZtuwVC0S-6FgO0qy1U5pv-m6GBcEZ6i45qFjtw3GgAOWdRUYHxzdC6aW=w2400'
        }
      ]}
      showPlayButton={false}
    />
    <hr />
    <ImageGallery
      items={[
        {
          original:
            'https://lh3.googleusercontent.com/at5p65wSCFf-fDEjsWTM7Gv33a0RtzzNXphJhBZRBqkhqc6jI2TqKtbQcOTWcYHvCnI_0IBmO2llNaexVApB6Sn-3_jK0-fojfdi0w3HafvQlTXIbkzbUToSCWutthBuxb1Xp6E7=w2400'
        }
      ]}
      showPlayButton={false}
    />
  </>
);

export default EntrantsNews;
