const Dual = () => (
  <>
    <h2>ДУАЛЬНА ОСВІТА</h2>
    <p>
      Перелік закладів освіти для впровадження упродовж 2019-2023 рр. пілотного
      проєкту з підготовки фахівців за дуальною формою здобуття освіти за
      обраними спеціальностями (Наказ МОН України  №991 від 15.09.2021 «Про
      внесення змін до наказу Міністерства освіти і науки України від 15 жовтня
      2019 1296».{" "}
      <a href="https://nmc-vfpo.com/wp-content/uploads/2019/09/nakaz-mon-991-15.09.2021-zminy-do-1296-1_compressed.pdf">
        Ознайомитися <i className={"fa fa-external-link"} />
      </a>
    </p>
    <p>
      Положення про дуальну форму здобуття освіти в Глухівському національному
      педагогічному університеті імені Олександра Довженка.
      <a href="https://drive.google.com/file/d/1oqL5FhIm1Oe5ORzWzdQyhstk68qqP2zF/view">
        Ознайомитися <i className={"fa fa-external-link"} />
      </a>
    </p>
  </>
);

export default Dual;
