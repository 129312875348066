import directorsList from '../../../data/history-directors-list.json';

import {
  DirectorsWrapper,
  HistoryContainer,
  HistoryDirectorContainer
} from './own-history.styles';

const OwnHistory = () => (
  <HistoryContainer>
    <h2>Історія інституту</h2>
    <h3>Галерея портретів деканів/директорів структурного підрозділу</h3>
    <DirectorsWrapper>
      {directorsList.map(({ name, years, imageUrl }, index) => (
        <HistoryDirectorContainer key={index}>
          <img src={imageUrl} alt={name} />
          <h5>{name}</h5>
          <h5>{years}</h5>
        </HistoryDirectorContainer>
      ))}
    </DirectorsWrapper>

    <p>
      Глухівський педагогічний інститут одним із перших в Україні (1956 р.) розпочав
      підготовку вчителів початкових класів з вищою освітою. Тривалий час факультет
      педагогіки і методики початкової освіти був організаційним центром діяльності
      інституту. У 1972 році у складі його було виокремлено факультет підготовки вчителів
      початкових класів. Але можна сказати, що історія факультету починається з далекого
      1874 року – року заснування учительського інституту з трьохрічним терміном навчання,
      який готував учителів для міських училищ. Саме факультет початкової освіти став
      спадкоємцем тих кращих освітянських традицій, які формувались усієї історії
      Глухівського педагогічного інституту.
    </p>
    <p>
      Перед колективом було поставлено завдання своєчасно і кваліфіковано відповідати на
      запити школи, на всі важливі питання її життя і діяльності, узагальнювати і
      впроваджувати в практику передовий педагогічний досвід.
    </p>
    <p>
      Скарбницю традицій факультету складали зустрічі з ветеранами, заслуженими
      працівниками педагогічної ниви, культури, письменниками, артистами, часті екскурсії
      і походи, огляди самодіяльності і творчі звіти академгруп, музичні вечори, вечори
      гумору і сатири, політичні зустрічі, вистави студентського драмтеатру, студії
      бального і народного танцю, оперної студії, спартакіади і спортивні вечори, художні
      виставки майстрів пензля, різноманітна трудова діяльність.
    </p>
    <p>
      Відроджуючи професійні традиції, акумулюючи фаховий досвід, розширюючи коло
      спеціальностей і спеціалізацій, з 1989-1990 н.р. факультет розпочав підготовку
      майбутніх учителів за спеціальністю «Педагогіка і методика початкового навчання та
      українська мова і література». З 1991-1992 н.р. уведено ще дві спеціальності
      «Педагогіка і методика початкового навчання і музика» та «Педагогіка і методика
      початкового навчання і біологія». Трохи пізніше – з 1995-1996 н.р. – ще одна
      спеціальність «Педагогіка і методика початкового навчання та іноземна мова
      (англійська)». Це стало поштовхом для відкриття нових факультетів – філологічного,
      природничого.
    </p>
    <p>
      Визначною подією на факультеті стало започаткування в березні 1999 року першої
      Всеукраїнської студентської олімпіади з початкового навчання, проведення якої стало
      традиційним.
    </p>
    <p>
      З 2003 року почала функціонувати науково-дослідна лабораторія «Актуальні проблеми
      комплексної професійної підготовки вчителя початкових класів», науковим
      консультантом якої був доктор педагогічних наук, професор, дійсний член НАПН України
      М.С.Вашуленко. Практично щороку проводилися Всеукраїнські й регіональні
      науково-практичні конференції і науково-методичні семінари. Викладачі успішно
      поєднували педагогічну працю з науково-методичними пошуками, розробляли і
      впроваджували в навчальний процес новітні педагогічні технології.
    </p>
    <p>
      На факультеті здійснювалася підготовка фахівців для початкової ланки за освітніми
      ступенями бакалавр (з дворічним і чотирирічним термінами навчання) та магістр за
      денною й заочною формами навчання зі спеціалізаціями образотворче мистецтво, музика,
      інформатика, іноземна мова (англійська).
    </p>
    <p>
      За роки свого існування факультетом підготовлено більше 11 тис. фахівців, серед яких
      багато мають вчені ступені і наукові звання, почесні звання і знаки, дипломи,
      медалі. грамоти, державні й відомчі відзнаки і нагороди. Гідно представляють
      психолого-педагогічну науку випускники М.С.Вашуленко (доктор педагогічних наук),
      Н.О.Головань (доктор психологічних наук), Л.Я.Бірюк (доктор педагогічних наук),
      Т.П.Новикова (кандидат педагогічних наук), В.Ф.Котляр (кандидат педагогічних наук),
      М.П.Черниш (кандидат педагогічних наук), О.П.Нехай (кандидат історичних наук),
      К.С.Дрозденко (кандидат психологічних наук), М.Т.Мусіяка (кандидат психологічних
      наук), І.П.Дригус (заслужений працівник освіти і науки України), Н.А.Зінченко
      (кандидат педагогічних наук), Н.Т.Тверезовська (доктор педагогічних наук),
      Г.С.Демидчик (кандидат педагогічних наук), Лучкіна Л.В. (кандидат педагогічних
      наук), Непомняща Г.І. (кандидат педагогічних наук), Пінчук І.О. (кандидат
      педагогічних наук), Вишник О.О. (кандидат педагогічних наук), Шрамко О.О. (кандидат
      педагогічних наук) та інші.
    </p>
    <p>
      Авторитет і фаховий рівень випускників факультету в Україні завжди був високим.
      Такому рівню підготовки випускники факультету завдячують передусім наполегливій
      праці педагогічного колективу, у якому завжди були яскраві творчі особистості. Це і
      викладачі української й російської мов та методик їх викладання А.М.Мукан,
      Й.М.Казнадзей, А.Т.Гамалій, Л.Й.Мигаль, В.Д.Глущенко, Є.О.Черепанова, В.М.Куриленко,
      К.Г.Соп’яненко, В.О.Собко, В.А.Каліш, викладач дитячої літератури Г.С.Ковальчук,
      викладачі математики та методики її викладання М.В.Барановський, М.В.Богданович,
      В.І.Мринська, В.Ю.Раєвський, М.П.Волос, Н.І.Горохова, М.З.Каплан, Г.П.Лишенко,
      викладачі фізичного виховання О.Г.Сегал, Д.С.Павленко, В.П.Шпак, А.М.Бор, викладачі
      праці В.І.Гіптенко, І.Ф.Гавриченко, В.Г.Демидчик, Г.В.Ігнатенко, викладачі музики
      Є.Л.Коляда, В.М.Мироненко, В.Д.Хоменко, В.І.Карпова, Р.М.Любима, П.П.Протас,
      М.В.Євтушенко, Т.М.Тюльпа, Н.І.Гречаник, викладачі образотворчого мистецтва
      О.Є.Ковальов, І.М.Дєдіщев, викладачі педагогіки І.І.Смагін, О.П.Романов,
      В.А.Мосіяшенко, К.С.Ляшко, викладачі психології Л.О.Ляшко, Г.А.Кириленко, викладачі
      природознавства та методики викладання Є.О.Савич, Л.К.Нарочна, Г.В.Ковальчук,
      Н.В.Хлонь, Г.І.П’явка, Н.М.Захарова, викладач основ землезнавства К.Ю.Гриценко,
      викладачі іноземної мови Т.Є.Гриценко, Л.Т.Никанорова, Г.А.Саханова, З.М.Єроха,
      Л.В.Бондаренко, викладачі суспільних дисциплін О.Д.Томчук, М.П.Стожок, І.М.Ляшко,
      В.А.Борисенко, М.Й.Заремський, В.І.Бєлашов, В.В.Заїка, О.М.Люлькова, О.І.Задоянчук,
      Л.В.Слінченко та інші.
    </p>
    <p>
      Фахові методики з предметів початкової освіти на факультеті завжди викладали
      досвідчені викладачі, автори підручників для початкової ланки загальноосвітньої
      школи і методичних посібників для вчителів. За підручниками математики кандидата
      педагогічних наук М.В.Богдановича і нині навчаються молодші школярі. Близько 40
      років витримали численні перевидання Буквар і підручники з української мови для 2 –
      4 класів, створені доктором педагогічних наук М.С.Вашуленком (у співавторстві). За
      підручники з природознавства для учнів 2 – 3 класів урядових нагород удостоєна
      кандидат педагогічних наук Л.К.Нарочна. Навчальними посібниками з методики
      викладання природознавства (автори Л.К.Нарочна, Г.В.Ковальчук, К.Д.Гончарова)
      користуються студенти багатьох ВНЗ України. Помітний слід у житті факультету
      залишила кандидат біологічних наук Г.А.Кириленко – автор методичних посібників,
      дидактичних матеріалів з анатомії та фізіології дитини з основами шкільної гігієни у
      школі та вищих навчальних закладах.
    </p>
    <p>
      Славу факультету творять його випускники – заслужені вчителі і відмінники освіти
      України, учителі, методисти, працівники освіти, праця яких визнана й високо оцінена
      державою. Це, без перебільшення, зіркові постаті: Л.К.Гриценко (м. Ірпінь),
      Л.А.Жирна (м. Черкаси), Л.М.Донченко (м. Мерефа Харківської обл..), С.Й.Чорновал (м.
      Київ), Р.І.Куриленко (м. Глухів), Д.М.Швець (смт Терни), Н.С.Кочубей (м. Глухів),
      В.К.Семенова (м. Буринь), Л.М.Мироновська (м. Глухів), А.І.Бесараб (м. Глухів),
      В.М.Терещенко (м. Глухів) і багато інших.
    </p>
    <p>
      Випускники факультету завжди користувалися попитом на ринку педагогічної праці не
      лише в Сумській та сусідніх областях, а й у більш віддалених куточках України. Їх
      радо брали на роботу, виокремлюючи з когорти випускників інших ВНЗ. Так, Василь
      Олександрович Сухомлинський у 1968 році, будучи директором Павлиської школи,
      звернувся листом до ректора саме Глухівського педагогічного інституту з проханням
      направити на роботу в його школу випускника педагогічного факультету.
    </p>
    <p>
      З 2002 року випускники факультету гідно представляли початкову ланку освіти на
      Всеукраїнському конкурсі «Учитель року» в номінації «вчитель початкових класів»,
      лауреатами якого стали О.В.Журенко (2002 р.), С.І.Увенкова (2004 р.), О.В.Сирота
      (2006 р.) та інші.
    </p>
    <p>
      Свою сторінку в історію факультету вписали студенти, які здобували перемоги у
      Всеукраїнських предметних олімпіадах і конкурсах студентських наукових робіт, були
      учасниками студентських науково-практичних конференцій, перемагали на чемпіонатах
      України та міжнародних спортивних змаганнях, всеукраїнських і обласних творчих
      конкурсах і фестивалях.
    </p>
    <p>
      На факультеті початкової освіти постійно прагнули до удосконалення
      навчально-виховного процесу, доведення його рівня до вимог часу. Розроблялися нові
      навчальні плани й програми, вводилися нові навчальні дисципліни, спрямовані на
      гуманітаризацію навчального процесу, створювалися навчальні посібники, методичні
      рекомендації, упроваджувалися в процес навчання технічні засоби навчання і нові
      інформаційні технології, здійснювалася робота з формування високого професіоналізму
      майбутніх фахівців, їхнього нового мислення, уміння виробляти індивідуальний стиль
      педагогічної діяльності, готовності творчо працювати, самовдосконалюватися.
    </p>
    <p>
      З 1 січня 2021 року на базі факультету початкової освіти створено Навчально-науковий
      інститут педагогіки і психології (Наказ №420 від 31.12.2020).
    </p>
    <p>
      Нині Навчально-науковий інститут педагогіки і психології є одним із основних
      самостійних структурних підрозділів Глухівського національного педагогічного
      університету імені Олександра Довженка, що здійснює підготовку бакалаврів,
      магістрів, докторів філософії за спеціальностями 013 Початкова освіта, 053
      Психологія, 231 Соціальна робота, 073 Менеджмент, 014 Середня освіта (Музичне
      мистецтво).
    </p>
    <p>
      В усі роки цей структурний підрозділ очолювали прекрасні керівники, кваліфіковані
      викладачі, педагоги і науковці, кожен із яких залишив помітний слід в історичній
      пам’яті. Сьогодні директором інституту є молодий, креативний керівник, кандидат
      педагогічних наук, доцент Шерудило Андрій Васильович.
    </p>
  </HistoryContainer>
);

export default OwnHistory;
