import ExternalLink from '../../../components/UI/external-link/external-link.component';
import oppToDiscuss from './oop-to-disscuss.json';
import { Link } from 'react-router-dom';

const PublicDiscussion = () => {
  return (
    <>
      <h2>ГРОМАДСЬКЕ ОБГОВОРЕННЯ ОСВІТНЬО-ПРОФЕСІЙНИХ ПРОГРАМ</h2>
      <p>
        Пропозиції та зауваження до змісту освітньо-професійних програм просимо надсилати
        на електронну пошту{' '}
        <a href="mailto:sherudilo@gnpu.edu.ua">
          sherudilo@gnpu.edu.ua <i className="fa fa-envelope" />
        </a>{' '}
        до 24 червня 2023 року
      </p>
      <ul>
        <li>Програми до обговорення:</li>
        {oppToDiscuss.map(({ id, title, linkTo }) => (
          <>
            <li key={id}>
              {title} <ExternalLink href={linkTo} />
            </li>
          </>
        ))}
      </ul>
      <h4>Обговорення:</h4>
      <p>
        <Link to="stakeholders-2023-06-08">
          Засідання ради стейкхолдерів (8 червня 2023 року)
        </Link>
      </p>
    </>
  );
};

export default PublicDiscussion;
