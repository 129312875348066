export type NewsItem = {
  id: string;
  type: string;
  title: string;
  shortCut: string;
  onlyImage: string;
  imagesList: { original: string; thumbnail?: string }[];
  text: string[];
  date: string;
  linkTo: string;
};

type News = { title: 'News'; items: NewsItem[] };
type Events = {
  title: 'Events';
  items: ({ expirationDate: string } & NewsItem)[];
};

type Announcements = [News, Events];
const ANNOUNCEMENTS: Announcements = [
  {
    title: 'News',
    items: [
      {
        id: '29',
        type: 'news-events',
        title: '',
        shortCut: '',
        onlyImage: '',
        imagesList: [
          {
            original: 'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20231122-2%2F401647831_792180566046180_6080530435506719788_n.webp?alt=media&token=39038845-2ee1-40b3-989c-fb472059d318'
          },
          {
            original: 'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20231122-2%2F401699006_792181202712783_406132277661518945_n.webp?alt=media&token=429003a8-dae7-48df-bcd1-5c5a661fa5f9'
          },
          {
            original: 'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20231122-2%2F401726651_792180669379503_3261980252803014159_n.webp?alt=media&token=7b5c0a6b-d217-496c-b02c-c8ddd8323660'
          }
        ],
        text: [
          '16 листопада 2023 року відбувся Всеукраїнський науково-методичний семінар «Компетентнісний підхід в освіті: теорія і практика» у змішаному форматі, який продовжив і розширив марафон традиційних регіональних семінарів, проведених Навчально-науковим інститутом педагогіки і психології Глухівського національного педагогічного університету імені Олександра Довженка.',
          'Учасниками заходу стали понад 300 осіб – учених, науково-педагогічних працівників, керівників підрозділів освітніх закладів, учителів-практиків, аспірантів і магістрантів, що підтвердило актуальність тематики наукового зібрання. Проведення заходу відбулося за підтримки Міністерства освіти і науки України, Інституту педагогіки НАПН України з активною участю представників різних закладів освіти та установ усієї країни. ',
          'Завдяки онлайн-зв’язку до активного обговорення й усвідомлення нагальних проблем змогли долучитися учасники з різних куточків України – Львів, Черкаси, Кременчук, Полтава, Прилуки, Путивль, Рівне, Суми, Умань, Маріуполь, Мукачево, Лебедин тощо.',
          'Присутні заслухали доповіді корифеїв наукової педагогічної спільноти – докторів наук, професорів, науковців-розробників підручників, директорів центрів професійного розвитку педагогічних працівників, а вчителі Нової української школи поділилися цінним практичним досвідом щодо шляхів формування ключових і предметних компетентностей, загальнолюдських і національних цінностей в учнів прикордонної Сумщини, використання засобів візуалізації освітнього контенту, запровадження х’ютагогічного та тьюторського підходів. ',
          'Важливість тематики семінару підкреслюється суспільними процесами, що відбуваються сьогодні в освіті нашої держави. Дискусійне обговорення дало змогу дійти висновку, що саме рівень підготовки молоді є стрижнем майбутньої національної педагогічної еліти, яка вже зараз формується в стінах навчальних закладів і наукових установ України. ',
          'Учасники семінару одержали багато корисної інформації, яскраві емоції та натхнення для подальших наукових досягнень, нові знайомства та ідеї. За матеріалами проведеного семінару укладено програму та доопрацьовується електронний збірник, ознайомитися з ними можна на сайті Глухівського національного педагогічного університету імені Олександра Довженка.',
          'Ми щиро вдячні за підтримку та активну участь у семінарі учасникам і запрошуємо до подальшої наукової співпраці.'
        ],
        date: '2023-11-22',
        linkTo: 'announcement-20231122-29'
      },
      {
        id: '28',
        type: 'news-events',
        title: '',
        shortCut: '',
        onlyImage: '',
        imagesList: [
          {
            original: 'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20231122-1%2F401578704_789820479615522_307535327160094587_n.webp?alt=media&token=eb740380-5609-4f60-9e06-a50f63534abf'
          },
          {
            original: 'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20231122-1%2F401686315_789820826282154_4648473052407090558_n.webp?alt=media&token=e1fc3dad-0c3d-4e4e-a0d4-e42feeea8fa9'
          },
          {
            original: 'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20231122-1%2F401555860_789820709615499_5658565512375913812_n.webp?alt=media&token=99293bb5-c6d2-4054-b4be-ad49c8db5752'
          }
        ],
        text: [
          'ННІ Педагогіки та психології Глухівського НПУ ім.О.Довженка долучився до співорганізації Третіх всеукраїнських педагогічних читань пам’яті О.Я. Савченко "Контексти творчості Олександри Яківни Савченко. ЦІЛІ І ЦІННОСТІ ПОЧАТКОВОЇ ОСВІТИ: РЕАЛІЗАЦІЯ В УМОВАХ СЬОГОДЕННЯ", що відбулися 9 листопада 2023 року на базі лабораторії початкової освіти імені Олександри Яківни Савченко Інституту педагогіки НАПН України.',
          'У межах заходу провідні науковці нашої країни обговорювали особливості впровадження в освітню практику наукової спадщини фундатора початкової освіти, доктора педагогічних наук, професора, дійсного члена НАПН України Олександри Яківни Савченко.',
          'Викладачі кафедр теорії та методики початкової освіти, педагогіки і психології початкової освіти Глухівського НПУ ім.О.Довженка дистанційно взяли участь в панельній дискусії означеного зібрання. ',
          'Директор інституту Андрій Шерудило та завідувачі кафедр Людмила Бірюк, Тетяна Зенченко представляли університет в Національній академії педагогічних наук України. У доповіді, виголошеній під час заходу, було презентовано і досвід учителів міст Глухова, Конотопа, Шостки, Кролевця, які формують загальнолюдські й національні цінності в учнів прикордонної Сумщини відповідно до Типової програми, створеної під керівництвом Олександри Яківни Савченко.',
          'Колектив ННІ Педагогіки та психології Глухівського НПУ ім.О.Довженка щиро вдячний науковцям відділу початкової освіти Інституту педагогіки НАПН України за можливість долучитися до педагогічних читань з питань визначення цілей і цінностей початкової освіти у контексті наукових поглядів Олександри Савченко.'
        ],
        date: '2023-11-22',
        linkTo: 'announcement-20231122-28'
      },
      {
        id: '27',
        type: 'news-events',
        title: 'ОГОЛОШЕННЯ',
        shortCut: '',
        onlyImage: '',
        imagesList: [
          {
            original: ''
          }
        ],
        text: [
          '8 червня 2023 року о 15.00 відбудеться засідання ради стейкхолдерів ННІ Педагогіки і психології.<br/>',
          '<h4>ПОРЯДОК ДЕННИЙ</h4>',
          '1. Презентація та обговорення проєктів освітніх та освітньо-наукових програм 2023 року.',
          '<i><b>Доповідачі:</b> гаранти ОП</i>',
          '<i><b>Співдоповідачі:</b> завідувачі кафедр.</i>',
          '<i><b>Обговорення:</b> члени ради стейкхолдерів.</i>',
          '<i><b>Доповідачі:</b> гаранти ОП</i><br/>',
          'Під\'єднатися до конференції Zoom https://us02web.zoom.us/j/82078660144?pwd=alpoc2hyYUttd3A4a3B5WEIxVmxFUT09',
          'Ідентифікатор конференції: 820 7866 0144',
          'Код доступу: 5e0kVU'
        ],
        date: '2023-06-02',
        linkTo: 'announcement-20230602-27'
      },
      {
        id: '26',
        type: 'news-events',
        title: 'До уваги студентів, які є військовозобов’язаними або призовниками',
        shortCut: '',
        onlyImage: '',
        imagesList: [
          {
            original: ''
          }
        ],
        text: [
          '1. Військовозобов’язані студенти та призовники зобов’язані ознайомитися і дотримуватися Правил військового обліку призовників і військовозобов’язаних (постанова Кабінету Міністрів України від 30 грудня 2022 р. № 1487) https://zakon.rada.gov.ua/laws/show/1487-2022-%D0%BF',
          '2. Зарахування на військовий облік за місцем навчання в Глухівському національному педагогічному університеті імені Олександра Довженка здійснюється у відділі кадрів на підставі поданих під час вступу (поновлення на навчання) військово-облікових документів (посвідчення про приписку до призовної дільниці, військовий квиток або тимчасове посвідчення замість військового квитка). Військові документи мають бути актуальні!',
          '3. Згідно з Порядком організації та ведення військового обліку призовників, військовозобов’язаних та резервістів університет збирає такі дані про призовників та військовозобов’язаних студентів. Університет надсилає у семиденний строк з дня видання наказу про зарахування, відрахування із закладу освіти до відповідних районних (міських) територіальних центрів комплектування та соціальної підтримки повідомлення про зміну облікових даних призовників, військовозобов’язаних та резервістів.',
          '4. Відповідно до статті 23 Закону України “Про мобілізаційну підготовку та мобілізацію” студенти, які навчаються за денною / дуальною формами навчання НЕ підлягають призову на військову службу під час мобілізації.',
          'Проте, у разі зміни місця проживання у період воєнного стану (незалежно від місця реєстрації) у осіб чоловічої статі віком 18-60 років – може виникнути необхідність стати на облік до місцевого ТЦК та СП за місцем нового перебування та надати їм довідку Ф-20 про статус студента.',
          'У разі потреби такої довідки заповнюйте ФОРМУ https://forms.gle/1Ljbn1WBaqJ7qLnr7',
          'Для отримання довідки Вам потрібно заповнити всі обов’язкові поля форми та звернутися до відділу кадрів Глухівського національного педагогічного університету імені Олександра Довженка.',
          'У разі неправильного заповнення форми довідка надана не буде.'
        ],
        date: '2023-05-02',
        linkTo: 'announcement-20230502-26'
      },
      {
        id: '25',
        type: 'news',
        title:
          'Всеукраїнська науково-практична конференція «Початкова освіта в парадигмі Нової української школи: виклики часу»',
        shortCut:
          '27 квітня 2023 року на базі Навчально-наукового інституту педагогіки і психології Глухівського національного педагогічного університету імені Олександра Довженка відбулася Всеукраїнська науково-практична конференція «Початкова освіта в парадигмі Нової української школи: виклики часу».',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230502%2F1.webp?alt=media&token=00b6d5fe-1946-4b95-840e-0833265e07ab'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230502%2F2.webp?alt=media&token=bae3c60d-fd10-4ddd-a0af-ab1193328555'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230502%2F3.webp?alt=media&token=7bd4c61b-fdeb-4fef-b1cb-a0cea531a583'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230502%2F4.webp?alt=media&token=7e239738-81aa-4c84-8f36-092521f44c89'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230502%2F5.webp?alt=media&token=e06dd73d-70d2-41a7-963d-61d2d87d6165'
          }
        ],
        text: [
          'Учасниками заходу стали понад 370 осіб – науково-педагогічних працівників, керівників підрозділів освітніх закладів, учителів-практиків, аспірантів і магістрантів, що підтвердило актуальність тематики наукового зібрання. Проведення заходу відбулося за підтримки Міністерства освіти і науки України, Інституту педагогіки НАПН України, Департаменту освіти і науки Сумської адміністрації, Сумського обласного інституту післядипломної педагогічної освіти. Співорганізаторами наукового заходу стали кілька педагогічних закладів вищої освіти України. Завдяки онлайн-зв’язку до конференції змогли долучитися учасники з різних куточків України – Львів, Кременчук, Полтава, Прилуки, Путивль, Рівне, Суми, Умань, Маріуполь, Мукачево, Лебедин тощо.',
          'У ході зібрання присутні заслухали доповіді корифеїв наукової педагогічної спільноти – докторів наук і професорів, а вчителі Нової української школи продемонстрували практичні майстер-класи щодо шляхів реалізації стратегій, методів і прийомів навчання, виховання, розвитку й оцінювання молодших школярів в освітньому процесі початкової школи.',
          'У ході обговорення результатів конференції учасники виявили небайдужість до суспільних процесів, що відбуваються сьогодні в освіті нашої держави, а також дійшли підсумку, що саме рівень підготовки молоді є стрижнем майбутньої національної педагогічної еліти, яка вже зараз формується в стінах навчальних закладів України. Наприкінці заходу відбулося підведення підсумків і закриття конференції. На згадку в учасників лишилося багато цікавої та корисної інформації, нові знайомства та ідеї, яскраві емоції та натхнення для подальших наукових досягнень. На адресу організаторів і учасників заходу надійшло багато схвальних відгуків і подяк від гостей за плідну співпрацю. За матеріалами проведеної конференції укладено програму та електронний збірник, ознайомитися з ними можна на сайті Глухівського національного педагогічного університету імені Олександра Довженка.'
        ],
        date: '2023-05-02',
        linkTo: 'announcement-20230502-25'
      },
      {
        id: '24',
        type: 'news-events',
        title: '',
        shortCut: '',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230412%2Fannouncement-garant-school.webp?alt=media&token=5906f32e-9182-4cb8-8f87-0b682578b454'
          }
        ],
        text: [''],
        date: '2023-04-12',
        linkTo: 'announcement-20230412-24'
      },
      {
        id: '23',
        type: 'news-events',
        title: 'Важкі питання взаємодії спілкування під час війни',
        shortCut:
          '6 квітня 2023 року о 13.00 запрошуємо здобувачів вищої освіти доєднатися до діалогу з практичним психологом університету Юрієм Рябко.',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230405%2Fphoto_2023-04-05_13-29-19.jpg?alt=media&token=36733e1f-b45f-495a-b9e0-f9f442adc8bd'
          }
        ],
        text: [
          'Тема: "Важкі питання взаємодії спілкування під час війни".',
          'Питання на обговорення:',
          '1. Як зберегти діалог і взаємопорозуміння.',
          '2. Чутливі теми: страхи, безпорадність, втрата.',
          '3. Сплкування в інтернеті і мова ненависті.',
          '4. Агресія в спілкуванні.',
          'Форма участі: очна – укриття корпусу № 4; дистанційна - підключення до конференції Zoom https://zoom.us/j/5313686297?pwd=bFZHUG05VTM3N3hMR3RFeGFpQlI1QT09',
          'Ідентифікатор конференції: 531 368 6297',
          'Код доступа: 2022'
        ],
        date: '2023-04-05',
        linkTo: 'announcement-20230405-23'
      },
      {
        id: '22',
        type: 'news-events',
        title: '',
        shortCut: '',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230328%2Fanno20230328.webp?alt=media&token=f44f52df-9685-4499-acd4-a86888cbaf91'
          }
        ],
        text: [''],
        date: '2023-03-28',
        linkTo: 'announcement-20230328-22'
      },
      {
        id: '21',
        type: 'news-events',
        title: 'Семінар «Шляхи і методи уникнення плагіату в наукових роботах»',
        shortCut:
          'Альоною Петрівною Плугіною, докторкою філософії, старшою викладачкою кафедри педагогіки і психології початкової освіти Глухівського національного педагогічного університету імені Олександра Довженка 24 березня 2023 року  було проведено семінар «Шляхи і методи уникнення плагіату в наукових роботах».',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230324%2FScreenshot_6.webp?alt=media&token=22d1c408-3b68-4848-87c2-22fb6b172cff',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230324%2FScreenshot_6.webp?alt=media&token=22d1c408-3b68-4848-87c2-22fb6b172cff'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230324%2FScreenshot_9.webp?alt=media&token=44a03398-ae7c-4a4d-9553-0b7555f43e8b',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230324%2FScreenshot_9.webp?alt=media&token=44a03398-ae7c-4a4d-9553-0b7555f43e8b'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230324%2FScreenshot_12.webp?alt=media&token=a27edaab-ecea-43c2-afc9-f26446e3a241',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230324%2FScreenshot_12.webp?alt=media&token=a27edaab-ecea-43c2-afc9-f26446e3a241'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230324%2FScreenshot_13.webp?alt=media&token=65838094-fa6b-49df-9fc5-b011080a143e',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230324%2FScreenshot_13.webp?alt=media&token=65838094-fa6b-49df-9fc5-b011080a143e'
          }
        ],
        text: [
          'Альона Петрівна ознайомила із трактуванням понять «академічна доброчесність», «плагіат», академічний плагіат» та основними видами порушень академічної доброчесності.',
          'Викладачка ознайомила присутніх з практичними порадами щодо запобігання плагіату та національними правилами оформлення бібліографічних посилань.',
          'Слухачами та активними учасниками зустрічі виступили аспіранти третього (освітньо-наукового) ступеня вищої освіти «Доктор філософії» спеціальності 013 Початкова освіта. Лекція пройшла цікаво, насичено, інформативно, з активним діалогом між викладачкою та здобувачами освіти'
        ],
        date: '2023-03-24',
        linkTo: 'announcement-20230324-21'
      },
      {
        id: '20',
        type: 'news-events',
        title: '',
        shortCut: '',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230318%2FIMG-01844c06ff64822f88d97096b743babc-V.jpg?alt=media&token=a6e784a3-1324-4ca5-bf2f-c9dfbb76f959'
          }
        ],
        text: [
          'Підключення до конференції Zoom: https://us04web.zoom.us/j/5707807244?pwd=UWVtYmZDV0VqN0hYYWJBMU5rQS9Idz09',
          'Ідентифікатор конференції: 570 780 7244',
          'Код доступу: HrvP2L'
        ],
        date: '2023-03-18',
        linkTo: 'announcement-20230318-20'
      },
      {
        id: '19',
        type: 'news',
        title:
          'Гостьова лекція для здобувачів освіти Мукачівського державного університету від професорки кафедри педагогіки і психології початкової освіти ГНПУ ім. О. Довженка Людмили Бірюк',
        shortCut:
          '9 березня 2023 року Людмила Бірюк – професорка, завідувачка  кафедри педагогіки і психології початкової освіти ГНПУ ім. О. Довженка, –  прочитала онлайн-лекцію для здобувачів третього (освітньо-наукового) рівня вищої освіти спеціальностей 011 Освітні, педагогічні науки і 015 Професійна освіта (за спеціалізаціями), здобувачів другого (магістерського) рівня вищої освіти спеціальностей 011 Освітні, педагогічні науки, 012 Дошкільна освіта і 013 Початкова освіта, а також науково-педагогічних працівників Мукачівського державного університету.',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230311%2FIMG-a117369436176e88b83c35d7393fca22-V.jpg?alt=media&token=cef2d3cb-563c-4add-a728-97f50fdab076',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230311%2FIMG-a117369436176e88b83c35d7393fca22-V.jpg?alt=media&token=cef2d3cb-563c-4add-a728-97f50fdab076'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230311%2FIMG-1a8a0c147e64bd9d155981dfcfc762f5-V.jpg?alt=media&token=f69a3ec8-7599-473e-a4f9-b18f3d6d1b26',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230311%2FIMG-1a8a0c147e64bd9d155981dfcfc762f5-V.jpg?alt=media&token=f69a3ec8-7599-473e-a4f9-b18f3d6d1b26'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230311%2FIMG-b805eac764f2952cff580e86c367d960-V.jpg?alt=media&token=509881e6-e79f-4a5f-bae2-2e6af2c875af',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230311%2FIMG-b805eac764f2952cff580e86c367d960-V.jpg?alt=media&token=509881e6-e79f-4a5f-bae2-2e6af2c875af'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230311%2FIMG-f10e6a0038c194b702240b484f0e09ec-V.jpg?alt=media&token=3d014ff6-92da-43b2-972e-052223da7d83',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230311%2FIMG-f10e6a0038c194b702240b484f0e09ec-V.jpg?alt=media&token=3d014ff6-92da-43b2-972e-052223da7d83'
          }
        ],
        text: [
          'Людмила Яківна запропонувала для обговорення актуальну тему «Компетентністий підхід як методологічне підґрунтя формування професійної компетентності майбутніх фахівців». Лектор акцентувала увагу слухачів на тому, що не існує єдиного узгодженого визначення та переліку ключових компетентностей. Формування компетентностей відбувається засобами змісту освіти, і для кожної спеціальності визначений їх певний перелік в Стандарті. ',
          'Слухачів  зацікавили, окреслені професоркою ключові проблеми означеної вище теми. У процесі жвавої дискусії науковці та молоді вчені засвідчили високу зацікавленість темою лекції та виявили бажання та готовність співпрацювати на партнерських засадах. '
        ],
        date: '2023-03-11',
        linkTo: 'announcement-20230311-19'
      },
      {
        id: '18',
        type: 'postgraduate-guest-lecture-news',
        title: 'Проведено гостьову лекцію Ольгою Андріївною Федій',
        shortCut:
          'Ольгою Андріївною Федій, докторкою педагогічних наук, професоркою, завідувачкою кафедри початкової освіти Полтавського національного педагогічного університету імені В. Г. Короленка 7 березня 2023 року  було проведено гостьову лекцію у дистанційному форматі на платформі Zoom. Тема лекції «Навчально-наукова лабораторія інноваційно-освітніх рішень у початковій освіті: педагогічні тренди та креативи».',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_7.webp?alt=media&token=2bd5ecee-0f35-49d5-8f79-dd9317520277',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_7.webp?alt=media&token=2bd5ecee-0f35-49d5-8f79-dd9317520277'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_15.webp?alt=media&token=ee253725-8ddf-46fa-8a4a-9fe4c945cf5a',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_15.webp?alt=media&token=ee253725-8ddf-46fa-8a4a-9fe4c945cf5a'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_16.webp?alt=media&token=82a7954a-ca42-489b-87c0-46f9b6335d35',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_16.webp?alt=media&token=82a7954a-ca42-489b-87c0-46f9b6335d35'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_20.webp?alt=media&token=c7bcd5aa-05e8-4371-90b7-cb998b37a7c1',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_20.webp?alt=media&token=c7bcd5aa-05e8-4371-90b7-cb998b37a7c1'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_24.webp?alt=media&token=4718c5ba-8e28-4a27-8485-8b1e543232ae',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_24.webp?alt=media&token=4718c5ba-8e28-4a27-8485-8b1e543232ae'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_26.webp?alt=media&token=92ff7a75-08b1-4652-8866-f729fe110328',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_26.webp?alt=media&token=92ff7a75-08b1-4652-8866-f729fe110328'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_28.webp?alt=media&token=a476840a-879c-443d-aec9-63a515752cc6',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_28.webp?alt=media&token=a476840a-879c-443d-aec9-63a515752cc6'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_31.webp?alt=media&token=98cdb6b4-31ac-42fe-af86-82ef638ad9b4',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F20230309-lecture-fedii%2FScreenshot_31.webp?alt=media&token=98cdb6b4-31ac-42fe-af86-82ef638ad9b4'
          }
        ],
        text: [
          'Ольга Андріївна ознайомила із роботою Навчально-наукової лабораторії інноваційно-освітніх рішень у початковій освіті, охарактеризувала її напрями роботи: методика навчання галузей початкової освіти в умовах НУШ; створення психо-емоційного середовища сучасної початкової школи засобами естетотерапії; електронне навчання; ергономізація освітнього простору; екологія в початковій освіті; музейна педагогіка.',
          'Продемонструвала на слайдах посібники, підручники, колективні монографії, видані у результаті роботи лабораторії, друковані видання збірників за науковим напрямом «Інноваційні педагогічні рішення у початковій освіті», результати проведення всеукраїнської науково-практичної конференції «Інновації в початковій школі: проблеми, перспективи, відповіді на виклик сьогодення». ',
          'Науковиця ознайомила присутніх із естетотерапевтичними технологіями фахової підготовки майбутніх учителів початкових класів: «педагогічною пісочницею», технологіями лялькотерапії, драматерапії, фототерапії, методикою «пісочний лист».',
          'Поінформувала про електронне навчання, зокрема: документ-камерою, семінарами-тренінгами для викладачів, робототехнікою та STEM-освітою, а також роботою Навчально-наукової лабораторії інноваційно-освітніх рішень у початковій освіті в умовах воєнного стану.',
          'Слухачами та активними учасниками зустрічі виступили аспіранти третього (освітньо-наукового) ступеня вищої освіти «Доктор філософії» спеціальності 013 Початкова освіта, які опановують освітній компонент «Реалізація концепції НУШ у початковій школі». Лекція пройшла цікаво, насичено, інформативно, з активним діалогом між лектором та здобувачами освіти.',
          'Планується подальша освітня співпраця з колективом кафедри початкової освіти Полтавського національного педагогічного університету імені В. Г. Короленка на чолі з докторкою педагогічних наук, професоркою Ольгою Андріївною Федій з метою підготовки конкурентоспроможних майбутніх викладачів означеної спеціальності.'
        ],
        date: '2023-03-09',
        linkTo: 'announcement-20230309-18'
      },
      {
        id: '17',
        type: 'postgraduate-guest-lecture-event',
        title: '',
        shortCut: '',
        onlyImage:
          'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2Fpostgraduate-guest-lecture-fedii.webp?alt=media&token=136d3413-0a14-44a5-b0cf-4e74934c6b5b',
        imagesList: [
          {
            original: ''
          }
        ],
        text: [''],
        date: '2023-03-06',
        linkTo: 'announcement-20230306-17'
      },
      {
        id: '16',
        type: 'news-event-announcement',
        title: '',
        shortCut: '',
        onlyImage:
          'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230303%2FFB_IMG_1677789750655.jpg?alt=media&token=1960582f-03dd-475f-a196-c453c856967c',
        imagesList: [
          {
            original: ''
          }
        ],
        text: [''],
        date: '2023-03-03',
        linkTo: 'announcement-20230303-16'
      },
      {
        id: '15',
        type: 'student-life-extracurricular',
        title: '«Крим – це Україна»',
        shortCut:
          '27 лютого у Глухівському національному педагогічному університеті імені Олександра Довженка відбулася виховна година «Крим – це Україна» до Дня початку спротиву російській окупації Криму, до якої доєднались здобувачі освіти ННІ Педагогіки і психології.',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.02.27-film%2F333947934_742009933976992_8796856850077269528_n.jpg?alt=media&token=6f53070b-81d4-4e10-b396-a4d2d61c6c21',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.02.27-film%2F333947934_742009933976992_8796856850077269528_n.jpg?alt=media&token=6f53070b-81d4-4e10-b396-a4d2d61c6c21'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.02.27-film%2F333537082_190185093703357_2507105682630866612_n.jpg?alt=media&token=afa8fd53-c43a-45b4-a89a-8211d1502bb4',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.02.27-film%2F333537082_190185093703357_2507105682630866612_n.jpg?alt=media&token=afa8fd53-c43a-45b4-a89a-8211d1502bb4'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.02.27-film%2F333635227_923993095708815_331020899969615649_n.jpg?alt=media&token=8b3beb1a-3032-45bc-a3ff-c03fe7b99c44',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.02.27-film%2F333635227_923993095708815_331020899969615649_n.jpg?alt=media&token=8b3beb1a-3032-45bc-a3ff-c03fe7b99c44'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.02.27-film%2F334028773_175610485218209_3936629674357404090_n.jpg?alt=media&token=0f2e0dba-0b10-4d55-b601-440553bddf99',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.02.27-film%2F334028773_175610485218209_3936629674357404090_n.jpg?alt=media&token=0f2e0dba-0b10-4d55-b601-440553bddf99'
          }
        ],
        text: [
          'Студентська молодь обговорила страшні події 26 лютого 2014 року. Тоді у 2014 році у Сімферополі під будинком Верховної Ради АР Крим відбувся організований Меджлісом кримськотатарського народу масовий мітинг кримських татар та проукраїнських активістів на підтримку територіальної цілісності України.',
          'На заклик Меджлісу кримськотатарського народу до заходу долучилися тисячі активістів і небайдужих громадян. Того дня у мирний спосіб українцям вдалося не допустити позачергового засідання парламенту та перегляду статусу Автономної Республіки Крим у складі України.',
          'Але наступного дня, о 5-й ранку до урядових будівель АРК увійшли регулярні військові формування рф.',
          'Відтоді для України 26 лютого стало символом опору окупації рф, яка триває досі!',
          'Також у рамках інформаційно просвітницького проєкту «Кіно у сховищі» в одному з укриттів університету студенти переглянули документальний фільм «Крим» та заслухали свідчення очевидців про мужній супротив наших громадян та бажання повернути Крим до складу України.'
        ],
        date: '2023-02-27',
        linkTo: 'announcement-20230227-15'
      },
      {
        id: '14',
        type: 'student-life-extracurricular',
        title: '«Розстріляні мрії на Майдані»',
        shortCut:
          '20 лютого студенти Глухівського національного педагогічного університету імені Олександра Довженка провели виховну годину пам’яті Героїв Небесної Сотні під назвою «Розстріляні мрії на Майдані». Студенти пригадали перебіг подій пекельних та кровопролитних днів Майдану 18-20 лютого 2014 року. Згадали кожного, хто поклав своє життя заради демократичного майбутнього країни та вшанували їх хвилиною мовчання.',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.02.20-100%2F332108545_746366183481802_8110351949252347050_n.jpg?alt=media&token=e3e6c0f1-f9ee-47a8-8e9a-4f4e4ca30a2a',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.02.20-100%2F332108545_746366183481802_8110351949252347050_n.jpg?alt=media&token=e3e6c0f1-f9ee-47a8-8e9a-4f4e4ca30a2a'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.02.20-100%2F332107106_693140539271062_2242055501053348221_n.jpg?alt=media&token=d35834ea-b0db-4b80-bb09-a0c8cde82ec9',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.02.20-100%2F332107106_693140539271062_2242055501053348221_n.jpg?alt=media&token=d35834ea-b0db-4b80-bb09-a0c8cde82ec9'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.02.20-100%2F332153345_871720894127293_7024607536155507704_n.jpg?alt=media&token=29cb4d87-2501-473c-b7f0-8b3f06083c05',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.02.20-100%2F332153345_871720894127293_7024607536155507704_n.jpg?alt=media&token=29cb4d87-2501-473c-b7f0-8b3f06083c05'
          }
        ],
        text: [
          'Ми маємо жити так, щоб сотня апостолів правди і свободи, яка пильно спостерігає за нами з небес, бачила: їхня мрія про гідне життя і процвітання України дістає крила, міцніє й утверджується.',
          'Саме зараз, завдяки Збройним Силам України, це підтверджується.',
          '\uD83D\uDC9B \uD83D\uDC99 Слава ЗСУ! Слава Україні!'
        ],
        date: '2023-02-20',
        linkTo: 'announcement-20230220-14'
      },
      {
        id: '13',
        type: 'news',
        title:
          'У ГЛУХІВСЬКОМУ НПУ ІМ. О. ДОВЖЕНКА ВІДБУЛОСЬ УРОЧИСТЕ ВРУЧЕННЯ ДИПЛОМІВ ВИПУСКНИКАМ',
        shortCut:
          '10 лютого 2023 року у Глухівському НПУ ім. О. Довженка у змішаному форматі відбулася урочиста церемонія вручення дипломів випускникам-магістрам денної та заочної форм навчання ННІ Педагогіки і психології.',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230226%2FFB_IMG_1676754027140.jpg?alt=media&token=39c54760-353d-4bbd-be4b-c007217af0a2',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230226%2FFB_IMG_1676754027140.jpg?alt=media&token=39c54760-353d-4bbd-be4b-c007217af0a2'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230226%2FFB_IMG_1676754034157.jpg?alt=media&token=e27746c1-6f46-4ee5-a94d-61591ce5a185',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230226%2FFB_IMG_1676754034157.jpg?alt=media&token=e27746c1-6f46-4ee5-a94d-61591ce5a185'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230226%2Fann-20230214-master-graduate-4.webp?alt=media&token=c57ef564-a90c-4513-81b2-fdaaa6f21c67',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230226%2Fann-20230214-master-graduate-4.webp?alt=media&token=c57ef564-a90c-4513-81b2-fdaaa6f21c67'
          }
        ],
        text: [
          'Відкрили захід спільним виконанням гімну України. З вітальним словом до випускників звернулися проректор з науково-педагогічної роботи Луценко Г.В. та проректор з наукової роботи та міжнародних зв’язків Ткаченко Н.М., які високо оцінили вклад випускників у розвиток студентського самоврядування університету, побажали їм наснаги, віри у завтрашній день, завжди повертатися у стіни рідної alma mater і, звичайно, – мирного та переможного неба!',
          'Привітав випускників директор ННІ Педагогіки і психології Шерудило А.В., який побажав їм нових перемог, невичерпного натхнення у досягненні поставленої мети, віри і надії та вручив випускникам дипломи.',
          'Випускники також привітали один одного та зі щирими словами подяки звернулися до рідних кафедр та наставників. ',
          'Бажаємо випускникам миру та злагоди, успіхів, професійної самореалізації, високих досягнень та справжнього людського щастя!',
          'Ми щиро вдячні адміністрації університету за надану можливість провести цей захід – свято миру, віри, маленької перемоги, яка стане часточкою перемоги усієї України!',
          'Дякуємо ЗСУ та всім нашим захисникам за можливість навчати і навчатися, продовжувати життя і вірити у світле майбутнє!',
          'Слава Україні!'
        ],
        date: '2023-02-10',
        linkTo: 'announcement-20230210-13'
      },
      {
        id: '12',
        type: 'student-life-extracurricular',
        title: 'Зустріч з представником Національної поліції України',
        shortCut:
          '31 січня 2023 року відбулася зустріч інспектора сектору ювенальної превенції капітана поліції Свинаренко Сергія Олександровича з учасниками освітнього процесу Глухівського національного педагогічного університету імені Олександра Довженка у змішаному форматі. ',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.31-police%2F328250993_1362847201148183_1754118460578989041_n.jpg?alt=media&token=5e0e1b0b-3f16-452d-b9e3-8002e1a7b913',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.31-police%2F328250993_1362847201148183_1754118460578989041_n.jpg?alt=media&token=5e0e1b0b-3f16-452d-b9e3-8002e1a7b913'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.31-police%2F328625913_5500573463380909_478823543311440614_n.jpg?alt=media&token=71511c82-16ce-4064-b507-d5ed183fc1ab',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.31-police%2F328625913_5500573463380909_478823543311440614_n.jpg?alt=media&token=71511c82-16ce-4064-b507-d5ed183fc1ab'
          }
        ],
        text: [
          'Учасники зустрічі прослухали інформацію про особливості кримінальної відповідальності громадян України, види і підстави адміністративної відповідальності. Обговорили питаня профілактики та протидії булінгу, домашньому насильству, захисту прав та безпеки дітей, громадян, попередження злочинності та своєчасне реагування на негативні тенденції у молодіжному середовищі.',
          'Профілактична робота дає можливість формувати уміння діяти в конфліктних ситуаціях, приймати правильні рішення, володіти навичками самозбереження, знати свої права та обов’язки.',
          'Поліцейський дав змістовні відповіді на запитання, які виникли у слухачів та нагадав про основні правила безпечної поведінки, розповів про конкретні неприємні ситуації, у які вони можуть потрапити і як їх уникнути. Наприкінці зустрічі Сергій Олександрович закликав студентів бути законослухняними громадянами.'
        ],
        date: '2023-01-31',
        linkTo: 'announcement-20230131-12'
      },
      {
        id: '11',
        type: 'student-life-extracurricular',
        title:
          '\uD83D\uDC9B \uD83D\uDC99 День пам\'яті Героїв Крут у рамках проєкту "Кіно у Сховищі" \uD83D\uDCFD️',
        shortCut:
          '30 січня в Університеті відбувся виховний захід присвячений символу Української революції – бою під Крутами.',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.30-kruty%2F328028107_717781296619803_1591858323913406344_n.jpg?alt=media&token=75835f45-ea07-4917-a5be-0c48558153f9',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.30-kruty%2F328028107_717781296619803_1591858323913406344_n.jpg?alt=media&token=75835f45-ea07-4917-a5be-0c48558153f9'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.30-kruty%2F328444553_512367567463853_8098805595563733008_n.jpg?alt=media&token=98ee1276-559f-4806-be50-5b6ef28ea493',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.30-kruty%2F328444553_512367567463853_8098805595563733008_n.jpg?alt=media&token=98ee1276-559f-4806-be50-5b6ef28ea493'
          }
        ],
        text: [
          'Здобувачі освіти ННІ Педагогіки і психології із цікавістю та трепетом прослухали про перебіг подій сторічної давності. Ця битва стала легендарною та символізує патріотизм і самопожертву молоді в боротьбі за Українську Незалежність. Подвиг під Крутами — приклад для багатьох наступних поколінь.',
          'Цінуємо подвиг кожного. Вшановуємо пам’ять героїв, загиблих під Крутами.',
          'Пам’ятаємо тих, хто віддав своє життя в боротьбі за незалежну Україну.',
          'Шануємо наших захисників, хто сьогодні боронить Українську державу! \uD83C\uDDFA\uD83C\uDDE6'
        ],
        date: '2023-01-30',
        linkTo: 'announcement-20230130-11'
      },
      {
        id: '10',
        type: 'student-life-extracurricular',
        title:
          '\uD83C\uDDFA\uD83C\uDDE6 \uD83C\uDDEE\uD83C\uDDF1 Міжнародний день пам’яті жертв Голокосту.',
        shortCut:
          '27 січня студенти ННІ Педагогіки і психології долучились до участі у виховній годині та вшанували памʼять жертв Голокосту.',
        onlyImage: '',
        imagesList: [
          {
            original: ''
          }
        ],
        text: [
          'Історію неможливо повернути назад. Але необхідно розповісти про неї всю правду.',
          'Пройшовши через страхіття Голодомору, українці як ніхто інший розуміють біль єврейського народу. Ми зобов’язані берегти пам’ять про Голокост і не допустити повторення подібного пекла у майбутньому.',
          'На знак вшанування світлої пам’яті тих, хто став жертвами Голокосту, увійшовши в безсмертя, молодь схилила голови і вшануйвала їх хвилиною мовчання…'
        ],
        date: '2023-01-27',
        linkTo: 'announcement-20230127-10'
      },
      {
        id: '9',
        type: 'student-life-extracurricular',
        title: 'ЄДИНА, СОБОРНА, НЕЗАЛЕЖНА УКРАЇНА',
        shortCut:
          '23 січня 2023 року здобувачі освіти ННІ Педагогіки і психології взяли участь у інформаційно просвітницькому заході «ДЕНЬ ЄДНОСТІ» з нагоди Дня Соборності України.',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.23-unity%2FDSC_0023.webp?alt=media&token=5a8ace57-bc12-405b-9095-ff9b72abe7a7',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.23-unity%2FDSC_0023.webp?alt=media&token=5a8ace57-bc12-405b-9095-ff9b72abe7a7'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.23-unity%2FDSC_0003.webp?alt=media&token=58954e05-584b-4d34-a9dc-1fa30283e203',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.23-unity%2FDSC_0003.webp?alt=media&token=58954e05-584b-4d34-a9dc-1fa30283e203'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.23-unity%2FDSC_0015.webp?alt=media&token=6740739b-08e3-482a-ab86-d5a9953a7484',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.23-unity%2FDSC_0015.webp?alt=media&token=6740739b-08e3-482a-ab86-d5a9953a7484'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.23-unity%2FDSC_0018.webp?alt=media&token=b21362ef-fdef-4fcb-8043-b870e0be6060',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/student-life%2Fextracurricular%2F23.01.23-unity%2FDSC_0018.webp?alt=media&token=b21362ef-fdef-4fcb-8043-b870e0be6060'
          }
        ],
        text: [
          'Який відбувся у змішаному форматі за підтримки кафедри історії, правознавства та методики навчання нашого університету та співпраці з Університетом Григорія Сковороди в Переяславі. Про 105-річницю проголошення незалежності УНР Четвертим Універсалом Центральної Ради розповіли завідувач кафедри доктор педагогічних наук Андрій ГРИЦЕНКО, асистентки кафедри Марина МІНЧЕНКО та Наталія ПЕТРЕНКО. До заходу також долучилися аспіранти ОНП «Історія та археологія». Зокрема Максим ЛЕВЧЕНКО звернув увагу присутніх на важливості інформування суспільства про подібні події в історії нашої держави в минулому аби не допускати подібні помилки в ході повномасштабної агресії росії в Україну.',
          'Представники молодіжного центру та студентство Університету активно долучилися до обговорення. Керівник відділу виховної роботи Університету Ольга ВИШНИК розповіла про розвиток патріотичного руху серед студентства Довженківського вишу. Адже, на сучасному етапі розвитку України, коли громадяни стали на захист своєї землі, коли існує пряма загроза втрати державної незалежності та потрапляння під вплив держави-агресора, виникає нагальна необхідність переосмислення зробленого і здійснення системних заходів, спрямованих на посилення патріотичного виховання – формування нового українця, який діє на основі національних та європейських цінностей.,',
          'Незалежність і соборність є запорукою процвітання Української державності. Втрата Україною незалежності в результаті більшовицької окупації у довготерміновій перспективі призвела до мільйонних втрат від голодомору, репресій та війни. Сьогодні захисники України так само відстоюють не лише незалежність, а й соборність України, як їхні попередники майже 100 років тому. Важливо проаналізувати і врахувати помилки минулого: брак національної єдності на початку ХХ ст., недосвідченість і «містечковість» інтересів тодішніх українських керманичів призвели до втрати української державності.,',
          'Сьогодні, коли маємо українські території, які очікують звільнення з під окупації, День Соборності – це привід нагадати, що Україна єдина й неподільна.'
        ],
        date: '2023-01-23',
        linkTo: 'announcement-20230123-9'
      },
      {
        id: '8',
        type: 'news',
        title: 'Відбулась Усеукраїнська науково-практична інтернет-конференція',
        shortCut:
          '28 жовтня 2022 року відбулась Усеукраїнська науково-практична інтернет-конференція «Україна і світ. Імена та події в педагогічній і культурно-мистецькій думці (до 270-річчя Дмитра Бортнянського)», у якій взяли участь близько 100 учених, досвідчених викладачів та молодих науковців.',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419755488.jpg?alt=media&token=9bade334-a9b6-4440-ad2e-8facac4a5cf7',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419755488.jpg?alt=media&token=9bade334-a9b6-4440-ad2e-8facac4a5cf7'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419747148.jpg?alt=media&token=928ede22-1cad-4889-9a26-be532416d482',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419747148.jpg?alt=media&token=928ede22-1cad-4889-9a26-be532416d482'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419751035.jpg?alt=media&token=ca64fff0-e0cf-4b4f-bca8-66c3d06706d1',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419751035.jpg?alt=media&token=ca64fff0-e0cf-4b4f-bca8-66c3d06706d1'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419758919.jpg?alt=media&token=d07bf0df-e299-4d13-bf1e-10955b12f5b0',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419758919.jpg?alt=media&token=d07bf0df-e299-4d13-bf1e-10955b12f5b0'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419762514.jpg?alt=media&token=7ea06a68-b61a-40ee-9f7d-3a9357f433e2',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419762514.jpg?alt=media&token=7ea06a68-b61a-40ee-9f7d-3a9357f433e2'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419773266.jpg?alt=media&token=13872675-f323-46c2-81c7-9ea050e2e80d',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419773266.jpg?alt=media&token=13872675-f323-46c2-81c7-9ea050e2e80d'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419776502.jpg?alt=media&token=e9b8a3b3-5e82-4e98-85f2-a658cfcd4291',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419776502.jpg?alt=media&token=e9b8a3b3-5e82-4e98-85f2-a658cfcd4291'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419781165.jpg?alt=media&token=ac302b86-c78c-4c31-9052-4724da27b183',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419781165.jpg?alt=media&token=ac302b86-c78c-4c31-9052-4724da27b183'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419790601.jpg?alt=media&token=0c23620c-26cb-4981-9181-4da2a2074f04',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419790601.jpg?alt=media&token=0c23620c-26cb-4981-9181-4da2a2074f04'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419800149.jpg?alt=media&token=278b4cc3-d9ab-44b3-8c3f-c10029d074ef',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno20260226%2FFB_IMG_1677419800149.jpg?alt=media&token=278b4cc3-d9ab-44b3-8c3f-c10029d074ef'
          }
        ],
        text: [
          'Із вітальними словами до присутніх звернулися:',
          'Луценко Григорій Васильович, доктор педагогічних наук, професор, проректор з науково-педагогічної роботи Глухівського національного педагогічного університету імені Олександра Довженка.',
          'Тимошенко Максим Олегович, доктор філософії, професор, Заслужений діяч мистецтв України, член-кореспондент Національної академії мистецтв України, ректор Національної музичної академії України імені Петра Чайковського.',
          'Скорик Адріана Ярославівна, доктор мистецтвознавств, професор, проректор з наукової роботи Національної музичної академії України імені Петра Чайковського, Заслужений діяч мистецтв України, член національної спілки журналістів України. Модераторами секційного засідання були директор ННІ Педагогіки і психології, кандидат педагогічних наук, доцент Шерудило А.В. та доктор педагогічних наук, доцент Тюльпа Т.М.',
          'Під час пленарного засідання науковцями та практиками розглянуті актуальні питання морально-духовного збагачення українського суспільства в галузі національного й світового музичного мистецтва; вивчення й осмислення результатів сучасних теоретичних досліджень та практичних пошуків у мистецьких, педагогічних, культурологічних аспектах музичної культури.',
          'Під час секційного засідання учасники заходу заслухали твори композиторів Д.Бортнянкого, М.Березовського, А.Вівальді, А.Моцарта та ін. Означені композиції виконували викладачі та учні Глухівської школи мистецтв ім.Максима Березовського та викладачі Національної академії мистецтв України імені Петра Чайковського. Хід секційного засідання транслювався в прямому ефірі на каналі університету в You Tube.'
        ],
        date: '',
        linkTo: 'announcement-20230226-8'
      },
      {
        id: '7',
        type: 'postgraduate-guest-lecture',
        title: 'Гостьова лекція. Предметно-методична компетентність учителя НУШ',
        shortCut: '',
        onlyImage:
          'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F%D0%93%D0%BE%D1%81%D1%82%D1%8C%D0%BE%D0%B0_%D0%BB%D0%B5%D0%BA%D1%86%D1%96%D1%8F_%D0%A5%D0%B8%D0%B6%D0%BD%D1%8F%D0%BA._%D0%9E%D0%B3%D0%BE%D0%BB%D0%BE%D1%88%D0%B5%D0%BD%D0%BD%D1%8F2.webp?alt=media&token=9f735a4b-5993-40cf-b0be-11fc9ed2490d',
        imagesList: [
          {
            original: ''
          }
        ],
        text: [''],
        date: '',
        linkTo: 'announcement-20220209-8'
      },
      {
        id: '6',
        type: 'postgraduate-guest-lecture-report',
        title:
          '26 січня 2022 року було проведено гостьову лекцію Тамарою Іванівною Бондар',
        shortCut:
          '26 січня 2022 року було проведено гостьову лекцію Тамарою Іванівною Бондар, докторкою педагогічних наук, професоркою, завідувачкою кафедрою педагогіки дошкільної, початкової освіти та освітнього менеджменту Мукачівського державного університету у дистанційній формі на платформі Zoom. Тема лекції «Сучасні тенденції розвитку інклюзивної освіти у США й Україні».',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F%D0%902022-01-26_15-02-53.webp?alt=media&token=4755da57-b3e1-4e22-8905-50cdbd9f8431',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F%D0%902022-01-26_15-02-53.webp?alt=media&token=4755da57-b3e1-4e22-8905-50cdbd9f8431'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F%D0%902022-01-26_15-01-10.webp?alt=media&token=4c7b733f-9ddd-47c1-93ce-1ee20deac471',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F%D0%902022-01-26_15-01-10.webp?alt=media&token=4c7b733f-9ddd-47c1-93ce-1ee20deac471'
          }
        ],
        text: [
          'Тамарою Іванівною схарактеризовано поняття «тенденції розвитку й функціонування системи освіти» у вітчизняній науково-педагогічній літературі. Обґрунтовано зовнішні та внутрішні чинники щодо опису динаміки розвитку інклюзивної освіти. Визначено керівні принципи, що створюють підґрунтя для реалізації інклюзивної освітньої політики США, лідера в розвитку правової моделі інклюзивної освіти (заборона відмови в наданні освіти, недискримінаційне виявлення й оцінювання учнів з інвалідністю, принцип безкоштовної, доцільної державної освіти, принцип судового захисту, принцип участі дітей і батьків у спільному ухваленні рішень). Проаналізовано Закон України «Про освіту» щодо характеристики понять «інклюзивне навчання», «інклюзивне освітнє середовище», «особа з особливими освітніми потребами». Наголошено на важливості команди психолого-педагогічного супроводу дитини з особливими освітніми потребами в закладах загальної середньої та дошкільної освіти України.',
          'Слухачами та активними учасниками зустрічі виступили аспіранти третього (освітньо-наукового) ступеня вищої освіти «Доктор філософії», які опановують освітній компонент «Інклюзивне навчання в початковій школі».',
          'Планується подальша освітня співпраця з Тамарою Іванівною із метою підготовки конкурентоспроможних майбутніх викладачів означеної спеціальності.'
        ],
        date: '',
        linkTo: 'announcement-2022-01-26-6'
      },
      {
        id: '5',
        type: 'postgraduate-guest-lecture-event',
        title: 'Гостьова лекція. Бондар Т. І.',
        shortCut: '',
        text: [
          'Тема: Гостьова лекція. Бондар Т.І.',
          'Время: 26 янв. 2022 15:00 PM Киев',
          ' ',
          'Подключиться к конференции Zoom',
          'https://us04web.zoom.us/j/7940750852?pwd=NjdPTXYvR0tCY1h2SUVEVEltZnJvQT09',
          ' ',
          'Идентификатор конференции: 794 075 0852',
          'Код доступа: 3anHnX'
        ],
        onlyImage:
          'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F%D0%93%D0%BE%D1%81%D1%82%D1%8C%D0%BE%D0%B0_%D0%BB%D0%B5%D0%BA%D1%86%D1%96%D1%8F_%D0%91%D0%BE%D0%BD%D0%B4%D0%B0%D1%80._%D0%9E%D0%B3%D0%BE%D0%BB%D0%BE%D1%88%D0%B5%D0%BD%D0%BD%D1%8F2.webp?alt=media&token=f04e48c0-e81e-40ad-8809-cbeb8cef07cb',
        imagesList: [
          {
            original: ''
          }
        ],
        date: '',
        linkTo: 'announcement-2022-01-26'
      },
      {
        id: '4',
        type: 'postgraduate-news',
        title: '12 січня 2022 року відбулося звітування аспірантів',
        shortCut:
          'Здобувачі третього (освітньо-наукового) рівня вищої освіти освітнього ступеня «Доктор філософії» 1-го, 2-го, 3-го, 4-го років навчання (денної форми) прозвітували про стан виконання індивідуальних планів за перше півріччя 2021-2022 н.р. на засіданні кафедри педагогіки та психології початкової освіти.',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F1643026469089.webp?alt=media&token=30d59aba-83a2-4128-ab40-ea6f9e9cc347'
          }
        ],
        text: [
          'Гречаник Світлана Володимирівна',
          'Тема дослідження: «Методика формування емоційно-етичної компетентності молодших школярів засобами музичного мистецтва».',
          'Мовчан Валентина Володимирівна ',
          'Тема дослідження: «Формування емоційного інтелекту молодших школярів у НУШ».',
          'Підсосонна Олена Володимирівна ',
          'Тема дослідження: «Формування наскрізних умінь у молодших школярів нової української школи».',
          'Ремньова Ніна Юріївна  ',
          'Тема дослідження: «Формування комунікативної компетентності учнів початкових класів за технологією розвитку критичного мислення».',
          'Моляка Інна Володимирівна ',
          'Тема дослідження: «Методика формування англомовної фонетичної компетентності у майбутніх учителів початкової школи».',
          'Тищенко Людмила Іванівна ',
          'Тема дослідження: «Професійна підготовка майбутніх учителів початкових класів до роботи в умовах інклюзивного навчання молодших школярів».'
        ],
        date: '',
        linkTo: 'announcement-20220112'
      },
      {
        id: '3',
        type: 'postgraduate-guest-lecture-report',
        title:
          '22 грудня 2021 року було проведено гостьову лекцію Оленою Валеріївною Матвієнко',
        shortCut:
          '22 грудня 2021 року було проведено гостьову лекцію Оленою Валеріївною Матвієнко, докторкою педагогічних наук, професоркою, заступницею декана з наукової роботи та міжнародних зв’язків, завідувачкою кафедри педагогіки та методики початкового навчання Педагогічного факультету Національного педагогічного університету імені М. П. Драгоманова у дистанційній формі на платформі Zoom.',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F2021-12-22_17-05-21.webp?alt=media&token=9fba7cc7-3985-4ef4-9d12-357a2db5df31',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F2021-12-22_17-05-21.webp?alt=media&token=9fba7cc7-3985-4ef4-9d12-357a2db5df31'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F2021-12-22_17-07-21.webp?alt=media&token=699811e6-fea8-46f6-b221-abd3fcf2def1',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F2021-12-22_17-07-21.webp?alt=media&token=699811e6-fea8-46f6-b221-abd3fcf2def1'
          },
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F2021-12-22_17-11-34.webp?alt=media&token=71c1e5e9-eaf3-4088-9e79-794a6e8232ef',
            thumbnail:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F2021-12-22_17-11-34.webp?alt=media&token=71c1e5e9-eaf3-4088-9e79-794a6e8232ef'
          }
        ],
        text: [
          'Тема лекції «Особливості професійної підготовки вчителя початкової школи в умовах розвитку освіти в Європі» зумовлена запитами вищої освіти. Слухачами та активними учасниками зустрічі виступили аспіранти спеціальності 013 Початкова освіта освітньо-наукової програми «Початкова освіта» з дисципліни «Компетентнісно орієнтована початкова освіта».Планується подальша освітня співпраця з Оленою Валеріївною з метою підготовки конкурентоспроможних майбутніх викладачів означеної спеціальності.'
        ],
        date: '',
        linkTo: 'announcement-20211222'
      },
      {
        id: '2',
        type: 'postgraduate-news',
        title: 'Екологічна майстерня "ЖИТТЯ БЕЗ СМІТТЯ"',
        shortCut:
          '17 травня 2021 року аспіранткою Оленою Володимирівною Підсосонною проведено  практичний майстер-клас по виготовленню аксесуарів, сувенірів і предметів побутового вжитку із вторинних матеріальних ресурсів та еко-сировини - Екологічна майстерня "ЖИТТЯ БЕЗ СМІТТЯ"',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F187006061_908777549854474_2580588234303059985_n.jpg?alt=media&token=3eb25ec7-9bcb-4845-995f-30804f11ef84'
          }
        ],
        text: [''],
        date: '',
        linkTo: 'announcement-20210517'
      },
      {
        id: '1',
        type: 'postgraduate-news',
        title:
          'Вища педагогічна освіта в Україні: історичний вимір та виклики сьогодення',
        shortCut:
          '18 березня 2021 року відбулася Всеукраїнська науково-практична інтернет-конференція «Вища педагогічна освіта в Україні: історичний вимір та виклики сьогодення». Захід присвячено 100-річчю з дня заснування кафедри педагогіки Глухівського національного педагогічного університету імені Олександра Довженка!',
        onlyImage: '',
        imagesList: [
          {
            original:
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fevents%2F162051060_1367143436986357_4154391448442282280_n.jpg?alt=media&token=4254682a-d14c-4134-830d-76529dd8e373'
          }
        ],
        text: [''],
        date: '',
        linkTo: 'announcement-20210318'
      }
    ]
  },
  {
    title: 'Events',
    items: [
      {
        id: '1',
        type: 'news-event-announcement',
        title: '',
        shortCut: '',
        onlyImage:
          'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/announcements%2Fanno-20230303%2FFB_IMG_1677789750655.jpg?alt=media&token=1960582f-03dd-475f-a196-c453c856967c',
        imagesList: [
          {
            original: ''
          }
        ],
        text: [''],
        date: '2023-03-03',
        expirationDate: '2023-04-28',
        linkTo: 'announcement-20230303-16'
      }
    ]
  }
];
export default ANNOUNCEMENTS;
