import PDFViewer from '../../../components/pdf-viewer/pdf-viewer.component';

const AttestationSchedule = () => (
  <PDFViewer
    title="Розклад підсумкової атестації"
    pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/education%2Fattestation-schedule%2Ffinal-attestation-schedule-22_23.pdf?alt=media&token=5bd3c3a8-ae5d-43c9-b644-6d44821820d8"
  />
);

export default AttestationSchedule;
