import styled from 'styled-components';

export const BaseButton = styled.button`
  min-width: 165px;
  width: auto;
  letter-spacing: 0.5px;
  line-height: 50px;
  font-size: 15px;
  background-color: var(--color-tertiary);
  color: var(--color-secondary);
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 5px;

  &:hover {
    background-color: var(--color-secondary);
    color: var(--color-primary);
  }
`;

export const GoogleButton = styled(BaseButton)`
  background-color: #4285f4;
  color: var(--color-secondary);

  &:hover {
    background-color: #357ae8;
    border: none;
  }
`;
export const InvertedButton = styled(BaseButton)`
  background-color: var(--color-secondary);
  color: var(--color-primary);
  border: 1px solid var(--color-tertiary);

  &:hover {
    background-color: var(--color-tertiary);
    color: var(--color-secondary);
    border: none;
  }
`;
