import styled from "styled-components";

export const GoBackTop = styled.p`
  margin: 20px auto 30px;
  max-width: 1000px;
  cursor: pointer;
  font-size: 1.3em !important;
  font-weight: bold;
  color: var(--color-primary);

  &:hover {
    color: var(--color-primary-darker);
  }
`;
export const GoBackBottom = styled(GoBackTop)`
  text-align: end !important;
`;
export const PostgraduateProfileContainer = styled.section`
  margin: 0 auto 30px;
  max-width: 1000px;
  background: var(--gradient-secondary);
  padding: 10px 20px 20px;
  border-radius: 1rem;
  box-shadow: -15px 15px 15px -15px black, inset 0 0 14px -7px black;
`;
export const ProfilePhoto = styled.img`
  width: 100%;
  max-width: 400px;
  border-radius: 0.5rem;
`;
export const ProfileMainInfo = styled.div`
  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
    align-items: start;
  }
`;
export const ProfileLeftContainer = styled.div`
  @media screen and (min-width: 992px) {
    
      padding-top: 1.33em;
      width: 30%;
   
  `;
export const Name = styled.h3`
  margin: 0.7em 0;
  text-align: center;
  font-size: 1.3em;
  text-shadow: 0 0 6px white;
`;
export const TopicHeading = styled.h4`
  margin-bottom: 0.6em;
`;
export const ResponsiveParagraph = styled.p`
  text-align: center !important;
  @media screen and (min-width: 992px) {
    text-align: start !important;
  }
`;

export const ProfileRightContainer = styled.div`
  @media screen and (min-width: 992px) {
      padding-left: 2%;
      width: 70%;
  `;
export const SocialContainerWideScreen = styled.div`
  display: none;
  @media screen and (min-width: 992px) {
    display: block;
  }
`;
export const SocialContainerMobileScreen = styled.div`
  @media screen and (min-width: 992px) {
    display: none;
  }
`;
export const HiddenList = styled.ol`
  margin: 0;
  padding-left: 15px;
`;
export const TypeHeading = styled.h5`
  text-align: start;
  font-size: 1.1em;
  margin: 1em 0 0.5em;
`;
export const HiddenListItem = styled.li`
  margin-bottom: 0.25em !important;
  text-align: justify;
  font-size: 1.1rem;
  overflow-wrap: anywhere;
`;
