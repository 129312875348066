import { FC } from 'react';
import { Viewer } from '@react-pdf-viewer/core';

// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import '@react-pdf-viewer/core/lib/styles/index.css';
// Import styles of default layout plugin
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { PDFContainer } from './pdf-viewer.styles';

type PDFViewerProps = {
  pdfUrl: string;
  title?: string;
};
const PDFViewer: FC<PDFViewerProps> = ({ pdfUrl, title }) => {
  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <>
      {title && <h2>{title}</h2>}
      <PDFContainer>
        <Viewer
          fileUrl={pdfUrl}
          // plugins={[defaultLayoutPluginInstance]}
        />
      </PDFContainer>
    </>
  );
};

export default PDFViewer;
