import { lazy, useEffect, useState } from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Worker } from '@react-pdf-viewer/core';

import Layout from './layout/layout.component';
import Page404 from './components/page404/page404.component';
import Home from './pages/home/home.component';
import About from './routes/about.routes';
import Education from './routes/education.routes';
import Student from './routes/student.routes';
import StudentLife from './routes/student-life.routes';
import Entrants from './routes/entrants.routes';
import Science from './routes/science.routes';
import Postgraduate from './routes/postgraduate.routes';
import AnnouncementsRoutes from './routes/announcements.routes';
import Announcement from './components/announcement/announcement.component';
import Management from './pages/managment/managment.component';

import { primaryTheme } from './components/theme/theme';
import { GlobalStyles } from './global.styles';

const Authentication = lazy(
  () => import('./pages/authentication/authentication.component')
);

function App() {
  const [selectedTheme, setSelectedTheme] = useState(primaryTheme);
  useEffect(() => {
    return () => {
      const currentTheme = JSON.parse(localStorage.getItem('current-theme') || 'null');
      if (currentTheme) setSelectedTheme(currentTheme);
    };
  }, []);

  const themeChangeHandler = (theme: any) => {
    setSelectedTheme(theme);
    localStorage.setItem('current-theme', JSON.stringify(theme));
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout themeChangeHandler={themeChangeHandler} />}>
        <Route index element={<Home />} />
        <Route path=":id" element={<Announcement />} />
        <Route path="about/*" element={<About />} />
        <Route path="education/*" element={<Education />} />
        <Route path="science/*" element={<Science />} />
        <Route path="postgraduate/*" element={<Postgraduate />} />
        <Route path="entrants/*" element={<Entrants />} />
        <Route path="student/*" element={<Student />} />
        <Route path="student-life/*" element={<StudentLife />} />
        <Route path="announcements/*" element={<AnnouncementsRoutes />} />
        <Route path="auth" element={<Authentication />} />
        <Route path="management" element={<Management />} />
        <Route path="/*" element={<Page404 />} />
      </Route>
    )
  );
  return (
    <ThemeProvider theme={selectedTheme}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
        <GlobalStyles />
        <RouterProvider router={router} />
      </Worker>
    </ThemeProvider>
  );
}

export default App;
