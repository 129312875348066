import {
  Button,
  Hamburger,
  LineAdditional,
  LineBottom,
  LineTop
} from './nav-button.styles';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { selectIsMenuOpen } from '../../../store/navigation/navigation.selector';
import { toggleMenu } from '../../../store/navigation/navigation.slice';

const NavButton = () => {
  const isMenuOpen = useAppSelector(selectIsMenuOpen);
  const dispatch = useAppDispatch();
  const toggleMenuHandler = () => dispatch(toggleMenu());
  return (
    <Button isOpen={isMenuOpen} onClick={toggleMenuHandler}>
      <Hamburger viewBox="0 0 100 100" width="250">
        <LineAdditional x1="90" x2="10" y1="20" y2="20" />
        <LineTop x1="90" x2="10" y1="40" y2="40" />
        <LineBottom x1="10" x2="90" y1="60" y2="60" />
        <LineAdditional x1="10" x2="90" y1="80" y2="80" />
      </Hamburger>
    </Button>
  );
};
export default NavButton;
