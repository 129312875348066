import { CenteredTextContainer } from './discussion.styles';
import ExternalLink from '../../../components/UI/external-link/external-link.component';
import { Fragment } from 'react';

type OnpOnDiscussion = {
  id: number;
  linkTo: string;
  title: string;
  educationLevel: string;
  degree: string;
  qualification: string;
  field: string;
  contact: string;
  endDate: string;
};
const DISCUSSION_ONPs: OnpOnDiscussion[] = [
  {
    id: 1,
    linkTo:
      'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fdiscussion%2F%D0%9E%D0%9D%D0%9F%20%D0%9F%D0%BE%D1%87%D0%B0%D1%82%D0%BA%D0%BE%D0%B2%D0%B0%20%D0%BE%D1%81%D0%B2%D1%96%D1%82%D0%B0%202023.pdf?alt=media&token=c4de4185-ed32-4f22-88ed-a9619d8e8090',
    title: 'Початкова освіта',
    educationLevel: 'третій (освітньо-науковий)',
    degree: 'доктор філософії',
    qualification: '013 Початкова освіта',
    field: '01 Освіта / Педагогіка',
    contact: 'kafpipo@gmail.com',
    endDate: '24 червня 2023 року'
  },
  {
    id: 2,
    linkTo:
      'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fdiscussion%2F%D0%9E%D0%9D%D0%9F%20%D0%A3%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D1%96%D0%BD%D0%BD%D1%8F%20%D0%B7%D0%B0%D0%BA%D0%BB%D0%B0%D0%B4%D0%BE%D0%BC%20%D0%BE%D1%81%D0%B2%D1%96%D1%82%D0%B8%202023%20-%20%D0%BF%D1%80%D0%BE%D1%94%D0%BA%D1%82.pdf?alt=media&token=64fa9a35-24d0-436d-83b5-3342c32a7df4',
    title: 'Управління закладом освіти',
    educationLevel: 'третій (освітньо-науковий)',
    degree: 'доктор філософії',
    qualification: '073 Менеджмент',
    field: '07 Управління та адміністрування',
    contact: 'kafpipo@gmail.com',
    endDate: '24 червня 2023 року'
  }
];
const Discussion = () => (
  <>
    <h2>Громадське обговорення освітньо-наукової програми</h2>
    <h3>Аспірантура - Громадське обговорення ОП</h3>
    {DISCUSSION_ONPs.map(
      (
        {
          linkTo,
          educationLevel,
          degree,
          title,
          qualification,
          field,
          contact,
          endDate,
          id
        },
        index,
        array
      ) => (
        <Fragment key={id}>
          <CenteredTextContainer>
            <p>
              <b>ОСВІТНЬО-НАУКОВА ПРОГРАМА</b>
            </p>
            <p>
              (
              <ExternalLink href={linkTo} text="проєкт" />)
            </p>
            <p>
              <b>«{title}»</b>
            </p>
            <p>
              <b>рівень вищої освіти:</b> {educationLevel}
            </p>
            <p>
              <b>на здобуття освітньо-наукового ступеня:</b> {degree}
            </p>
            <p>
              <b>за спеціальністю:</b> {qualification}
            </p>
            <p>
              <b>галузі знань:</b> {field}
            </p>
          </CenteredTextContainer>
          <p>
            Пропозиції та зауваження до змісту освітньо-наукової програми "Початкова
            освіта" просимо надсилати на електронну адресу{' '}
            <a href={`mailto:${contact}`}>
              <i className={'fa fa-envelope'} /> {contact}
            </a>
            до {endDate}
          </p>
          {array.length - 1 !== index && <hr />}
        </Fragment>
      )
    )}
  </>
);

export default Discussion;
