import ExternalLink from '../../../components/UI/external-link/external-link.component';

const Accreditation = () => (
  <>
    <h2>Акредитація</h2>
    <ul>
      <li>
        <h4>
          Відомості про самооцінювання ОП.
          <ExternalLink href="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Faccreditation%2F%D0%92%D1%96%D0%B4%D0%BE%D0%BC%D0%BE%D1%81%D1%82%D1%96%20%D0%BF%D1%80%D0%BE%20%D1%81%D0%B0%D0%BC%D0%BE%D0%BE%D1%86%D1%96%D0%BD%D1%8E%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20%D0%9E%D0%9F.pdf?alt=media&token=d1b161c2-7432-4d23-91b5-2b26e68fc975" />
        </h4>
      </li>
    </ul>
  </>
);

export default Accreditation;
