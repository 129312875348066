import { Address, WelcomeLink } from './header-address.styles';
const HeaderContacts = () => {
  return (
    <Address>
      <a href="tel:+380953526896">
        <i className="fa fa-phone" /> +380662110850
      </a>
      <a href="mailto:dfdo@gnpu.edu.ua">
        <i className="fa fa-envelope" /> dnnipp@gnpu.edu.ua
      </a>
      <WelcomeLink to="/">Вітаємо на сайті Інституту</WelcomeLink>
      <a href="http://po.gnpu.edu.ua/" target="_blank" rel="noreferrer">
        Старий сайт
      </a>
    </Address>
  );
};
export default HeaderContacts;
