import { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';

import SocialScience from '../social-science/social-science.component';
import ExternalLink from '../UI/external-link/external-link.component';
import Page404 from '../page404/page404.component';

import postgraduatesList from '../../data/postgraduates.json';

import {
  ResponsiveParagraph,
  GoBackBottom,
  GoBackTop,
  HiddenList,
  HiddenListItem,
  Name,
  PostgraduateProfileContainer,
  ProfileLeftContainer,
  ProfileMainInfo,
  ProfilePhoto,
  ProfileRightContainer,
  TopicHeading,
  TypeHeading,
  SocialContainerWideScreen,
  SocialContainerMobileScreen
} from './postgraduate-profile.styles';

type PostgraduateProfileParams = {
  id: string;
};
const PostgraduateProfile = () => {
  const { id } = useParams<PostgraduateProfileParams>() as PostgraduateProfileParams;
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const postgraduate = postgraduatesList.find(({ linkTo }) => linkTo === id);
  if (!postgraduate) {
    return <Page404 />;
  }
  const {
    name,
    photo,
    specialty,
    onp,
    educationForm,
    dissertationTopic,
    supervisor,
    additionalInfo,
    social,
    scientificWorksList,
    approbationOfResearch,
    internship,
    grantProjects,
    nonFormal
  } = postgraduate;
  return (
    <>
      <GoBackTop onClick={goBack}>
        <i className={'fa fa-arrow-left'} /> Повернутися на попередню сторінку
      </GoBackTop>
      <PostgraduateProfileContainer>
        <ProfileMainInfo>
          <ProfileLeftContainer>
            <ProfilePhoto src={photo} alt={name} />
            <Name>{name}</Name>
            <SocialContainerWideScreen>
              <TopicHeading>Профілі у соціальних мережах:</TopicHeading>
              <SocialScience social={social} />
            </SocialContainerWideScreen>
          </ProfileLeftContainer>
          <ProfileRightContainer>
            <ResponsiveParagraph>
              <b>Спеціальність: </b>
              {specialty}
            </ResponsiveParagraph>
            <ResponsiveParagraph>
              <b>Освітньо-наукова програма: </b>
              {onp}
            </ResponsiveParagraph>
            <ResponsiveParagraph>
              <b>Форма навчання: </b>
              {educationForm}
            </ResponsiveParagraph>
            <TopicHeading>Тема дисертації:</TopicHeading>
            <p>{dissertationTopic}</p>
            <TopicHeading>Науковий керівник: </TopicHeading>
            <p>
              <ExternalLink href={supervisor.url} text={supervisor.name} />
            </p>
            {additionalInfo[0] && (
              <>
                <TopicHeading>Додаткова інформація:</TopicHeading>
                <p>
                  <em>{additionalInfo}</em>
                </p>
              </>
            )}
          </ProfileRightContainer>
          <SocialContainerMobileScreen>
            <TopicHeading>Профілі у соціальних мережах:</TopicHeading>
            <SocialScience social={social} />
          </SocialContainerMobileScreen>
        </ProfileMainInfo>
        <div>
          <TopicHeading>Список опублікованих праць за темою дисертації:</TopicHeading>
          <HiddenList>
            {scientificWorksList.map(({ workType, works }, index) => (
              <Fragment key={index}>
                <TypeHeading>{workType}</TypeHeading>
                {works.map((work, index) => (
                  <HiddenListItem key={index}>{work}</HiddenListItem>
                ))}
              </Fragment>
            ))}
          </HiddenList>
          <HiddenList>
            {approbationOfResearch[0].conferences[0] && (
              <>
                <TopicHeading>Апробація результатів дослідження:</TopicHeading>
                {approbationOfResearch.map(({ approbationType, conferences }, index) => (
                  <Fragment key={index}>
                    <TypeHeading>{approbationType}</TypeHeading>
                    {conferences.map((conference, index) => (
                      <HiddenListItem key={index}>{conference}</HiddenListItem>
                    ))}
                  </Fragment>
                ))}
              </>
            )}
          </HiddenList>
          {internship?.internshipCerfInfo[0] && (
            <HiddenList>
              <TopicHeading>Міжнародне стажування</TopicHeading>
              {internship.internshipCerfInfo.map((cerfInfo, index) => (
                <HiddenListItem key={index}>{cerfInfo}</HiddenListItem>
              ))}
            </HiddenList>
          )}
          {internship?.cerfImageUrl[0].original && (
            <ImageGallery items={internship.cerfImageUrl} showPlayButton={false} />
          )}
          {grantProjects?.grantProjectsCerfInfo[0] && (
            <HiddenList>
              <TopicHeading>Участь у проєктах та грантах</TopicHeading>
              {grantProjects.grantProjectsCerfInfo.map((cerfInfo, index) => (
                <HiddenListItem key={index}>{cerfInfo}</HiddenListItem>
              ))}
            </HiddenList>
          )}
          {grantProjects?.cerfImageUrl[0].original && (
            <ImageGallery items={grantProjects.cerfImageUrl} showPlayButton={false} />
          )}
          {nonFormal?.cerfImageUrl[0].original && (
            <>
              <TopicHeading>Участь у заходах неформальної освіти:</TopicHeading>
              <ImageGallery items={nonFormal.cerfImageUrl} showPlayButton={false} />
            </>
          )}
        </div>
      </PostgraduateProfileContainer>
      <GoBackBottom onClick={goBack}>
        <i className={'fa fa-arrow-left'} /> Повернутися на попередню сторінку
      </GoBackBottom>
    </>
  );
};
export default PostgraduateProfile;
