const VashulenkoM = () => {
  return (
    <>
      <h2>
        Наукова школа доктора педагогічних наук, професора Вашуленка Миколи
        Самійловича{' '}
      </h2>

      <img
        src="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Fschools%2Fvashulenko-m.webp?alt=media&token=ca7e739f-1989-4c3e-bd85-9ababceec05a"
        alt="Рисунок1"
      />
      <h4>
        «ТЕОРЕТИКО-МЕТОДИЧНІ ЗАСАДИ КОМПЛЕКСНОГО ПІДХОДУ ДО ФАХОВОЇ ПІДГОТОВКИ
        ВЧИТЕЛЯ ПОЧАТКОВИХ КЛАСІВ»
      </h4>

      <p>
        <span>
          Інформація щодо діяльності наукової школи доктора педагогічних наук,
          професора, дійсного члена НАПН України Вашуленка М. С.
        </span>
      </p>
      <p>
        <span>
          <em>
            <strong>Рік заснування наукової школи</strong>
          </em>
          <em>:</em> 1989.
        </span>
      </p>
      <p>
        <span>
          Наукову школу затверджено рішенням вченої ради від 29 грудня 2016
          року, протокол № 7.
        </span>
      </p>
      <p>
        <span>
          <em>
            <strong>Дані про керівника наукової школи</strong>
          </em>
          <em>:</em>
        </span>
      </p>
      <p>
        <span>
          Вашуленко Микола Самійлович народився 1 червня 1941 року в селі
          Жовтневе Бородянського району Київської області. У 1961 році закінчив
          факультет підготовки вчителів початкових класів Глухівського
          державного педагогічного інституту (нині Глухівський національний
          педагогічний університет імені Олександра Довженка), а у 1972 році –
          філологічний факультет Київського державного педагогічного інституту
          (нині Національний педагогічний університет імені М. П. Драгоманова).
        </span>
      </p>
      <p>
        <span>
          У 1961 – 1972 рр. працював учителем початкових класів у Хмельницькій
          та Київській областях, а з 1972 по 1995 рр. – у Науково-дослідному
          інституті педагогіки України, де пройшов шлях від молодшого наукового
          співробітника до заступника директора з наукової роботи й дійсного
          члена Національної академії педагогічних наук України (1995). З грудня
          1995 року – головний учений секретар президії АПН України, а з 2003
          року – головний науковий співробітник лабораторії початкової освіти
          Інституту педагогіки АПН України.
        </span>
      </p>
      <p>
        <span>
          У 1976 році захистив кандидатську дисертацію «Робота над орфоепією як
          одна з умов формування у молодших школярів навичок орфографічного
          письма», а в 1992 році – докторську «Теорія і практика навчання
          української мови в чотирирічній початковій школі» зі спеціальності
          13.00.02 – Теорія та методика навчання (українська мова). Вчене звання
          професора присвоєно у 1993 році.
        </span>
      </p>
      <p>
        <span>
          У 1999 – 2008 рр. за сумісництвом працює в Глухівському національному
          педагогічному університеті імені Олександра Довженка на посадах
          професора кафедри української мови та методики її викладання й
          педагогіки і методики початкового навчання. З 2008 по 2014 рр. обіймає
          посаду завідувача кафедри теорії і методики початкового навчання.
          Наразі – професор цієї кафедри.
        </span>
      </p>
      <p>
        <span>
          Головні напрями наукових досліджень: проблеми теорії і методики
          навчання української мови в початковій школі; методичні шляхи
          модернізації початкового курсу шкільної мовної освіти відповідно до
          завдань, поставлених перед школою сучасним суспільством; виховання
          культури мовлення і спілкування молодого покоління.
        </span>
      </p>
      <p>
        <span>
          <em>
            <strong>
              Зміст та результати наукових досліджень у визначеному напрямі
            </strong>
          </em>
          <em>: </em>
        </span>
      </p>
      <p>
        <span>
          <em>
            - проведення теоретичних та експериментальних досліджень актуальних
            проблем навчання, виховання і розвитку молодших школярів та
            всебічної професійної підготовки майбутніх учителів початкових
            класів;
          </em>
        </span>
      </p>
      <p>
        <span>
          <em>
            - використання результатів досліджень у процесі підготовки
            науково-педагогічних кадрів для початкової ланки загальноосвітньої
            школи;
          </em>
        </span>
      </p>
      <p>
        <span>
          <em>
            - проведення науково-методичних конференцій, семінарів, нарад у
            співробітництві з установами Академії педагогічних наук України,
            закладами вищої освіти, закладами післядипломної освіти та
            загальноосвітніми школами.
          </em>
        </span>
      </p>
      <p>
        <span>
          <em>
            <strong>
              Наукові видання (монографії, наукові збірники, наукові статті, в
              тому числі у Scopus, Web of science):
            </strong>
          </em>
        </span>
      </p>
      <p>
        <span>
          Вашуленко М. С. автор понад 250 наукових праць. Науковий керівник
          авторських колективів і співавтор букварів для шкіл з українською і
          російською мовами навчання, підручників з української мови для 2, 3 і
          4 класів. Співавтор Державного стандарту початкової загальної освіти.
        </span>
      </p>
      <p>
        <span>
          За науковою редакцією М. С. Вашуленка видано монографії: «Комплексний
          підхід до фахової підготовки сучасного вчителя початкових класів»
          (2012 р.), «Формування соціокультурної компетентності майбутніх
          учителів засобами інформаційно-комунікаційних технологій» (2017 р.).
        </span>
      </p>
      <p>
        <span>
          <strong>Статті:</strong>
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Методичне підґрунтя професійної мовно-мовленнєвої
          підготовки майбутніх учителів початкових класів.{' '}
          <em>Сучасна початкова освіта: вектори розвитку</em>: зб. наук. праць.
          Бердянськ, 2012. С. 42-53.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Вивчення рідної мови розпочинається з Букваря / Зб.
          наук. праць «
          <em>
            Професійна підготовка вчителя: сучасні тенденції та перспективи
            розвитку»
          </em>
          , Східноєвропейський національний університет імені Лесі Українки,
          Луцьк, 4-5 жовтня 2012 р.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Мовленнєва підготовка майбутніх учителів початкових
          класів у педагогічному навчальному закладі .{' '}
          <em>Педагогічна і психологічна науки в Україні</em>: зб. наук. праць в
          5 т. Т.3: Загальна середня освіта. Київ, 2012. С. 374-389.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Концептуальні засади створення підручників з
          української мови для початкової школи.{' '}
          <em>
            Науковий вісник Східноєвропейського національного університету імені
            Лесі Українки
          </em>
          . Педагогічні науки, № 6, 2014.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Методичні орієнтири щодо навчання української мови у 3
          класі / М.С. Вашуленко, Н.А. Васильківська. <em>Початкова школа</em>,
          2014. №6. С.4-11.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. У педагогічній спадщині В.О. Сухомлинського
          толерантність – багатогранне поняття.{' '}
          <em>
            Науковий вісник Миколаївського державного університету імені В.О.
            Сухомлинського
          </em>
          : збірник наукових праць. / гол. ред.. В.Д. Будак. Випуск 1.46 [108].
          [Серія «Педагогічні науки»]. Миколаїв, 2014. 184 с.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Нормативне наголошування слів – важлива ознака
          літературного мовлення. <em>Початкова школа,</em> 2015. № 1. С. 17-21.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Формування у молодших школярів наукового поняття про
          класифікацію речень. <em>Актуальні проблеми лінгводидактики</em> : зб.
          наук. праць. Вип. Глухівський НПУ ім. О.Довженка. Суми, 2017. С.
          29-35.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. 1 клас. Читання, письмо, розвиток мовлення / Вашуленко
          М., Прищепа О., Вашуленко О. <em>Початкова школа.</em> 2018. № 9.
          С.37-43.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Про роботу за букварем нового видання/ Вашуленко М.,
          Вашуленко О. <em>Початкова школа.</em> 2018 № 8. С.16-21.
        </span>
        <span>
          <em>
            <strong> </strong>
          </em>
        </span>
      </p>
      <p>
        <span>
          <em>
            <strong> </strong>
          </em>
        </span>
      </p>
      <p>
        <span>
          <em>
            <strong>Основні навчальні видання (підручники та посібники)</strong>
          </em>
          <em>:</em>
        </span>
      </p>
      <p>
        <span>
          Методика навчання української мови в початковій школі:
          навчально-методичний посібник для студентів вищих навчальних закладів
          / За наук. ред. М.С.Вашуленка. Київ, 2010. 364 с.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С Українська мова і мовлення в початковій школі : метод.
          посіб. / М.С.Вашуленко. Київ, 2018. 400 с.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Буквар : підруч. для 1 кл. загальноосвіт. навч. закл. /
          М. С. Вашуленко, О. В. Вашуленко. Київ, 2012. 152 с.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Навчання грамоти в 1 класі : метод. посіб. для вчителя.
          Київ, 2012.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Українська мова : підруч. для 2 кл. загальноосвіт.
          навч. зал. з навчанням українською мовою / М.С. Вашуленко, С.Г.
          Дубовик. Київ, 2013. 160 с.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Українська мова : підруч. для 3 кл. загальноосвіт.
          навч. закл. /М.С.Вашуленко, О.І Мельничайко, Н.А.Васильківська/ за
          ред. М.С. Вашуленка. Київ, 2016. 192 с. : іл.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Довідник молодшого школяра з української мови і
          мовлення : посіб. для учнів поч. кл. загальноосвіт. навч. заклад.
          Київ, 2016. 160 с.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Українська мова : підруч. для 3 кл. спеціальних
          загальноосвіт : навч. закл. для дітей зі зниженим зором /
          М.С.Вашуленко, О.І Мельничайко, Н.А.Васильківська. Київ, 2016. 192 с.
          : іл.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Українська мова : підруч. для 4 кл. спеціальних
          загальноосвіт. навч. закл. для дітей зі зниженим зором / М.С.
          Вашуленко, С.Г. Дубовик, О.І Мельничайко. Київ, 2016. 192 с. : іл.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Українська мова. Буквар : підруч. для 1 кл. закладів
          загальної середньої освіти (у 2-х частинах) Ч. 1/ М.С.Вашуленко,
          О.В.Вашуленко. Київ, 2018. 112 с.: іл.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Українська мова. Буквар : підруч. для 1 кл. закладів
          загальної середньої освіти (у 2-х частинах) Ч. 2/ М.С.Вашуленко, О. В.
          Вашуленко. Київ, 2018. 112 с.: іл.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М. С. Українська мова та читання : підруч. для 2 класу
          закладів загальної середньої освіти (у 2-х частинах). Ч.1 /
          М.С.Вашуленко, С.Г.Дубовик. Київ, 2019. 144 с. : іл.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Робочий зошит з української мови. 2 клас (у 2-х
          частинах) Ч.1 / М.С.Вашуленко, С.Г.Дубовик. – К. : Видавничий дім
          «Освіта», 2019. 64 с.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Робочий зошит з української мови. 2 клас (у 2-х
          частинах). Ч.2 / М.С.Вашуленко, С. Г. Дубовик. Київ, 2019. 64 с.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. У світі рідного слова. Зошит із розвитку мовлення. 2
          клас: навч. посіб. / М.С.Вашуленко, С.Г.Дубовик. Київ, 2019. 48 с.
        </span>
      </p>
      <p>
        <span>
          Вашуленко М.С. Я досліджую світ : підруч. для 2 класу закладів
          загальної освіти (у 2-х частинах) Ч.1, Ч.2/ М.С.Вашуленко,
          Г.В.Ломаковська, Т.П.Єресько. Київ, 2019. по 144 с.: іл.
        </span>
      </p>
      <p>
        <span>
          За науковою редакцією М. С. Вашуленка видано с
          <strong>ловники: </strong>«Словник синонімів, антонімів і
          найпоширеніших фразеологізмів для учнів початкових класів» (2014 р.),
          «Орфографічний словник для учнів початкових класів» (2014 р., 2016
          р.,), «Словник термінів для учнів початкових класів» (2016 р.).
        </span>
      </p>
      <p>
        <span>
          Під керівництвом Миколи Самійловича виконано дослідження за рахунок
          державного бюджету :
        </span>
      </p>
      <p>
        <span>
          <em>
            «Теоретико-методичні засади комплексного підходу до фахової
            підготовки учителя початкових класів» (термін виконання 2009 – 2011
            роки).
          </em>
        </span>
      </p>
      <p>
        <span>
          <em>
            «Підготовка майбутніх учителів початкових класів до формування у
            молодших школярів ключових і предметних компетентностей» (термін
            виконання 2013-2014 роки).
          </em>
        </span>
      </p>
      <p>
        <span>
          <em> </em>
        </span>
      </p>
      <p>
        <span>
          <em>
            <strong>
              Публічне визнання досягнень представників школи (премії, перемоги
              у конкурсах, гранти, медалі, дипломи, інші нагороди, публікації у
              рейтингових журналах, членство у комітетах наукових конференцій
              міжнародного рівня та редакційних колегіях наукових журналів і
              збірників тощо):
            </strong>
          </em>
        </span>
      </p>
      <p>
        <span>
          Миколу Самійловича Вашуленка нагороджено Почесною Грамотою Кабінету
          Міністрів України, значком «Отличник просвещения СССР», знаками МОН
          України «Відмінник освіти України», «За наукові досягнення», знаки
          НАПН України «Григорій Сковорода», «Володимир Мономах», «Ушинський
          К.Д.», «Макаренко А. С.». Присуджено Сумську обласну премію імені А.
          С. Макаренка у номінації «Вищий навчальний заклад» (2009 р.).
          Нагороджений відзнакою Українського Реєстрового Козацтва «Бронзовий
          козацький хрест» ІІІ ступеня (2011 р.).
        </span>
      </p>
      <p>
        <span>
          Заслужений діяч науки і техніки України (1998 р.), Лауреат Державної
          премії України в галузі освіти (2011 р.) в номінації «загальна середня
          освіта» за цикл робіт «Нова початкова школа» (у складі колективу),
          занесений на Дошку Пошани «Педагогічна гордість Сумщини» (2012 р.),
          «Почесний громадянин міста Глухова» (2008 р.). Почесний професор
          Глухівського НПУ ім. О. Довженка (2016 р.). Занесено до книги
          «Науковці України – еліта держави» (2019 р.).
        </span>
      </p>
      <p>
        <span>
          <em>
            <strong>
              Залучення до наукової діяльності за напрямом школи студентів,
              аспірантів, докторантів
            </strong>
          </em>
          <em>:</em> Вашуленко М.С. підготував 5 докторів і 37 кандидатів наук з
          методики навчання української мови. Брав участь у роботі багатьох
          робочих груп і комісій при МОН України. Протягом 10 років очолював
          журі на Всеукраїнському конкурсі «Учитель року» в номінації «Початкове
          навчання».
        </span>
      </p>
      <p>
        <span>
          <em>
            <strong>
              Участь представників школи у діяльності спеціалізованих вчених рад
              з захисту кандидатських, докторських дисертацій
            </strong>
          </em>
          <em>:</em> упродовж багатьох років Вашуленко М. С.був членом
          спеціалізованих вчених рад із захисту дисертацій: в Інституті
          педагогіки НАПН України (з 2009 р.), Національному педагогічному
          університеті ім. М.П.Драгоманова (2009р.), Д 41.053.01 при Державному
          закладі «Південноукраїнський національний педагогічний університет
          імені К.Д. Ушинського» (2009-2013 рр.), К 26.452.02 в Інституті
          педагогіки НАПН України (з 2013 р.); член спеціалізованої вченої ради
          Д 56.146.01 із захисту дисертацій на здобуття наукових ступенів
          доктора і кандидата педагогічних наук при Глухівському національному
          педагогічному університеті імені Олександра Довженка, спеціалізованої
          вченої ради Д 26.133.05 Київського університету імені Бориса Грінченка
          за спеціальністю 13.00.02 – Теорія та методика навчання (українська
          мова), також член науково-експертної ради.
        </span>
      </p>
      <p>
        <span>
          Бере участь в опонуванні дисертацій на здобуття наукових ступенів
          доктора та кандидата педагогічних наук.
        </span>
      </p>
    </>
  );
};

export default VashulenkoM;
