import ExternalLink from '../../../components/UI/external-link/external-link.component';

const Practice = () => (
  <>
    <h2>Практика</h2>
    <ul>
      <li>
        <h4>
          Робочі програми практик 2022.
          <ExternalLink href="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fpractice%2F%D0%9D%D0%B0%D1%83%D0%BA%D0%BE%D0%B2%D0%BE-%D0%BF%D0%B5%D0%B4%D0%B0%D0%B3%D0%BE%D0%B3%D1%96%D1%87%D0%BD%D0%B0%20%D0%BF%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B0.pdf?alt=media&token=058f6377-27a1-4970-bbe3-5f5b4537193c" />
          <ExternalLink href="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fpractice%2F%D0%9F%D0%B5%D0%B4%D0%B0%D0%B3%D0%BE%D0%B3%D1%96%D1%87%D0%BD%D0%B0%20%D0%BF%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B0%20%D1%83%20%D0%97%D0%92%D0%9E.pdf?alt=media&token=fe0dbb7c-2619-4691-a576-9759d8cb077f" />
        </h4>
      </li>

      <li>
        <h4>
          Робочі програми практик 2021.
          <ExternalLink href="https://drive.google.com/drive/folders/1v09R1l6osKjy9__NFCj1gO7FwLtt778W" />
        </h4>
      </li>
    </ul>
  </>
);

export default Practice;
