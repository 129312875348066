import styled from 'styled-components';

export const Aside = styled.aside`
  text-align: center;
  margin: 0 10px;
  min-height: 100vh;
  font-size: 1.1em;
  padding-top: 1px;
  padding-bottom: 10px;
  @media screen and (min-width: 768px) {
    margin: 0 10px 0 0;
  }
`;
export const AsideCard = styled.div`
  background: var(--gradient-secondary);
  padding: 5px 10px 10px;
  margin-top: 10px;
  box-shadow: -10px 10px 15px -15px black, inset 0 0 14px -7px black;
  border-radius: 0.5rem;

  h4 {
    text-align: center;
    color: var(--color-primary-darker);
    text-shadow: var(--text-shadow-link);
  }

  h5 {
    margin: 5px;
    color: var(--color-primary-darker);
    text-shadow: var(--text-shadow-link);
    font-size: 1.1rem;
  }

  ul {
    padding: 0 10px;
    margin: 0 0 10px;
    li {
      margin-bottom: 5px;
      & > .active {
        color: var(--color-primary-darker);
        text-shadow: var(--text-shadow-link);
        font-weight: bold;
        text-decoration: underline;
      }
    }
    li,
    li > a {
      list-style: none;
      text-align: start;
      color: var(--color-tertiary);
      font-weight: bold;
      &:hover {
        color: var(--color-primary-darker);
      }
      &#war {
        color: black;
        transition: text-shadow 3s ease;
        &:hover {
          text-shadow: 0 0 10px crimson;
        }
      }
    }
  }
`;
