import styled, { css } from 'styled-components';

export const sharedCSSForBlocksWithBG = css`
  margin: 0 auto 30px;
  background: var(--gradient-secondary);
  padding: 20px 10px 10px;
  border-radius: 1rem;
  box-shadow: -15px 15px 15px -15px black, inset 0 0 14px -7px black;
  font-weight: bold;
`;
export const ListWithBG = styled.ul`
  ${sharedCSSForBlocksWithBG};
`;

export const ItemListWithBG = styled.li`
  text-align: start;
`;
