import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import EducationalSchedule from '../pages/education/educational_schedule/educational-schedule.component';
import Page404 from '../components/page404/page404.component';
import SessionSchedule from '../pages/education/examination-session-schedule/session-schedule.component';
import AttestationSchedule from '../pages/education/examination-attestation-schedule/attestation-schedule.component';
import SemiAttestation from '../pages/education/attestation-results-semi/semi-attestation.component';
import FinalAttestation from '../pages/education/attestation-results-final/final-attestation.component';
import Rating from '../pages/education/attestation-results-rating/rating.component';
import Programs from '../pages/education/programs/programs.component';
import Selective from '../pages/education/selective/selective.component';
import Dual from '../pages/education/dual/dual.component';
import Syllabus from '../pages/education/syllabus/syllabus.component';
import Practices from '../pages/education/practices/practices.component';
import NonCitizenStudents from '../pages/education/non-citizen-students/non-citizen-students.component';
import AcademicIntegrity from '../pages/education/academic-integrity/academic-integrity.component';
import PublicDiscussion from '../pages/education/public-discussion/public-discussion';
const PublicDiscussionStakeholders20230608 = lazy(
  () =>
    import('../pages/education/public-discussion/public-discussion-stakeholders-20230608')
);

const Education = () => (
  <Routes>
    <Route index element={<Page404 />} />
    <Route path="educational-schedule" element={<EducationalSchedule />} />
    <Route path="examination/session-schedule" element={<SessionSchedule />} />
    <Route path="examination/attestation-schedule" element={<AttestationSchedule />} />
    <Route path="attestation-results/semi" element={<SemiAttestation />} />
    <Route path="attestation-results/final" element={<FinalAttestation />} />
    <Route path="attestation-results/rating" element={<Rating />} />
    <Route path="selective" element={<Selective />} />
    <Route path="programs" element={<Programs />} />
    <Route path="syllabus" element={<Syllabus />} />
    <Route path="practices" element={<Practices />} />
    <Route path="dual" element={<Dual />} />
    <Route path="non-citizen-students" element={<NonCitizenStudents />} />
    <Route path="academic-integrity" element={<AcademicIntegrity />} />
    <Route path="public-discussion" element={<PublicDiscussion />} />
    <Route
      path="public-discussion/stakeholders-2023-06-08"
      element={<PublicDiscussionStakeholders20230608 />}
    />
    <Route path="/*" element={<Page404 />} />
  </Routes>
);
export default Education;
