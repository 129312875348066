import { Link } from 'react-router-dom';

import AboutFaculty from '../about/about-faculty.component';
import AnnouncementShort from '../../components/announcement-short/announcement-short.component';

import { selectNews } from '../../store/announcements/announcements.selector';
import { useAppSelector } from '../../store/store';

import { AboutContainer, AnnouncementsContainer } from './home.styles';

const Home = () => {
  const news = useAppSelector(selectNews);

  return (
    <>
      <AboutContainer>
        <div>
          <AboutFaculty />
        </div>
        <Link to="about">Читати повністю</Link>
      </AboutContainer>
      <hr />
      <AnnouncementsContainer>
        <h2>Останні новини</h2>
        {news
          .filter((_, index) => index < 5)
          .map((announcement, index) => (
            <AnnouncementShort key={index} announcement={announcement} />
          ))}
      </AnnouncementsContainer>
      <Link to="announcements" title="До всіх новин">
        <h2>До всіх новин інституту</h2>
      </Link>
    </>
  );
};

export default Home;
