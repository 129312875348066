import { FC } from 'react';
import { Link } from 'react-router-dom';

import { EmptyPageElement } from './empty-page.styles';

type EmptyPageProps = {
  title: string;
  warning?: boolean;
};
const EmptyPage: FC<EmptyPageProps> = ({ title, warning = false }) => {
  return (
    <EmptyPageElement>
      <h2>{title}</h2>
      <Link to={'/'}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/layout%2Fempty-page.webp?alt=media&token=b774ac79-aa41-4f63-aee6-3ab01c30dacc"
          alt="empty page"
        />
        {warning && <h1>404</h1>}
      </Link>
    </EmptyPageElement>
  );
};
export default EmptyPage;
