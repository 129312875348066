import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ExternalLink from '../UI/external-link/external-link.component';

import {
  PostgraduatesCard,
  PostgraduatesCardImage,
  PostgraduatesCardName
} from './postgraduate-card.styles';

export type Postgraduate = {
  linkTo: string;
  name: string;
  photo: string;
  specialty: string;
  onp: string;
  educationForm: string;
  dissertationTopic: string;
  supervisor: {
    name: string;
    url: string;
  };
};
type PostgraduateCardProps = {
  postgraduate: Postgraduate;
};
const PostgraduateCard: FC<PostgraduateCardProps> = ({ postgraduate }) => {
  const navigate = useNavigate();
  const {
    name,
    photo,
    specialty,
    onp,
    educationForm,
    dissertationTopic,
    supervisor,
    linkTo
  } = postgraduate;
  const goToMore = () => linkTo && navigate(linkTo);
  return (
    <PostgraduatesCard>
      <PostgraduatesCardName onClick={goToMore}>{name}</PostgraduatesCardName>
      <PostgraduatesCardImage
        src={
          photo ||
          'https://firebasestorage.googleapis.com/v0/b/fdo-gnpu.appspot.com/o/aside%2Falumni-association%2Fno-photo.webp?alt=media&token=4c9ebe49-ee68-4024-865d-ca023f8ed339'
        }
        alt={name}
        onClick={goToMore}
      />
      <p>
        <b>Спеціальність: </b>
        {specialty}
      </p>
      <p>
        <b>Освітньо-наукова програма: </b>
        {onp}
      </p>
      <p>
        <b>Форма навчання: </b>
        {educationForm}
      </p>
      <p>
        <b>Тема дисертації: </b>
        {dissertationTopic}
      </p>
      <p>
        <b>Науковий керівник: </b>
        <ExternalLink href={supervisor.url} text={supervisor.name} />
      </p>
      <Link to={linkTo}>
        Докладніше <i className={'fa fa-arrow-right'} />
      </Link>
    </PostgraduatesCard>
  );
};
export default PostgraduateCard;
