import MapAnnouncementsShort from '../../../components/map-announcements-short/map-announcements-short.component';

const Extracurricular = () => (
  <>
    <h2>Позанавчальна робота</h2>
    <MapAnnouncementsShort annoType="extracurricular" />
  </>
);

export default Extracurricular;
