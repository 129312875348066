import { FC } from 'react';
import { PersonnelListContainer } from './personnel-map.styles';

type PersonnelMapProps = {
  personnelList: {
    name: string;
    qualification: string;
    councilTitle: string;
  }[];
  title: string;
  additionalText: string;
};

const PersonnelMap: FC<PersonnelMapProps> = ({
  personnelList,
  title,
  additionalText
}) => {
  return (
    <PersonnelListContainer>
      <>
        {title && <h3>{title}</h3>}
        {additionalText && (
          <p>
            <em>{additionalText}</em>
          </p>
        )}
        {personnelList.map(({ name, qualification, councilTitle }, index) => {
          return (
            <li key={index}>
              <b>{name}</b>, {qualification}
              {councilTitle && (
                <>
                  {' '}
                  – <i>{councilTitle}</i>
                </>
              )}
              {index !== personnelList.length - 1 ? ';' : '.'}
            </li>
          );
        })}
      </>
    </PersonnelListContainer>
  );
};
export default PersonnelMap;
