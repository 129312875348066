export type Theme = {
  name: string;
  colors: {
    primary: string;
    secondary: string;
    tertiary: string;
    primaryDarker: string;
    secondaryDarker: string;
    tertiaryDarker: string;
    tertiaryOpacity: string;
    bgMain: string;
    textShadowMain?: string;
  };
};

export const primaryTheme = {
  name: 'primary_theme',
  colors: {
    primary: '#164F59',
    secondary: '#F1DC83',
    tertiary: '#228b6d',
    primaryDarker: '#294104e6',
    secondaryDarker: '#F1DC8388',
    tertiaryDarker: '#607d8b',
    tertiaryOpacity: '#AFF8DD80',
    bgMain: '#f5fbde8c',
    textShadowMain: undefined
  }
};
export const secondaryTheme = {
  name: 'secondary_theme',
  colors: {
    primary: '#515151',
    secondary: '#efefef',
    tertiary: '#228b6d',
    simpleText: '#f0f0f0',
    primaryDarker: '#3f3f3fe6',
    secondaryDarker: '#e2e2e288',
    tertiaryDarker: '#000',
    tertiaryOpacity: '#bff8e3',
    bgMain: 'var(--color-tertiary)',
    textShadowMain: '0 0 15px #fff'
  }
};
// export const highContrastPurple = {
//   name: "high-contrast-purple_theme",
//   colors: {
//     primary: "#261e41",
//     secondary: "#81ebe6",
//     tertiary: "#80228b",
//     primaryDarker: "#3f3f3fe6",
//     secondaryDarker: "#e2e2e288",
//     tertiaryDarker: "#607d8b",
//     tertiaryOpacity: "#AFF8DD80",
//     bgMain: "var(--color-tertiary)",
//   },
// };
