import { UlItems, UnorderedList } from './regulations.styles';

const Regulations = () => (
  <>
    <h2>Нормативні документи</h2>
    <UnorderedList>
      <UlItems>
        Положення про Навчально-науковий інститут педагогіки і психології Глухівського
        національного педагогічного університету імені Олександра Довженка.
        <a href="https://docs.google.com/document/d/1Xctf7hPipBlNJV9kGUO4SfqJC79V2EBy/view">
          Ознайомитися <i className="fa fa-external-link" />
        </a>
      </UlItems>
      <UlItems>
        Положення про директорат Навчально-наукового інституту педагогіки і психології
        Глухівського національного педагогічного університету імені Олександра Довженка.
        <a href="https://docs.google.com/document/d/1GzGKUoIDhNreVMim2HylyOqOntUIXHpD/view">
          Ознайомитися <i className="fa fa-external-link" />
        </a>
      </UlItems>
      <UlItems>
        Положення про вчену раду Навчально-наукового інституту педагогіки і психології
        Глухівського національного педагогічного університету імені Олександра Довженка.
        <a href="https://docs.google.com/document/d/1azdngzYkOeqHbW5gaiQqihzyH75lOV0d/view">
          Ознайомитися <i className="fa fa-external-link" />
        </a>
      </UlItems>
      <UlItems>
        Положення про навчальний кабінет «Нова українська школа» Глухівського
        національного педагогічного університету імені Олександра Довженка.
        <a href="https://drive.google.com/file/d/1200-1qvKVz_L0dYDWP3Bd5ZLuuJyFura/viewОзнайомитися">
          Ознайомитися <i className="fa fa-external-link" />
        </a>
      </UlItems>
    </UnorderedList>
  </>
);
export default Regulations;
