import StudentToCards from '../../../components/student-to-cards/student-to-cards.component';

import starostat from '../../../data/starostat.json';

const Starostat = () => (
  <>
    <h2>Старостат</h2>
    <StudentToCards studentList={starostat} />
  </>
);

export default Starostat;
