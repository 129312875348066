import styled from 'styled-components';

export const ProfileContainer = styled.section`
  margin: 0 auto 30px;
  max-width: 1000px;
  background: var(--gradient-secondary);
  padding: 10px 20px 20px;
  border-radius: 1rem;
  box-shadow: -15px 15px 15px -15px black, inset 0 0 14px -7px black;

  ul {
    margin: 0;
    padding: 0 !important;
    li {
      margin-bottom: 0.25em !important;
    }
  }
`;

export const MainInfo = styled.div`
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (min-width: 992px) {
    flex-direction: row;
    align-items: start;
    & > div:first-child {
      padding-top: 1.33em;
      width: 30%;
    }
    & > div:last-child {
      padding-left: 2%;
      width: 70%;
    }
  }
  img {
    width: 100%;
    max-width: 400px;
    border-radius: 0.5rem;
  }
  div {
    @media screen and (min-width: 992px) {
      width: unset;
      padding-left: unset;
    }
    h3 {
      margin: 0.7em 0;
      text-align: center;
      text-shadow: 0 0 4px white;

      :first-child {
        font-size: 1.3em;
        text-shadow: 0 0 6px white;
      }
    }
  }
`;
export const AdditionalInfo = styled.div`
  h4 {
    text-align: start;
    margin: 15px 0 0.25em;
    font-size: 1.2rem;
  }
  p {
    text-align: justify;
    margin: 0;
  }

  ol {
    padding-left: 15px;
    li {
      text-align: justify;
      font-size: 1.1rem;
      overflow-wrap: anywhere;
    }
  }

  address {
    text-align: start;
  }
  .rpv-core__inner-page {
    background: none;
  }
`;
export const WorksListWrapper = styled.div`
  padding: 5px 5px 5px 15px;
  height: 90vh;
  overflow: auto;
  background: rgba(0, 0, 0, 0.1);
  margin-left: -10px;
  margin-right: -10px;
  border-radius: 0.5rem;
`;

export const FontAwesomeChevron = styled.i``;

type ClickableH4Props = {
  close: boolean;
};
export const ClickableH4 = styled.h4<ClickableH4Props>`
  cursor: pointer;

  ${FontAwesomeChevron} {
    transition: transform 0.3s;
    ${({ close }) => close && `transform: rotate(-90deg)`};
  }

  & + ${WorksListWrapper} {
    ${({ close }) => close && 'display: none'};
  }
`;

export const CerfInfoWrapper = styled(WorksListWrapper)`
  height: 100%;
  img {
    box-shadow: none;
  }
`;
