import { Route, Routes } from 'react-router-dom';
import Page404 from '../components/page404/page404.component';
import Onp from '../pages/postgraduate/onp/onp.component';
import SyllabusPostgraduate from '../pages/postgraduate/syllabus/syllabus.component';
import Postgraduates from '../pages/postgraduate/postgraduates/postgraduates.component';
import Staffing from '../pages/postgraduate/staffing/staffing.component';
import SelectivePostgraduate from '../pages/postgraduate/selective/selective.component';
import EducationQuality from '../pages/postgraduate/education-quality/education-quality.component';
import Practice from '../pages/postgraduate/practice/practice.component';
import EntranceExams from '../pages/postgraduate/entrance-exams/entrance-exams.component';
import EventsPostgraduate from '../pages/postgraduate/events/events.component';
import Accreditation from '../pages/postgraduate/accreditation/accreditation.component';
import Discussion from '../pages/postgraduate/discussion/discussion.component';
import PostgraduateProfile from '../components/postgraduate-profile/postgraduate-profile.component';
import Announcement from '../components/announcement/announcement.component';

const Postgraduate = () => (
  <Routes>
    <Route index element={<Page404 />} />
    <Route path="onp" element={<Onp />} />
    <Route path="selective" element={<SelectivePostgraduate />} />
    <Route path="syllabus" element={<SyllabusPostgraduate />} />
    <Route path="postgraduates" element={<Postgraduates />} />
    <Route path="postgraduates/:id" element={<PostgraduateProfile />} />
    <Route path="staffing" element={<Staffing />} />
    <Route path="education-quality" element={<EducationQuality />} />
    <Route path="practice" element={<Practice />} />
    <Route path="entrance-exams" element={<EntranceExams />} />
    <Route path="events" element={<EventsPostgraduate />} />
    <Route
      path="events/:id"
      element={<Announcement annoType={'postgraduate'} />}
    />
    <Route path="accreditation" element={<Accreditation />} />
    <Route path="discussion" element={<Discussion />} />
    <Route path="/*" element={<Page404 />} />
  </Routes>
);
export default Postgraduate;
