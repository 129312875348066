import syllabus from '../../../data/selective-education.json';
import TableSpecialMaster from './table-special-master';
import TableSpecialBachelor from './table-special-bachelor';
import SelectiveTableMap from './selective-table-map';

const Selective = () => (
  <>
    <h2>Каталог дисциплін вільного вибору</h2>

    <h4>
      Каталог загальноуніверситетських дисциплін{' '}
      <a
        href="http://gnpu.edu.ua/index.php/ua/osvitnii-protses/kataloh-zahalnouniversytetskykh-vybirkovykh-dystsyplin"
        target="_blank"
        rel="noreferrer noopener"
      >
        Ознайомитися <i className={'fa fa-external-link'} />
      </a>
    </h4>

    <h4>
      Перелік вибіркових освітніх компонентів циклу загальної підготовки для здобувачів
      вищої освіти за ОС «Бакалавр»
    </h4>
    <SelectiveTableMap syllabuses={syllabus.bachelor} />

    <h4>
      Перелік вибіркових освітніх компонентів циклу загальної підготовки для здобувачів
      вищої освіти за ОС «Магістр»
    </h4>
    <SelectiveTableMap syllabuses={syllabus.master} />

    <h4>
      Перелік вибіркових освітніх компонентів циклу професійної підготовки для здобувачів
      вищої освіти за ОС «Бакалавр»
    </h4>
    <TableSpecialBachelor />
    <h4>
      Перелік вибіркових освітніх компонентів циклу професійної підготовки для здобувачів
      вищої освіти за ОС «Магістр»
    </h4>
    <TableSpecialMaster />
  </>
);
export default Selective;
