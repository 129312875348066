import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  NextOrObserver,
  User
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  writeBatch,
  query,
  getDocs,
  QueryDocumentSnapshot
} from 'firebase/firestore';
import { NewsItem } from '../../data/announcements-list';

const firebaseConfig = {
  apiKey: 'AIzaSyDcLB1WKQN459zR18Z54AYXEZ-FgFiFhwE',
  authDomain: 'nni-pp-gnpu.firebaseapp.com',
  projectId: 'nni-pp-gnpu',
  storageBucket: 'nni-pp-gnpu.appspot.com',
  messagingSenderId: '143854587590',
  appId: '1:143854587590:web:0f388d8b1427e38e8651c7',
  measurementId: 'G-8DS59T4EWH'
};

initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: 'select_account'
});

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, googleProvider);
export const db = getFirestore();

export type ObjectsToAdd = {
  title: string;
};
export const addCollectionAndDocuments = async <T extends ObjectsToAdd>(
  collectionKey: string,
  objectsToAdd: T[]
): Promise<void> => {
  const collectionRef = collection(db, collectionKey);
  const batch = writeBatch(db);
  objectsToAdd.forEach((object) => {
    const docRef = doc(collectionRef, object.title.toLowerCase());
    batch.set(docRef, object);
  });
  await batch.commit();
  alert('something uploaded to the database');
};

export const getDatabaseDocList = async (
  collectionName: string,
  documentName: string,
  listName: string
): Promise<NewsItem[]> => {
  const newsDocRef = doc(db, collectionName, documentName);
  const newsSnapshot = await getDoc(newsDocRef);
  const { [listName]: items } = newsSnapshot.data() as {
    [key: string]: NewsItem[];
  };
  return items;
};
export const getCollection = async (collectionName: string) => {
  const collectionRef = collection(db, collectionName);
  const collectionQuery = await query(collectionRef);
  const documentsSnapshot = await getDocs(collectionQuery);
  return documentsSnapshot.docs.map((docSnapshot) => docSnapshot.data());

  // const documentsMap = documentsSnapshot.docs.reduce((acc, docSnapshot) => {
  //   const { title, items } = docSnapshot.data();
  //   acc[title.toLowerCase()] = items;
  //   return acc;
  // }, {});
  // return documentsMap;
};

export type AdditionalInformation = {
  displayName?: string;
};
export type UserData = {
  createdAt: Date;
  displayName: string;
  email: string;
};
export const createUserDocumentFromAuth = async (
  userAuth: User,
  additionalInformation = {} as AdditionalInformation
): Promise<void | QueryDocumentSnapshot<UserData>> => {
  if (!userAuth) return;

  const userDocRef = doc(db, 'users', userAuth.uid);

  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        ...additionalInformation
      });
    } catch (error) {
      console.log('error creating the user data', error);
    }
  }
  return userSnapshot as QueryDocumentSnapshot<UserData>;
};

export const createAuthUserWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInAuthUserWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = async (callback: NextOrObserver<User>) =>
  await onAuthStateChanged(auth, callback);

export const getCurrentUser = (): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (userAuth) => {
        unsubscribe();
        resolve(userAuth);
      },
      reject
    );
  });
};
