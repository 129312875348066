import { NavLink } from 'react-router-dom';
import { Aside, AsideCard } from './aside-container.styles';

const AsideContainer = () => {
  return (
    <Aside>
      <AsideCard>
        <h4>ВСТУПНИКУ</h4>
        <ul>
          <li>
            <NavLink to="entrants/news">Новини вступної кампанії 2023 року</NavLink>
          </li>
          <li>
            <NavLink to="entrants/specialties">Спеціальності інституту</NavLink>
          </li>
        </ul>
      </AsideCard>
      <AsideCard>
        <h4>ЗДОБУВАЧУ ОСВІТИ</h4>
        <ul>
          <li>
            <a
              href={'http://193.169.124.188/cgi-bin/timetable.cgi'}
              target={'_blank'}
              rel="noreferrer"
            >
              Розклад занять <i className="fa fa-external-link" />
            </a>
          </li>
          <li>
            <NavLink to={'student/guest-lectures'}>Гостьові лекції</NavLink>
          </li>
          <li>
            <a
              href="http://gnpu.edu.ua/index.php/ua/struktura/psykholohichna-sluzhba"
              target="_blank"
              rel="noreferrer"
            >
              Психологічна служба <i className="fa fa-external-link" />
            </a>
          </li>
          <li>
            <NavLink to="student/trust-mailbox">Скринька довіри</NavLink>
          </li>
        </ul>
      </AsideCard>
      <AsideCard>
        <h4>АКРЕДИТАЦІЯ ОСВІТНІХ ПРОГРАМ</h4>
      </AsideCard>

      <AsideCard>
        <h4>СТУДЕНТСЬКЕ ЖИТТЯ</h4>
        <ul>
          <li>
            <NavLink to={'about/administration/local-government'}>
              Студентське самоврядування
            </NavLink>
          </li>
          <li>
            <NavLink to={'student-life/starostat'}>Старостат</NavLink>
          </li>
          <li>
            <NavLink to="student-life/leisure-time">Студентське дозвілля</NavLink>
          </li>
          <li>
            <NavLink to={'student-life/extracurricular'}>Позанавчальна робота</NavLink>
          </li>
          <li>
            <NavLink to={'student-life/honor-board'}>Дошка пошани</NavLink>
          </li>
          <li>
            <NavLink to="student-life/photo-gallery">Фотогалерея</NavLink>
          </li>
        </ul>
      </AsideCard>
      <AsideCard>
        <NavLink to="about/contacts">
          <h4>
            КОНТАКТИ <i className={'fa fa-map-marker'}></i>
          </h4>
        </NavLink>
      </AsideCard>
    </Aside>
  );
};
export default AsideContainer;
