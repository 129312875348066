import styled from 'styled-components';

export const MainContentContainer = styled.div`
  display: block;
  background-color: var(--bg-color-main);

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 0px;
  }
  @media screen and (min-width: 1232px) {
    grid-template-columns: 7fr 3fr;
  }
`;
