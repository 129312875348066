import { FC } from 'react';
import ImageGallery from 'react-image-gallery';

import { replaceUrlsAndEmails } from '../../utils/replaceUrlsAndEmails/replaceUrlsAndEmails';

import AnnounceDate from '../announce-date/announce-date.component';

import { NewsItem } from '../../data/announcements-list';

import {
  AdditionalInformation,
  AnnouncementContainer,
  WrapAndHide,
  MainInformation,
  LinkToMore
} from './announcement-short.styles';

type AnnouncementShortProps = { announcement: NewsItem };

const AnnouncementShort: FC<AnnouncementShortProps> = ({ announcement }) => {
  const { shortCut, imagesList, title, text, date, linkTo, onlyImage } = announcement;
  const fadingText = text.map((paragraph, index) => (
    <p
      key={index}
      dangerouslySetInnerHTML={{
        __html: replaceUrlsAndEmails(paragraph)
      }}
    />
  ));
  return (
    <AnnouncementContainer>
      {title && <h2>{title}</h2>}

      {(onlyImage && <img src={onlyImage} alt={title} />) ||
        (shortCut && <p>{shortCut}</p>)}
      {imagesList[0]?.original && (
        <ImageGallery
          items={[imagesList[0]]}
          showPlayButton={false}
          showThumbnails={false}
        />
      )}
      {!shortCut && text[0] && (
        <MainInformation>
          <WrapAndHide>{text[0] && fadingText}</WrapAndHide>
        </MainInformation>
      )}
      <hr />
      <AdditionalInformation>
        {linkTo && (
          <LinkToMore to={linkTo}>
            Докладніше <i className="fa fa-arrow-right" />
          </LinkToMore>
        )}
        {date && <AnnounceDate date={date} />}
      </AdditionalInformation>
    </AnnouncementContainer>
  );
};
export default AnnouncementShort;
