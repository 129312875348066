import { Route, Routes } from "react-router-dom";
import Page404 from "../components/page404/page404.component";
import EntrantsNews from "../pages/entrants/news/news.component";
import Specialties from "../pages/entrants/specialties/specialties.component";
import Rules from "../pages/entrants/rules/rules.component";

const Entrants = () => (
  <Routes>
    <Route index element={<Page404 />} />
    <Route path="news" element={<EntrantsNews />} />
    <Route path="specialties" element={<Specialties />} />
    <Route path="rules" element={<Rules />} />
    <Route path="/*" element={<Page404 />} />
  </Routes>
);
export default Entrants;
