import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/nni-pip-logo.svg';

export const LogoContainer = styled(Link)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  background: var(--color-primary);
  background: linear-gradient(
    var(--color-primary) 0%,
    var(--color-tertiary) 75%,
    var(--color-primary) 100%
  );

  @media screen and (max-width: 800px) {
    width: auto;
    padding: 0;
  }
  @media screen and (max-width: 526px) {
    font-size: 0.6rem;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    padding-left: 20px;

    h1 {
      color: var(--color-secondary);
      font-size: 2.5em;
      text-shadow: 1px 1px 2px black, 0 0 1em #725f09, 0 0 0.2em #c8af22;
      font-family: 'Yeseva One', cursive;
    }

    h3 {
      font-size: 1.5em;
      color: var(--color-secondary);
      text-shadow: var(--text-shadow);
      font-family: 'Yeseva One', cursive;
    }
  }
`;
export const MainLogo = styled(Logo)`
  margin: auto;
  padding-right: 0.5rem;
  max-height: 15rem;
`;

export const Gaudeamus = styled.div`
  padding: 1em 0;
  color: white;
  text-align: center;
  font-weight: bold;
  font-style: italic;
  background: var(--gradient-primary);
  font-family: 'Kurale', serif;

  p {
    margin: 0;
  }
`;
