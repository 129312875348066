import styled from 'styled-components';

export const Table = styled.table`
  margin: 0 auto 20px;
  border-spacing: 0;
  width: 95%;
  border: 1px solid;
  th,
  td {
    border: 1px solid;
    padding: 2px;
    border-top-width: 0;
    border-left-width: 0;
    text-align: start;
    vertical-align: top;
    @media screen and (min-width: 768px) {
      font-size: 1.2em;
      padding: 5px 10px;
    }
    a {
      display: block;
    }
  }

  tr > th:last-child {
    padding: 10px 5px;
  }
`;
export const CenteredCell = styled.td`
  text-align: center !important;
`;
