const AcademicIntegrity = () => (
  <>
    <h2>Академічна доброчесність</h2>
    <ul>
      <li>
        <h4>
          Ознайомитися з засадами Академічної доброчесності на головному сайті
          університету
          <a
            href="http://gnpu.edu.ua/index.php/ua/pro-universytet/akademichna-dobrochesnist"
            target="_blank"
            rel="noreferrer noopener"
          >
            Перейти <i className={'fa fa-external-link'} />
          </a>
        </h4>
      </li>
    </ul>
  </>
);
export default AcademicIntegrity;
