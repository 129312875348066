import styled from 'styled-components';
import { MapContainer } from 'react-leaflet';

export const HighMapContainer = styled(MapContainer)`
  width: 100%;
  height: calc(50vh - 4rem);
  box-shadow: 0 0 18px -8px black;
  border-radius: 0.5rem;

  img {
    box-shadow: none !important;
  }

  a {
    color: var(--color-primary) !important;
    text-shadow: var(--text-shadow-link);
  }
`;
