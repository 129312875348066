import { FC, lazy, Suspense } from 'react';
import { ScrollRestoration } from 'react-router-dom';

import Navigation from '../components/navigation/navigation.component';
import ScrollUpBtn from '../components/UI/scroll-up-btn/scroll-up-btn.component';
import AsideContainer from '../components/aside-container/aside-container.component';
import GlobalHeader from '../components/global-header/global-header.component';
import Spinner from '../components/UI/spinner/spinner.component';

import { MainContentContainer } from './layout.styles';
import NavButton from '../components/UI/menu-button/nav-button.component';

const PageContainer = lazy(() => import('./page-container.component'));
const GlobalFooter = lazy(
  () => import('../components/global-footer/global-footer.component')
);

type LayoutProps = {
  themeChangeHandler: (theme: any) => void;
};
const Layout: FC<LayoutProps> = ({ themeChangeHandler }) => {
  return (
    <>
      <Navigation />
      <NavButton />
      <ScrollUpBtn />
      <GlobalHeader />
      <MainContentContainer>
        <Suspense fallback={<Spinner />}>
          <PageContainer />
        </Suspense>
        <AsideContainer />
      </MainContentContainer>
      <Suspense fallback={<Spinner />}>
        <GlobalFooter themeChangeHandler={themeChangeHandler} />
      </Suspense>
      <ScrollRestoration />
    </>
  );
};

export default Layout;
