import styled from 'styled-components';

export const PDFOpener = styled.h3`
  cursor: pointer;
  text-align: start;
  border-radius: 0.5rem;
  background: var(--gradient-secondary);

  i {
    transition: transform 0.3s;
  }
`;
