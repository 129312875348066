import styled from 'styled-components';

export const AnnouncementContainer = styled.div`
  width: 100%;
  margin: 20px auto;
  background: var(--gradient-secondary);
  padding: 10px 20px 20px;
  border-radius: 1rem;
  box-shadow: -15px 15px 15px -15px black, inset 0 0 14px -7px black;
  img {
    width: inherit;
  }
`;
// export const PDFContainer = styled.div`
//   background-color: #e4e4e4;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow-y: auto;
//   margin-bottom: 10px;
//
//   @media screen and (max-width: 650px) {
//     height: 500px;
//   }
//   span {
//     margin-top: 3px;
//   }
// `;
export const AdditionalInformation = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 1em;
`;
