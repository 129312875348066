import styled from "styled-components";

export const PersonnelListContainer = styled.ol`
  border-radius: 1rem;
  background: var(--gradient-secondary);
  padding: 5% 5% 5% 10%;
  box-shadow: -15px 15px 15px -15px black, inset 0 0 14px -7px black;

  li {
    text-align: justify;
    font-size: 1.2rem;
    padding: 0.5rem;
  }
`;
