import StudentToCards from '../../../components/student-to-cards/student-to-cards.component';

import localGovernmentList from '../../../data/local-government.json';

const LocalGovernment = () => (
  <>
    <h2>Студентське самоврядування</h2>
    <StudentToCards studentList={localGovernmentList} />
  </>
);

export default LocalGovernment;
