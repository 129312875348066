import onpList from "../../../data/on-programs.json";
import { ListWithBG } from "./programs.styles";
const Programs = () => (
  <>
    <h2>Освітні програми</h2>
    {onpList.map(({ year, allProgramsList }, index) => (
      <div key={index}>
        <h3>{year} рік</h3>
        {allProgramsList.map(({ programDegree, programsList }, index) => (
          <ListWithBG key={index}>
            <h3>{programDegree}</h3>
            {programsList.map(({ name, link }, index) => (
              <li key={index}>
                {name}.
                <a href={link} target="_blank" rel="noopener noreferrer">
                  Ознайомитися <i className="fa fa-external-link" />
                </a>
              </li>
            ))}
          </ListWithBG>
        ))}
      </div>
    ))}
  </>
);
export default Programs;
