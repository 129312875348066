import ExternalLink from '../../../components/UI/external-link/external-link.component';

const EducationQuality = () => (
  <>
    <h2>Система забезпечення якості освіти</h2>
    <ul>
      <li>
        <h4>
          Результати опитування щодо якості освітнього процесу 2022/2023 н.р.
          <ExternalLink href="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Feducation-quality%2F%D0%9E%D0%BF%D0%B8%D1%82%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20%D0%94%D1%96%D0%B0%D0%B3%D1%80%D0%B0%D0%BC%D0%B8%20%D0%9E%D1%80%D0%B3%D0%B0%D0%BD%D1%96%D0%B7%D0%B0%D1%86%D1%96%D1%8F%20%D0%BF%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B8%202023%20%D0%BE%D1%81%D1%82%D0%B0%D0%BD%20%D0%B2%D0%B0%D1%80.pdf?alt=media&token=ca748499-0f26-4dcc-a48e-ca6c693d2fb0" />{' '}
          |
          <ExternalLink
            href="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Feducation-quality%2F%D0%9E%D0%BF%D0%B8%D1%82%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20%D0%94%D1%96%D0%B0%D0%B3%D1%80%D0%B0%D0%BC%D0%B8%202023%20%D0%BE%D1%81%D1%82%D0%B0%D0%BD%20%D0%B2%D0%B0%D1%80.pdf?alt=media&token=9537742d-2adf-44fc-a618-20b69eb23ef5"
            text=" "
          />
        </h4>
      </li>
      <li>
        <h4>
          Результати опитування щодо якості освітнього процесу 2021/2022 н.р.
          <ExternalLink href="https://drive.google.com/drive/folders/1IcEkGYvKcWV1Y2SLWphFAqs0EAXB9Z31" />
        </h4>
      </li>
    </ul>
  </>
);

export default EducationQuality;
