import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/store';

import Backdrop from '../UI/backdrop/backdrop.component';
import NavbarItem from '../navbar-item/navbar-Item.component';

import {
  createUserDocumentFromAuth,
  onAuthStateChangedListener,
  signOutUser
} from '../../utils/firebase/firebase.utils';
import {
  selectIsAuthHidden,
  selectIsMenuOpen
} from '../../store/navigation/navigation.selector';
import { toggleMenu } from '../../store/navigation/navigation.slice';
import { logOut, setCurrentUser } from '../../store/user/user.slice';
import navLinks from '../../data/navbar.json';

import { DrawerContainer } from './navigation.styles';
import { Plain } from '../navbar-item/navbar-item.styles';
import Button from '../UI/button/button.component';

const Navigation = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(({ user }) => user.isLoggedIn);
  const isMenuOpen = useAppSelector(selectIsMenuOpen);
  const isAuthHidden = useAppSelector(selectIsAuthHidden);

  useEffect(() => {
    onAuthStateChangedListener(async (user) => {
      if (user) {
        const userCredentials = await createUserDocumentFromAuth(user);
        const userData = userCredentials!.data();
        dispatch(setCurrentUser(userData));
      }
    });
  }, [dispatch]);
  const toggleMenuHandler = () => dispatch(toggleMenu());
  const onSignOutUserHandler = () => {
    signOutUser();
    dispatch(logOut());
  };
  return (
    <>
      <DrawerContainer closed={!isMenuOpen}>
        <div>
          {navLinks.map((item, index) => (
            <NavbarItem key={index} item={item} isOpen={isMenuOpen} />
          ))}
        </div>
        <Plain>
          {(isAuthHidden || isLoggedIn) &&
            (isLoggedIn ? (
              <Button onClick={onSignOutUserHandler}>Вийти</Button>
            ) : (
              <Link to="auth">
                <Button onClick={toggleMenuHandler}>Увійти / Зареєструватися</Button>
              </Link>
            ))}
        </Plain>
      </DrawerContainer>
      {isMenuOpen && <Backdrop toggleMenuHandler={toggleMenuHandler} />}
    </>
  );
};
export default Navigation;
