import HiddenPDF from '../../../components/hidden-pdf/hidden-pdf.component';
import ExternalLink from '../../../components/UI/external-link/external-link.component';

const Onp = () => {
  return (
    <>
      <h2>Освітньо-наукова програма</h2>
      <ul>
        <li>
          <h4>
            Освітньо-наукова програма «Початкова освіта» 2022 рік{' '}
            <ExternalLink href="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fonp%2F%D0%9E%D0%9D%D0%9F%20%D0%9F%D0%BE%D1%87%D0%B0%D1%82%D0%BA%D0%BE%D0%B2%D0%B0%20%D0%BE%D1%81%D0%B2%D1%96%D1%82%D0%B0%202022.pdf?alt=media&token=5b646f75-fd56-4892-9408-ceb1123652df" />
          </h4>
        </li>
        <li>
          <h4>
            Навчальний план ОНП «Початкова освіта» 2022 рік, для денної форми навчання{' '}
            <ExternalLink href="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fonp%2F%D0%9D%D0%B0%D0%B2%D1%87.%20%D0%BF%D0%BB%D0%B0%D0%BD_%D0%9E%D0%9D%D0%9F%20%D0%9F%D0%BE%D1%87%D0%B0%D1%82%D0%BA%D0%BE%D0%B2%D0%B0%20%D0%BE%D1%81%D0%B2%D1%96%D1%82%D0%B0_%D0%B4%D0%B5%D0%BD%D0%BD%D0%B0_2022.pdf?alt=media&token=64035e3a-dad8-4c7c-9bd9-c7135d8bcde2" />
          </h4>
        </li>
        <li>
          <h4>
            Навчальний план ОНП «Початкова освіта» 2022 рік, для заочної форми навчання{' '}
            <ExternalLink href="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fonp%2F%D0%9D%D0%B0%D0%B2%D1%87.%20%D0%BF%D0%BB%D0%B0%D0%BD_%D0%9E%D0%9D%D0%9F%20%D0%9F%D0%BE%D1%87%D0%B0%D1%82%D0%BA%D0%BE%D0%B2%D0%B0%20%D0%BE%D1%81%D0%B2%D1%96%D1%82%D0%B0_%D0%B7%D0%B0%D0%BE%D1%87.%D0%BD%D0%B0_2022.pdf?alt=media&token=dee99703-e3d4-4fab-917f-112126a15138" />
          </h4>
        </li>
        <li>
          <HiddenPDF
            btnText="Рецензія Олени Білої, 2022 рік"
            pdfUrl={
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fonp%2F%D0%A0%D0%B5%D1%86%D0%B5%D0%BD%D0%B7%D1%96%D1%8F%20%D0%91%D1%96%D0%BB%D0%B0_2022.pdf?alt=media&token=c96e1548-ca9d-460a-a251-dc82ccdf578f'
            }
          />
        </li>
        <li>
          <HiddenPDF
            btnText="Рецензія Антоніни Міненок, 2022 рік"
            pdfUrl={
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fonp%2F%D0%A0%D0%B5%D1%86%D0%B5%D0%BD%D0%B7%D1%96%D1%8F%20%D0%9C%D1%96%D0%BD%D0%B5%D0%BD%D0%BE%D0%BA_2022.pdf?alt=media&token=277f3ed7-cee7-4cca-8833-e8ce4b106c48'
            }
          />
        </li>
        <li>
          <HiddenPDF
            btnText="Рецензія Оксани Онопрієнко, 2022 рік"
            pdfUrl={
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fonp%2F%D0%A0%D0%B5%D1%86%D0%B5%D0%BD%D0%B7%D1%96%D1%8F%20%D0%9E%D0%BD%D0%BE%D0%BF%D1%80%D1%96%D1%94%D0%BD%D0%BA%D0%BE_2022.pdf?alt=media&token=3436485c-94aa-4a76-9465-a3dd11f85be0'
            }
          />
        </li>
        <li>
          <HiddenPDF
            btnText="Рецензія Інни Хижняк, 2022 рік"
            pdfUrl={
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fonp%2F%D0%A0%D0%B5%D1%86%D0%B5%D0%BD%D0%B7%D1%96%D1%8F%20%D0%A5%D0%B8%D0%B6%D0%BD%D1%8F%D0%BA_2022.pdf?alt=media&token=1bb62f20-c347-4345-8bed-ca20893c53a3'
            }
          />
        </li>
        <li>
          <h4>
            Освітньо-наукова програма (проєкт) «Початкова освіта» 2022 рік.
            <ExternalLink href="https://docs.google.com/document/d/1w3ovN1MWcQJcPClEytbDgmhGYjmYiBCL/view" />
          </h4>
        </li>
        <li>
          <h4>
            Освітньо-наукова програма «Початкова освіта» 2021 рік.
            <ExternalLink href="https://drive.google.com/file/d/1dD9PYv3oBgtpJkP4k-FwPbxvLrdF1XDR/view" />
          </h4>
        </li>
        <li>
          <h4>
            Навчальний план «Початкова освіта» 2021 рік, для денної форми навчання.
            <ExternalLink href="https://drive.google.com/file/d/1FJ1F8b2Yho7Hv-CNBqdN1ni6Oxgt6Uoi/view" />
          </h4>
        </li>
        <li>
          <h4>
            Навчальний план «Початкова освіта» 2021 рік, для заочної форми навчання.
            <ExternalLink href="https://drive.google.com/file/d/1SujanNSq4dZ3UpNnaCGDlKUdA01SCLG5/view" />
          </h4>
        </li>
        <li>
          <HiddenPDF
            btnText="Рецензія Олени Біди, 2021 рік"
            pdfUrl={
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fonp%2F%D0%A0%D0%B5%D1%86%D0%B5%D0%BD%D0%B7%D1%96%D1%8F%20%D0%9E.%20%D0%91%D1%96%D0%B4%D0%B0%202021.pdf?alt=media&token=c327f361-c0ff-48a5-8aa4-988eb18320f7'
            }
          />
        </li>
        <li>
          <HiddenPDF
            btnText="Рецензія Оксани Онопрієнко, 2021 рік"
            pdfUrl={
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fonp%2F%D0%A0%D0%B5%D1%86%D0%B5%D0%BD%D0%B7%D1%96%D1%8F%20%D0%9E.%D0%9E%D0%BD%D0%BE%D0%BF%D1%80%D1%96%D1%94%D0%BD%D0%BA%D0%BE%202021.pdf?alt=media&token=d72904ee-bccb-40fe-9a3a-d6a106523082'
            }
          />
        </li>
        <li>
          <HiddenPDF
            btnText="Рецензія Раїси Пріми, 2021 рік"
            pdfUrl={
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fonp%2F%D0%A0%D0%B5%D1%86%D0%B5%D0%BD%D0%B7%D1%96%D1%8F%20%D0%A0.%20%D0%9F%D1%80%D1%96%D0%BC%D0%B0%202021.pdf?alt=media&token=1afadf18-5864-4698-8c78-d9a3d2cf32e8'
            }
          />
        </li>
        <li>
          <HiddenPDF
            btnText="Рецензія Інни Хижняк, 2021 рік"
            pdfUrl={
              'https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fonp%2F%D0%A0%D0%B5%D1%86%D0%B5%D0%BD%D0%B7%D1%96%D1%8F%20%D0%86.%20%D0%A5%D0%B8%D0%B6%D0%BD%D1%8F%D0%BA%202021.pdf?alt=media&token=182c2d61-ed97-4c5a-bc4c-6d711e1dd6b9'
            }
          />
        </li>
      </ul>
    </>
  );
};

export default Onp;
