import styled from 'styled-components';
export const PDFContainer = styled.div`
  --rpv-core__inner-page-background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 800px;
  overflow-y: auto;
  margin-bottom: 10px;

  span {
    margin-top: 3px;
  }
`;
