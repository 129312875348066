import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';

import SocialScience, {
  SocialScienceProps
} from '../social-science/social-science.component';
import Page404 from '../page404/page404.component';

import noPhoto from '../../assets/abstract-user.png';

import {
  ProfileContainer,
  AdditionalInfo,
  MainInfo,
  ClickableH4,
  CerfInfoWrapper,
  WorksListWrapper,
  FontAwesomeChevron
} from './teacher-profile.styles';
import {
  GoBackBottom,
  GoBackTop
} from '../postgraduate-profile/postgraduate-profile.styles';

type TeacherProfileParams = {
  id: string;
};

export type TeacherFullProfile = {
  linkTo: string;
  name: string;
  partTime?: boolean;
  jobTitle: string;
  qualification?: string;
  imageUrl: string;
  contacts: { email: string };
  education: string[];
  degree: string[];
  dissertationTopic: string[];
  academicTitle: string[];
  awardsAndHonors: string[];
  supervision: string[];
  scientificWorksList: { workType: string; works: string[] }[];
  professionalDevelopment: string[];
  certification: { cerfInfo: string[]; certificates: { original: string }[] };
} & SocialScienceProps;

type TeacherProfileProps = {
  departmentPersonnel: TeacherFullProfile[];
};

const TeacherProfile: FC<TeacherProfileProps> = ({ departmentPersonnel }) => {
  const { id } = useParams<TeacherProfileParams>() as TeacherProfileParams;
  const navigate = useNavigate();
  const [closeWorksList, setCloseWorksList] = useState(true);
  const [closeCerf, setCloseCerf] = useState(true);
  const goBack = () => navigate(-1);
  const professor = departmentPersonnel.find(({ linkTo }) => linkTo === id);
  if (!professor) {
    return <Page404 />;
  }
  const {
    name,
    partTime,
    jobTitle,
    qualification,
    imageUrl,
    education,
    degree,
    dissertationTopic,
    academicTitle,
    awardsAndHonors,
    supervision,
    scientificWorksList,
    contacts: { email },
    social,
    professionalDevelopment,
    certification
  } = professor;

  return (
    <>
      <GoBackTop onClick={goBack}>
        <i className={'fa fa-arrow-left'} /> Повернутися на попередню сторінку
      </GoBackTop>
      <ProfileContainer>
        <MainInfo>
          <div>
            <img src={imageUrl || noPhoto} alt={name} />
            <h3>
              {name.slice(0, name.indexOf(' ')).toUpperCase()}
              <br />
              {name.slice(name.indexOf(' '))}
              {partTime && (
                <>
                  <br />
                  <span>(сумісник)</span>
                </>
              )}
            </h3>
            <hr />
            {jobTitle && (
              <>
                <h3>{jobTitle[0].toLowerCase() + jobTitle.slice(1)}</h3>
                <hr />
              </>
            )}
            {qualification && (
              <>
                <h3>{qualification}</h3>
                <hr />
              </>
            )}
            {email[0] && (
              <address>
                <h4>КОНТАКТНА ІНФОРМАЦІЯ:</h4>
                Електронна пошта: <a href={`mailto:${email}`}>{email}</a>
              </address>
            )}
            {(social.googleAcademy ||
              social.orcid ||
              social.WoSRID ||
              social.scopusID ||
              social.publons ||
              social.rGate) && (
              <div>
                <h4>ПРОФІЛІ У СОЦІАЛЬНИХ МЕРЕЖАХ:</h4>
                <SocialScience social={social} />
              </div>
            )}
          </div>
          <div>
            {education[0] && (
              <>
                <h4>Освіта:</h4>
                <ul>
                  {education.map((institute, index) => (
                    <li key={index}>{institute}</li>
                  ))}
                </ul>
              </>
            )}
            {degree[0] && (
              <>
                <h4>Науковий ступінь:</h4>
                <ul>
                  {degree.map((dg, index) => (
                    <li key={index}>{dg}</li>
                  ))}
                </ul>
              </>
            )}

            {dissertationTopic[0] && (
              <>
                <h4>Тема дисертації:</h4>
                <ul>
                  {dissertationTopic.map((topic, index) => (
                    <li key={index}>{topic}</li>
                  ))}
                </ul>
              </>
            )}
            {academicTitle[0] && (
              <>
                <h4>Вчене звання:</h4>
                <ul>
                  {academicTitle.map((title, index) => (
                    <li key={index}>{title}</li>
                  ))}
                </ul>
              </>
            )}
            {awardsAndHonors[0] && (
              <>
                <h4> Нагороди та відзнаки:</h4>
                <ul>
                  {awardsAndHonors.map((award, index) => (
                    <li key={index}>{award}</li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </MainInfo>
        <AdditionalInfo>
          {scientificWorksList[0]?.works[0] && (
            <>
              <ClickableH4
                onClick={() => setCloseWorksList(!closeWorksList)}
                close={closeWorksList}
              >
                СПИСОК НАУКОВИХ ПРАЦЬ{' '}
                <FontAwesomeChevron className="fa fa-chevron-down" />
              </ClickableH4>
              <WorksListWrapper>
                <ol>
                  {scientificWorksList.map(({ workType, works }, index) => (
                    <div key={index}>
                      <h3 style={{ fontSize: '1.2rem' }}>{workType}</h3>
                      {works.map((work, index) => (
                        <li key={index}>{work}</li>
                      ))}
                    </div>
                  ))}
                </ol>
              </WorksListWrapper>
            </>
          )}
          {professionalDevelopment[0] && (
            <>
              <h4>ПРОФЕСІЙНИЙ РОЗВИТОК ТА ДОСВІД ЕКСПЕРТНОЇ ДІЯЛЬНОСТІ:</h4>
              <ul>
                {professionalDevelopment.map((experience, index) => (
                  <li key={index}>{experience}</li>
                ))}
              </ul>
            </>
          )}
          {supervision[0] && (
            <>
              <h4>
                Опонування дисертаційних робіт на здобуття наукового ступеня кандидата
                наук:
              </h4>
              <ul>
                {supervision.map((student, index) => (
                  <li key={index}>{student}</li>
                ))}
              </ul>
            </>
          )}
          {certification.cerfInfo[0] && (
            <>
              <ClickableH4 onClick={() => setCloseCerf(!closeCerf)} close={closeCerf}>
                Інформація про підвищення кваліфікації{' '}
                <FontAwesomeChevron className="fa fa-chevron-down" />
              </ClickableH4>
              <CerfInfoWrapper>
                <ol>
                  {certification.cerfInfo.map((cerf, index) => (
                    <li key={index}>{cerf}</li>
                  ))}
                </ol>
                <ImageGallery
                  items={certification.certificates}
                  showPlayButton={false}
                  showFullscreenButton={false}
                />
              </CerfInfoWrapper>
            </>
          )}
        </AdditionalInfo>
      </ProfileContainer>
      <GoBackBottom onClick={goBack}>
        <i className={'fa fa-arrow-left'} /> Повернутися на попередню сторінку
      </GoBackBottom>
    </>
  );
};
export default TeacherProfile;
