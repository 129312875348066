import MapAnnouncementsShort from '../../components/map-announcements-short/map-announcements-short.component';

const Announcements = () => {
  return (
    <>
      <h2>Всі новини</h2>
      <MapAnnouncementsShort />
    </>
  );
};
export default Announcements;
