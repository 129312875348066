import styled from "styled-components";
export const Card = styled.div`
  margin: 10px auto 30px;
  max-width: 600px;
  background: var(--gradient-secondary);
  padding: 20px 5px 10px;
  border-radius: 1rem;
  box-shadow: -15px 15px 15px -15px black, inset 0 0 14px -7px black;

  h3 {
    font-size: 1.4rem;
    &:first-child {
      text-shadow: 0 0 3px #fff;
    }
  }
  h4 {
    font-size: 1.2rem;
  }
  img {
    width: 80%;
    border-radius: 0.5rem;
    max-width: 400px;
    &:first-child {
      margin-top: 30px;
    }
  }
`;
