import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Address = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  background: var(--gradient-primary-reversed);
  font-family: 'Kurale', serif;

  a {
    color: #fff;
    padding: 10px 0px;
  }
`;
export const WelcomeLink = styled(Link)`
  @media screen and (max-width: 550px) {
    display: none;
  }
`;
