const KyzminskyA = () => (
  <>
    <h2>
      Наукова школа доктора педагогічних наук, професора, члена-кореспондента Національної
      академії педагогічних наук України Кузьмінського Анатолія Івановича{' '}
    </h2>

    <img
      src="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Fschools%2Fkuzminsky-a.jpg?alt=media&token=e27174ea-da70-4777-93e8-555819e25881"
      alt="Кузмінський"
    />

    <p>
      <span>
        Доктор педагогічних наук, професор, член-кореспондент Національної академії
        педагогічних наук України, професор кафедри педагогіки та менеджменту освіти
        Глухівського національного педагогічного університету імені Олександра Довженка,
        дійсний член Міжнародної слов'янської академії освіти імені Я. А. Коменського,
        заслужений працівник народної освіти України.
      </span>
    </p>
    <p>
      <span>
        Нагороджений Почесною відзнакою Президента України, орденом Дружби народів,
        орденом «За заслуги» ІІІ ступеня, орденом «За заслуги» ІІ ступеня, медаллю «За
        трудову доблесть», медаллю «Незалежність України», знаком «Відмінник освіти
        України», Орденом рівноапостольного князя Володимира, Орденом святого Дмитра
        Солунського.
      </span>
    </p>
    <p>
      <span>
        У 1997 році Анатолій Іванович захистив дисертацію «Організаційно-педагогічні
        основи безперервної освіти педагогічних кадрів» на здобуття наукового ступеня
        кандидата педагогічних наук в Інституті педагогіки АПН України, а у 2003 році -
        доктора педагогічних наук з теми «Теоретико-методологічні засади післядипломної
        педагогічної освіти в Україні» в Інституті педагогіки і психології професійної
        освіти АПН України).
      </span>
    </p>
    <p>
      <span>
        Наукова школа Анатолія Івановича Кузьмінського склалась наприкінці ХХ ст. Наукову
        школу затверджено рішенням вченої ради від 2<span>6</span> грудня 201
        <span>9</span> року, протокол № <span>5.</span> Коло її наукових інтересів:
        проблеми підготовки фахівців і виховання молоді у вищому навчальному закладі
        освітньої системи України в умовах євроінтеграції; проблеми змісту педагогічної
        освіти у вищих навчальних закладах, зокрема науково-теоретичні засади організації
        вивчення педагогіки як навчальної дисципліни у класичних та педагогічних
        університетах; проблеми післядипломної педагогічної освіти, зокрема
        теоретико-методологічні засади післядипломної педагогічної освіти в Україні;
        світові тенденції її розвитку та становлення в період державної незалежності,
        перспективи її розвитку; проблеми секундарної освіти, зокрема сільської
        малокомплектної школи.
      </span>
    </p>
    <p>
      <span>
        За результатами досліджень захищаються кандидатські та докторські дисертації. Під
        керівництвом члена-кореспондента НАПН України, доктора педагогічних наук,
        професора Кузьмінського А. І. активно захищаються докторські дисертації, зокрема:
        Савченко О. П. (2013), Вовк О. І (2014), Гнезділова К. М. (2014), Луценко Г. В.
        (2014), Атаманчук Ю. М. (2016), Кучай О. В. (2016), Бондар Т.І. (2019), та
        кандидатські дисертації, зокрема: Гасюк Л. М. (2004), Жижко Т. А. (2004), Лещенко
        І. Т. (2008), Береза В. О. (2010), Герасимова І. В. (2010), Задворна С. (2013),
        Бондарук Я. В. (2014), Гордійчук А. В. (2014), Біда А. П. (2014) тощо. Успішно
        проводять наукові дослідження докторанти Гречаник Н.І. та Тюльпа Т.М. Наслідувачі
        наукових ідей Анатолія Івановича постійно публікують свої наукові та методичні
        праці у фахових збірниках з актуальних педагогічних проблем, беруть участь у
        наукових конференціях, семінарах, анотують наукові праці, готують відгуки про
        дисертації та автореферати.
      </span>
    </p>
    <p>
      <span>
        За результатами досліджень науковцем опубліковано понад 400 праць, у тому числі
        монографії, підручники, навчально-методичні посібники з грифом Міністерства освіти
        і науки України, статті, інтерв'ю, спогади, зокрема: «Педагогіка» (2003),
        «Педагогіка: Завдання і ситуації» (2003), «Педагогіка: Хрестоматія» (2003),
        «Народна педагогіка: Світовий досвід» (2003), «Вступ до університетських студій»
        (2004), «Педагогіка в запитаннях і відповідях» (2006), «Педагогіка родинного
        виховання» (2006), «Теорія і методика виховання» (2008), «Педагогічна мудрість
        віків» (2009), «Педагогіка вищої школи» (2011), «Становлення професіоналізму
        педагога вищої школи (у бесідах і роздумах)» (2013), «Педагогічна мудрість»
        (2013), «Менеджмент української освіти в контексті євроінтеграції» (2017),
        Інноваційні процеси в освітньому просторі закладу вищої освіти (2019).
      </span>
    </p>
    <p>
      <span>
        На базі наукової школи щороку проводяться міжнародні тавсеукраїнські
        науково-практичні конференції. Очільник наукової школи активно запроваджує в
        навчально-виховний процес університету зарубіжний досвід, набутий під час роботи у
        Європі за міжнародною програмою грантів «Tempus Tasis».
      </span>
    </p>
    <p>
      <strong>
        <span>Основні напрями роботи наукової школи:</span>
      </strong>
    </p>
    <p>
      <span>
        – актуальні проблеми історії становлення української школи і розвитку вітчизняної
        освіти;
      </span>
    </p>
    <p>
      <span>
        – проблеми підготовки фахівців і виховання молоді у вищому навчальному закладі
        системи освіти України в умовах європейської інтеграції;
      </span>
    </p>
    <p>
      <span>
        – дослідження організаційно-педагогічних засад модернізації навчально-виховного
        процесу в закладах вищої освіти;
      </span>
    </p>
    <p>
      <span>
        – теоретико-методологічні засади післядипломної педагогічної освіти в Україні.
      </span>
    </p>
    <p>
      <span>
        Про наукове визнання наукової школи професора Кузьмінського А. І. свідчать
        фундаментальні дослідження (держбюджетні теми) школи, виконані на замовлення
        Міністерства освіти і науки України: «Теоретико-методологічні засади забезпечення
        навчально-виховного процесу в сільській малокомплектній школі» (номер державної
        реєстрації: 0102U007103), 2003–2005 рр., «Педагогічна діагностика рівня навчальних
        досягнень студентів у вищому навчальному закладі» (номер державної реєстрації:
        0106 U 004023), 2006–2008 рр., «Моделювання професійної діяльності майбутнього
        фахівця в умовах інтеграції України в європейський освітній простір» (номер
        державної реєстрації: 0109U002550), 2009–2011 рр. Результати науково-дослідної
        роботи апробуються на всеукраїнських та міжнародних конференціях (доповіді і
        опубліковані тези), а також знаходять відображення у публікаціях штатних та
        позаштатних виконавців тем.
      </span>
    </p>
    <p>
      <strong>
        <span>
          У ході дослідження організаційно-педагогічних засад модернізації
          навчально-виховного процесу в закладах вищої освіти було розроблено:
        </span>
      </strong>
    </p>
    <p>
      <span>
        – концепцію інноваційної моделі навчання у вищій школі, що охоплює всі компоненти
        педагогічної системи (цілі, зміст, форми, методи, засоби навчання), технології
        навчання в університеті, серед яких є і зовнішньо орієнтовані (спрямовані на
        результати навчання), і студентоорієнтовані (спрямовані на особистісні потреби й
        можливості студента);
      </span>
    </p>
    <p>
      <span>– концептуальні засади підготовки працівників вищої освіти;</span>
    </p>
    <p>
      <span>
        – науково-методичні рекомендації щодо створення сучасних підручників,
        інтерактивних курсів, електронних дидактичних комплексів тощо;
      </span>
    </p>
    <p>
      <span>
        – механізми запровадження в систему вищої освіти розвивальних технологій
        професійної освіти та технологій саморегульованого навчання в рамках традиційного
        навчання, зокрема: когнітивно орієнтованих технологій (діалогічні методи навчання,
        семінари, дискусії, проблемне навчання, когнітивне консультування, когнітивні
        карти, інструментально-логічний тренінг, тренінг рефлексії тощо); діяльнісно
        орієнтованих технологій (методи проектів і направляючих тестів, контекстне
        навчання, організаційно-діяльнісні ігри, комплексні дидактичні завдання,
        технологічні карти, імітаційно-ігрове моделювання технологічних процесів тощо).
      </span>
    </p>
    <p>
      <span>
        особистісно орієнтованих технологій (інтерактивні та імітаційні ігри, тренінги
        розвитку, розвивальна психодіагностика тощо).
      </span>
    </p>
    <p>
      <span>
        Творча група, яку очолює професор Кузьмінський А. І., налагодила і підтримує міцні
        контакти із основними науковими та навчальними закладами України (Академія
        педагогічних наук України, Інститут проблем виховання АПН України, Інститут
        педагогічної освіти і освіти дорослих АПН України, Київський національний
        університет імені Т. Г. Шевченка, Національний педагогічний університет імені М.
        П. Драгоманова, ДВНЗ «Переяслав-Хмельницький державний педагогічний університет
        імені Г. Сковороди», Ізмаїльський державний гуманітарний університет, Черкаський
        обласний інститут післядипломної освіти педагогічної працівників) і зарубіжними
        науковими інституціями, зокрема з Вищою школою педагогічних умінь та управління в
        Риках (Польща), Вроцлавським університетом (Польща), Придністровським державним
        університетом імені Т. Г. Шевченка (Молдова), Міжнародною слов’янською академією
        освіти імені Я. А. Коменського, Національним інститутом освіти (Мінськ),
        Гродненським державним університетом імені Янки Купали (Білорусь), Мінським
        державним педагогічним університетом (Мінськ), Карагандинським інститутом
        актуальної освіти «Болашак» (Казахстан), Російською академією освіти (Москва),
        Російським державним педагогічним університетом імені А. Герцена
        (Санкт-Петербург), Тверським державним університетом (Росія) та іншими закладами
        вищої освіти зарубіжжя.
      </span>
    </p>
  </>
);

export default KyzminskyA;
