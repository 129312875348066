import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { H4 } from './department.styles';

import {
  DirectorySection as DepartmentSection,
  Teacher
} from '../../pages/about/administration-directory/directory.styles';
import noPhoto from '../../assets/abstract-user.png';

import { TeacherFullProfile } from '../teacher-profile/teacher-profile.component';

type DepartmentProps = {
  departmentPersonnel: TeacherFullProfile[];
  title: string;
};
const Department: FC<DepartmentProps> = ({ departmentPersonnel, title }) => {
  const navigate = useNavigate();
  return (
    <>
      <h2>{title}</h2>
      <DepartmentSection>
        {departmentPersonnel.map((professor, index) => {
          const {
            name,
            partTime,
            jobTitle,
            qualification,
            imageUrl,
            contacts: { email },
            linkTo
          } = professor;
          return (
            <Teacher key={index} hasMoreInfo={Boolean(linkTo)}>
              <h3 onClick={() => linkTo && navigate(linkTo)}>
                {name}
                {partTime && (
                  <>
                    <br />
                    <span>(сумісник)</span>
                  </>
                )}
              </h3>
              <img
                src={imageUrl || noPhoto}
                alt={name}
                onClick={() => linkTo && navigate(linkTo)}
              />
              {jobTitle && <h3>{jobTitle}</h3>}
              {qualification && <H4>{qualification}</H4>}
              {email && (
                <address>
                  <H4>Контакти:</H4>
                  ел.пошта:&nbsp;<a href={`mailto:${email}`}>{email}</a>
                </address>
              )}

              {linkTo && (
                <Link to={linkTo}>
                  Докладніше <i className="fa fa-link" />
                </Link>
              )}
            </Teacher>
          );
        })}
      </DepartmentSection>
    </>
  );
};

export default Department;
