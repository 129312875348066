import ExternalLink from '../../../components/UI/external-link/external-link.component';

import syllabusList from '../../../data/syllabusList.json';

import { ItemListWithBG, ListWithBG } from '../../education/programs/programs.styles';

const SyllabusPostgraduate = () => (
  <>
    <h2>Силабуси</h2>
    {syllabusList.map(({ year, syllabus }, index) => (
      <div key={index}>
        <h3>{year} рік</h3>

        <ListWithBG key={index}>
          {syllabus.map(({ name, link }, index) => (
            <ItemListWithBG key={index}>
              {name}.
              <ExternalLink href={link} />
            </ItemListWithBG>
          ))}
        </ListWithBG>
      </div>
    ))}
  </>
);

export default SyllabusPostgraduate;
