import HiddenPDF from '../../../components/hidden-pdf/hidden-pdf.component';

import societyPersonnel from '../../../data/science-society-personnel.json';

import { Table, TableContainer } from './society.styles';
import {
  ItemListWithBG,
  OrderedListWithBG,
  ParagraphWithBG
} from '../../about/administration-stakeholders/stakeholders.styles';

const Society = () => {
  return (
    <>
      <h2>
        Наукове товариство студентів, аспірантів, докторантів і молодих вчених ННІ
        Педагогіки та психології
      </h2>
      <section>
        <OrderedListWithBG>
          <h3>Список членів Ради</h3>
          {societyPersonnel.management.map(
            ({ name, qualification, tel, eMail }, index) => (
              <ItemListWithBG key={index}>
                {`${qualification} - ${name}, тел.: ${tel}, e-mail: ${eMail}`}
              </ItemListWithBG>
            )
          )}
          {societyPersonnel.members.map(({ name }, index) => (
            <ItemListWithBG key={index}>{name}</ItemListWithBG>
          ))}
          <ParagraphWithBG>
            Список членів Ради Наукового товариства студентів, аспірантів, докторантів і
            молодих вчених ННІ Педагогіки та психології схвалено на засіданні Вченої ради
            ННІ Педагогіки та психології протокол № 8 від 24.10.2022 р.
          </ParagraphWithBG>
        </OrderedListWithBG>
      </section>
      <TableContainer>
        <h3>План роботи Наукового товариства</h3>
        <Table>
          <thead>
            <tr>
              <th>№ п/п</th>
              <th>Зміст роботи</th>
              <th>Термін виконання</th>
              <th>Відповідальні</th>
            </tr>
          </thead>
          <tbody>
            {societyPersonnel.plan.map(
              ({ workContent, deadline, responsible }, index) => (
                <tr key={index}>
                  <th>{index + 1}</th>
                  <td>{workContent}</td>
                  <td>{deadline}</td>
                  <td> {responsible}</td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </TableContainer>
      <section>
        <HiddenPDF
          pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/science%2Fsociety%2F%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%BD%D1%8F_%D0%9D%D0%A2%D0%9C%D0%A3.pdf?alt=media&token=93b4097a-5295-44b9-ae9c-5cbd6767ba76"
          btnText="Положення про Наукове товариство"
        />
      </section>
    </>
  );
};
export default Society;
