import PDFViewer from '../../../components/pdf-viewer/pdf-viewer.component';

const EducationalSchedule = () => (
  <>
    <PDFViewer
      title="Графік освітнього процесу"
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/education%2Feducation-schedule%2Feducational-schedule-22-23.pdf?alt=media&token=1a8c6bc5-fbbf-45c0-a146-9597a6509b0e"
    />
    <PDFViewer pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/education%2Fnon-citizen%2F%D0%93%D1%80%D0%B0%D1%84%D1%96%D0%BA%20%D0%9E%D0%9F_%D1%96%D0%BD%D0%BE%D0%B7%D0%B5%D0%BC%D1%86%D1%96%20(1).pdf?alt=media&token=817a41a6-42cc-4f7f-a5c8-b9e293f9a298" />
  </>
);
export default EducationalSchedule;
