import { ReactComponent as GoogleScholar } from '../../assets/google-scholar.svg';
import { ReactComponent as OrcID } from '../../assets/orcid-logo.svg';
import { ReactComponent as WoSR } from '../../assets/CLVT.svg';
import { ReactComponent as Scopus } from '../../assets/scopus.svg';
import { ReactComponent as RGate } from '../../assets/ResearchGate.svg';
import { ReactComponent as Publons } from '../../assets/PUBLONS.svg';

import { LogoContainer, SocialWrapper } from './social-science.styles';

export type SocialScienceProps = {
  social: {
    googleAcademy?: string;
    orcid?: string;
    WoSRID?: string;
    publons?: string;
    rGate?: string;
    scopusID?: string;
    bibliometrics?: string;
    scienceUkr?: string;
  };
};
const SocialScience = ({ social }: SocialScienceProps) => {
  const { googleAcademy, orcid, WoSRID, scopusID, publons, rGate } = social;
  return (
    <SocialWrapper>
      {googleAcademy && (
        <LogoContainer href={googleAcademy} rel="noreferrer noopener" target={'_blank'}>
          <GoogleScholar />
          Google академія
        </LogoContainer>
      )}
      {orcid && (
        <LogoContainer href={orcid} rel="noreferrer noopener" target={'_blank'}>
          <OrcID />
          ORC<span style={{ color: 'var(--color-primary)' }}>ID</span>
        </LogoContainer>
      )}
      {WoSRID && (
        <LogoContainer href={WoSRID} rel="noreferrer noopener" target={'_blank'}>
          <WoSR />
          Web of Science™
        </LogoContainer>
      )}
      {scopusID && (
        <LogoContainer href={scopusID} rel="noreferrer noopener" target={'_blank'}>
          <Scopus />
          Scopus
        </LogoContainer>
      )}
      {publons && (
        <LogoContainer href={publons} rel="noreferrer noopener" target={'_blank'}>
          <Publons />
          Publons
        </LogoContainer>
      )}
      {rGate && (
        <LogoContainer href={rGate} rel="noreferrer noopener" target={'_blank'}>
          <RGate />
          Research Gatе
        </LogoContainer>
      )}
    </SocialWrapper>
  );
};
export default SocialScience;
