import { CenteredCell, Table } from './selective.styles';
import ExternalLink from '../../../components/UI/external-link/external-link.component';

const TableSpecialMaster = () => (
  <Table>
    <thead>
      <tr>
        <th>Освітньо-професійна програма (повна назва)</th>
        <th>Вибірковий освітній компонент (повна назва)</th>
        <th>Обсяг кредитів</th>
        <th>Силабуси</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td rowSpan={7}>ОП Початкова освіта</td>
        <td>Інноваційні технології в початковій освіті</td>
        <CenteredCell>4</CenteredCell>
        <CenteredCell rowSpan={7}>
          <ExternalLink
            href="https://drive.google.com/drive/folders/1U0raMZa8ZSMA5nsQNvqGl5IjkIQrNTdv?usp=share_link"
            text=""
          />
        </CenteredCell>
      </tr>
      <tr>
        <td>
          Технології формування професійної компетентності майбутнього вчителя початкових
          класів
        </td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Комунікативна компетентність викладача</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Формування професійно-комунікативної компетентності викладача ЗВО</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Теоретико-методологічні основи початкової освіти</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Теоретико-методологічні засади концепції НУШ в початковій освіті</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Акмеологічна педагогіка</td>
        <CenteredCell>3</CenteredCell>
      </tr>

      <tr>
        <td rowSpan={6}>ОП Управління закладом освіти</td>
        <td>Управління інформаційними зв’язками</td>
        <CenteredCell>5</CenteredCell>
        <CenteredCell rowSpan={6}>
          <ExternalLink
            href="https://drive.google.com/drive/folders/1qhgLO8gXWvqZ9RjVs_9zSSnACTfyILzu?usp=share_link"
            text=""
          />{' '}
        </CenteredCell>
      </tr>
      <tr>
        <td>Сучасні технології інформації</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Управління якістю освітніх послуг у закладі вищої освіти</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Інформаційно-комунікаційний менеджмент</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Правові аспекти управління закладом освіти</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Провайдинг інновацій в освіті</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td rowSpan={6}>
          ОП Менеджмент у сфері охорони здоров'я в галузі знань &quot;Управління та
          адміністрування&quot;
        </td>
        <td>Управління інформаційними зв’язками</td>
        <CenteredCell>5</CenteredCell>
        <CenteredCell rowSpan={6}>
          <ExternalLink
            href="https://drive.google.com/drive/folders/1WC3W00CG-3BhGxrXKG_ym6Ajp_otO78I?usp=share_link"
            text=""
          />{' '}
        </CenteredCell>
      </tr>
      <tr>
        <td>Сучасні технології інформації</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Управління проєктами в громадських і неприбуткових організаціях</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Комунікаційний менеджмент</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Інформаційно - комунікаційний менеджмент</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Правові аспекти управління закладом охорони здоров'я</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td rowSpan={8}>ОП Соціальна робота</td>
        <td>Основи віктимології</td>
        <CenteredCell>4</CenteredCell>
        <CenteredCell rowSpan={8}>
          <ExternalLink
            href="https://drive.google.com/drive/folders/1nXdFPe-QqbxMsg-phiqstsFJLGCmSfJc?usp=share_link"
            text=""
          />
        </CenteredCell>
      </tr>
      <tr>
        <td>Формування соціальної компетентності особистості</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Соціальна робота з людьми в ситуаціях життєвої кризи</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Моделювання соціальних програм і проєктів розвитку громад</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Проектування соціальних технологій у діяльності соціального працівника</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Соціально-педагогічна профілактика правопорушень</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Кризове втручання як теорія і практика соціальної роботи.</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Технологія супервізії в соціальній роботі</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td rowSpan={9}>ОП Психологія</td>
        <td>Психологія педагогічного спілкування</td>
        <CenteredCell>5</CenteredCell>
        <CenteredCell rowSpan={9}>
          <ExternalLink
            href="https://drive.google.com/drive/folders/1GWH4_MdnPefkf7jCWK-zVyM0vnFwJxB4?usp=sharing"
            text=""
          />
        </CenteredCell>
      </tr>
      <tr>
        <td>Психологія особистості</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Психологія переживання життєвих криз</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Психологія сім’ї та сімейне консультування</td>
        <CenteredCell>4</CenteredCell>
      </tr>
      <tr>
        <td>Психологія розвитку</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Психологія використання Інтернету в освітньому процесі</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Психологія етнічних середовищ</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Психологія гендеру</td>
        <CenteredCell>5</CenteredCell>
      </tr>
      <tr>
        <td>Психологічна безпека особистості</td>
        <CenteredCell>5</CenteredCell>
      </tr>
    </tbody>
  </Table>
);
export default TableSpecialMaster;
