import styled from "styled-components";

export const UnorderedList = styled.ul`
  margin: auto;
  max-width: 90%;
`;
export const UlItems = styled.li`
  font-weight: bold;
  margin-bottom: 1em !important;
`;
