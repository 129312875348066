// it exports as `createDraftSafeSelector` from redux-toolkit ()
import { createSelector } from '@reduxjs/toolkit';
import { AnnouncementsState } from './announcements.slice';
import { RootState } from '../store';

const selectAnnouncementsReducer = (state: RootState): AnnouncementsState =>
  state.announcements;

export const selectNews = createSelector(
  [selectAnnouncementsReducer],
  (announcements) => announcements.news
);

export const selectAnnouncementsIsLoading = createSelector(
  [selectAnnouncementsReducer],
  (announcements) => announcements.isLoading
);
