import styled from 'styled-components';

export const AboutContainer = styled.section`
  img {
    margin: 0;
    float: left;
    height: auto;
    width: 100%;
    @media screen and (min-width: 768px) {
      margin: 5px;
      width: auto;
      max-width: 50%;
    }
  }
`;
