import { Link, useNavigate } from 'react-router-dom';

import directoryPersonnel from '../../../data/directory-personnel-list.json';

import { DirectorySection, Teacher } from './directory.styles';

const Directory = () => {
  const navigate = useNavigate();
  return (
    <>
      <DirectorySection>
        <h2>ДИРЕКЦІЯ ІНСТИТУТУ</h2>
        {directoryPersonnel.map((teacher, index) => {
          const {
            name,
            jobTitle,
            qualification,
            imageUrl,
            contacts: { tel, email },
            linkToMore
          } = teacher;

          return (
            <Teacher key={index} hasMoreInfo={Boolean(linkToMore)}>
              <h3 onClick={() => linkToMore && navigate(linkToMore)}>{jobTitle}</h3>
              <img
                src={imageUrl}
                alt={name}
                onClick={() => linkToMore && navigate(linkToMore)}
              />
              <h3>{name}</h3>
              {qualification && <h4>{qualification}</h4>}
              <address>
                Контакти: тел.&nbsp;<a href={`tel:${tel}`}>{tel}</a>, ел.пошта:&nbsp;
                <a href={`mailto:${email}`}>{email}</a>
              </address>
              {linkToMore && (
                <Link to={linkToMore}>
                  Докладніше <i className="fa fa-arrow-right" />
                </Link>
              )}
            </Teacher>
          );
        })}
      </DirectorySection>
      <DirectorySection>
        <h3>Графік роботи дирекції інституту:</h3>
        <p>Понеділок – четвер з 8:00 до 17:15 год.</p>
        <p>П'ятниця з 8:00 до 16:00 год.</p>
        <p>Обідня перерва: з 12:00 до 13:00 год.</p>
        <p>Видача довідок: четвер з 14:00 год.</p>
        <p>
          Прийом громадян директором з особистих питань з 10:00 до 12:00 год щочетверга.
        </p>
        <p>Старостат проводиться щовівторка з 13:00 год (аудиторія №6).</p>
        <p>Збори студентського самоврядування інституту – кожен третій четвер місяця.</p>
      </DirectorySection>
    </>
  );
};
export default Directory;
