import HiddenPDF from '../../../components/hidden-pdf/hidden-pdf.component';
import ExternalLink from '../../../components/UI/external-link/external-link.component';

import syllabus from '../../../data/postgraduate-selective-syllabus.json';

import { ItemListWithBG, ListWithBG } from '../../education/programs/programs.styles';
const SelectivePostgraduate = () => (
  <>
    <h2>Каталог вибіркових дисциплін</h2>
    <ul>
      <li>
        <HiddenPDF
          btnText="Каталог вибіркових освітніх компонентів, 2022."
          pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/postgraduate%2Fselective%2F%D0%9A%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3%20%D0%B2%D0%B8%D0%B1%D1%96%D1%80%D0%BA%D0%BE%D0%B2%D0%B8%D1%85%20%D0%BE%D1%81%D0%B2%D1%96%D1%82%D0%BD%D1%96%D1%85%20%D0%BA%D0%BE%D0%BC%D0%BF%D0%BE%D0%BD%D0%B5%D0%BD%D1%82%D1%96%D0%B2%202022.pdf?alt=media&token=be3db76f-027c-450b-9912-016d793be424"
        />
        <ListWithBG>
          <h3>Силабуси вибіркових освітніх компонентів 2022:</h3>
          {syllabus.map(({ name, link }, index) => (
            <ItemListWithBG key={index}>
              {name}.
              <ExternalLink href={link} />
            </ItemListWithBG>
          ))}
        </ListWithBG>
      </li>
      <li>
        <h4>
          Каталог вибіркових освітніх компонентів, 2021.
          <ExternalLink href="https://drive.google.com/file/d/1OUcqlTUT0Ppz-s_ihzVYNc-i-4MhOX9f/view" />
        </h4>
      </li>
    </ul>
  </>
);

export default SelectivePostgraduate;
