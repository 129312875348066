import PDFViewer from '../../../components/pdf-viewer/pdf-viewer.component';

const Specialties = () => (
  <>
    <h2>Спеціальності інституту</h2>
    <PDFViewer pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/entrants%2Fspecialties%2F%D0%A1%D0%BF%D0%B5%D1%86%D1%96%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%96%20%D1%96%D0%BD%D1%81%D1%82%D0%B8%D1%82%D1%83%D1%82%D1%83.pdf?alt=media&token=97103166-3aaf-4340-b38c-638728e62848" />
  </>
);

export default Specialties;
