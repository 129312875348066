import MapAnnouncementsShort from "../../../components/map-announcements-short/map-announcements-short.component";

const EventsPostgraduate = () => (
  <>
    <h2>Події та анонси</h2>
    <MapAnnouncementsShort annoType="postgraduate" />
  </>
);

export default EventsPostgraduate;
