import Button, { BUTTON_TYPE_CLASSES } from '../../components/UI/button/button.component';

import { resetAnnouncements } from '../../utils/hidden-features/reset-announcements';
import { useAppDispatch } from '../../store/store';
import { toggleAuth } from '../../store/navigation/navigation.slice';

const Management = () => {
  const dispatch = useAppDispatch();
  const revealAuth = () => dispatch(toggleAuth());

  return (
    <>
      <Button
        buttonType={BUTTON_TYPE_CLASSES.inverted}
        type="button"
        onClick={resetAnnouncements}
      >
        Оновити список новин
      </Button>
      <Button
        buttonType={BUTTON_TYPE_CLASSES.inverted}
        type="button"
        onClick={revealAuth}
      >
        Показати кнопку авторизації
      </Button>
    </>
  );
};
export default Management;
