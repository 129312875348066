import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDatabaseDocList } from '../../utils/firebase/firebase.utils';
import { AppThunk } from '../root-reducer';
import { NewsItem } from '../../data/announcements-list';

export type AnnouncementsState = {
  news: NewsItem[];
  isLoading: boolean;
  error: Error | null;
};

const initialState: AnnouncementsState = {
  news: [],
  isLoading: true,
  error: null
};

export const announcementsSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {
    fetchNewsStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchNewsSuccess: (state, action: PayloadAction<NewsItem[]>) => {
      state.isLoading = false;
      state.news = action.payload;
    },
    fetchNewsFailed: (state, action: PayloadAction<Error>) => {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

const { fetchNewsStart, fetchNewsSuccess, fetchNewsFailed } = announcementsSlice.actions;

export const fetchNews = (): AppThunk => async (dispatch) => {
  dispatch(fetchNewsStart());
  try {
    const newsFromFirebase = await getDatabaseDocList('announcements', 'news', 'items');
    dispatch(fetchNewsSuccess(newsFromFirebase));
  } catch (e) {
    dispatch(fetchNewsFailed(e as Error));
  }
};

export default announcementsSlice.reducer;
