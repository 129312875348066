import { createSlice } from '@reduxjs/toolkit';

export type NavigationState = {
  readonly isMenuOpen: boolean;
  readonly isAuthHidden: boolean;
};

const initialState: NavigationState = {
  isMenuOpen: false,
  isAuthHidden: false
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.isMenuOpen = !state.isMenuOpen;
    },
    toggleAuth: (state) => {
      state.isAuthHidden = !state.isAuthHidden;
    }
  }
});

export const { toggleMenu, toggleAuth } = navigationSlice.actions;
export default navigationSlice.reducer;
