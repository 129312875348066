import HiddenPDF from '../../../components/hidden-pdf/hidden-pdf.component';

import stakeholders from '../../../data/stakeholders.json';

import {
  ItemListWithBG,
  OrderedListWithBG,
  ParagraphWithBG
} from './stakeholders.styles';

const Stakeholders = () => (
  <>
    <h2>Рада стейкхолдерів</h2>
    <OrderedListWithBG>
      <h3>Склад ради стейкхолдерів ННІ Педагогіки і психології</h3>
      {stakeholders.map(({ name, qualification, tel, eMail }, index) => (
        <ItemListWithBG key={index}>
          <b>{name}</b> - {`${qualification}, тел.: ${tel}, e-mail: ${eMail}`}
        </ItemListWithBG>
      ))}
    </OrderedListWithBG>
    <ParagraphWithBG>
      Склад ради стейкхолдерів ННІ Педагогіки і психології, затверджено вченою радою ННІ
      Педагогіки і психології, протокол № 1 від 25.01.2023
    </ParagraphWithBG>
    <HiddenPDF
      pdfUrl="https://firebasestorage.googleapis.com/v0/b/nni-pp-gnpu.appspot.com/o/about%2Fadministration%2Fstakeholders%2Fprovision-with-stakeholders.pdf?alt=media&token=9f071d94-feca-42ae-ac33-afbbbfbb93be"
      btnText="ПОЛОЖЕННЯ про співпрацю Глухівського  національного педагогічного університету
 імені Олександра Довженка із стейкхолдерами"
    />
  </>
);
export default Stakeholders;
