import { Route, Routes } from "react-router-dom";
import Announcements from "../pages/announcements/announcements.component";
import Page404 from "../components/page404/page404.component";
import Announcement from "../components/announcement/announcement.component";

const AnnouncementsRoutes = () => {
  return (
    <Routes>
      <Route index element={<Announcements />} />
      <Route path=":id" element={<Announcement />} />
      <Route path="/*" element={<Page404 />} />
    </Routes>
  );
};
export default AnnouncementsRoutes;
