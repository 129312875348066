import styled, { css } from 'styled-components';

const closedSScreen = css`
  transform: translateX(-300px);
`;
const closedLScreen = css`
  transform: translateX(-400px);
`;

type DrawerContainerProps = {
  closed: boolean;
};
export const DrawerContainer = styled.nav<DrawerContainerProps>`
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 80%;
  max-width: 300px;
  padding: 20px 10px;
  box-sizing: border-box;
  transform: translateX(0px);
  transition: transform 0.6s cubic-bezier(0.22, 0.61, 0.36, 1);
  z-index: 1002;
  background: var(--gradient-primary);
  overflow: auto;
  box-shadow: 15px 0 15px -15px rgba(0, 0, 0, 0.8);
  font-family: 'Playfair Display', serif;

  ${({ closed }) => closed && closedSScreen}

  & > div > a {
    font-size: 1.2rem;
  }
  @media screen and (min-width: 1232px) {
    max-width: 400px;
    
    ${({ closed }) => closed && closedLScreen}
  }
  }
`;
