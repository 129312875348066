import ExternalLink from '../../../components/UI/external-link/external-link.component';
import { CenteredCell, Table } from './selective.styles';
import { FC } from 'react';

type Syllabus = {
  id: number;
  name: string;
  size: number;
  linkTo: string;
};
type SyllabusProps = { syllabuses: Syllabus[] };
const SelectiveTableMap: FC<SyllabusProps> = ({ syllabuses }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Вибірковий освітній компонент (повна назва)</th>
          <th>Обсяг кредитів</th>
          <th>Силабус</th>
        </tr>
      </thead>
      <tbody>
        {syllabuses.map(({ id, name, size, linkTo }) => (
          <tr key={id}>
            <td>{name}</td>
            <CenteredCell>{size}</CenteredCell>
            <CenteredCell>
              {linkTo && <ExternalLink href={linkTo} text="" />}
            </CenteredCell>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
export default SelectiveTableMap;
