import { Route, Routes } from 'react-router-dom';

import AboutFaculty from '../pages/about/about-faculty.component';
import Department from '../components/department/department.component';
import TeacherProfile from '../components/teacher-profile/teacher-profile.component';
import Page404 from '../components/page404/page404.component';
import Directory from '../pages/about/administration-directory/directory.component';
import AcademicCouncil from '../pages/about/administration-academic-council/academic-council.component';
import Stakeholders from '../pages/about/administration-stakeholders/stakeholders.component';
import LocalGovernment from '../pages/about/administration-local-government/local-government.component';
import Regulations from '../pages/about/regulations/regulations.component';
import OwnHistory from '../pages/about/own-history/own-history.component';
import MaterialBase from '../pages/about/material-base/material-base.component';
import Contacts from '../pages/about/contacts/contacts.component';

import directorProfile from '../data/director-profile.json';
import personnelKppsrm from '../data/personnel-kppsrm.json';
import personnelKtmpo from '../data/personnel-ktmpo.json';
import personnelKpppo from '../data/personnel-kpppo.json';

const About = () => (
  <Routes>
    <Route index element={<AboutFaculty />} />
    <Route path="administration/directory" element={<Directory />} />
    <Route
      path="administration/directory/:id"
      element={<TeacherProfile departmentPersonnel={directorProfile} />}
    />
    <Route path="administration/academic-council" element={<AcademicCouncil />} />
    <Route path="administration/stakeholders" element={<Stakeholders />} />
    <Route path="administration/local-government" element={<LocalGovernment />} />
    <Route path="regulations" element={<Regulations />} />
    <Route
      path="departments/ktmpo"
      element={
        <Department
          title="Кафедра теорії і методики початкової освіти"
          departmentPersonnel={personnelKtmpo}
        />
      }
    />
    <Route
      path="departments/ktmpo/:id"
      element={<TeacherProfile departmentPersonnel={personnelKtmpo} />}
    />

    <Route
      path="departments/kpppo"
      element={
        <Department
          title="Кафедра педагогіки і психології початкової освіти"
          departmentPersonnel={personnelKpppo}
        />
      }
    />
    <Route
      path="departments/kpppo/:id"
      element={<TeacherProfile departmentPersonnel={personnelKpppo} />}
    />
    <Route
      path="departments/kppsrm"
      element={
        <Department
          title="Кафедра педагогіки, психології, соціальної роботи та менеджменту"
          departmentPersonnel={personnelKppsrm}
        />
      }
    />
    <Route
      path="departments/kppsrm/:id"
      element={<TeacherProfile departmentPersonnel={personnelKppsrm} />}
    />
    <Route path="own-history" element={<OwnHistory />} />
    <Route path="material-base" element={<MaterialBase />} />
    <Route path="contacts" element={<Contacts />} />

    <Route path="/*" element={<Page404 />} />
  </Routes>
);

export default About;
