import { FC } from 'react';

import { Card } from './student-to-cards.styles';

type Student = {
  name: string;
  jobTitle?: string;
  group: string;
  imageUrl: string;
};
type StudentToCardsProps = {
  studentList: Student[];
};
const StudentToCards: FC<StudentToCardsProps> = ({ studentList }) => (
  <>
    {studentList.map(({ name, jobTitle, group, imageUrl }, index) => (
      <Card key={index}>
        {jobTitle && <h3>{jobTitle}</h3>}
        <img src={imageUrl} alt={jobTitle} />
        <h3>{name}</h3>
        <h4>{group}</h4>
      </Card>
    ))}
  </>
);

export default StudentToCards;
