import { FC } from 'react';
import { DateContainer } from './announce-date.styles';

type AnnounceDateProps = {
  date: string;
};
const AnnounceDate: FC<AnnounceDateProps> = ({ date }) => {
  const getDate = (propDate: typeof date) => {
    const dateString = new Date(propDate);

    if (!dateString?.valueOf()) return 'дата невідома ☹';
    const dateWeekday = dateString.toLocaleDateString('uk-UA', {
      weekday: 'long'
    });
    const msInDay = 1000 * 60 * 60 * 24;
    const nowInDays = new Date().valueOf() / msInDay;
    if (nowInDays - dateString.valueOf() / msInDay <= 1) return 'сьогодні';
    if (nowInDays - dateString.valueOf() / msInDay <= 2) return 'вчора';
    if (nowInDays - dateString.valueOf() / msInDay <= 7) return dateWeekday;
    if (dateString.getFullYear() === new Date().getFullYear())
      return dateString.toLocaleDateString('uk', {
        month: 'long',
        day: 'numeric'
      });
    return dateString
      .toLocaleDateString('uk', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
      .replace('р.', 'року');
  };

  return <DateContainer>Опубліковано: {getDate(date)}</DateContainer>;
};
export default AnnounceDate;
